import React, { Component } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import countryNames from './countryNames.json';
import MenuModel from '../models/Menu';
import MenuItemModel from '../models/MenuItem';
import { mapEdgesToNodes } from '../utils/utils';
import { setBrowseForeign } from '../state/userLocationService';

export const query = graphql`
  {
    allSanityHeaderMenu {
      edges {
        node {
          ...HeaderMenu
        }
      }
    }
    allSanityHeaderMenuItem {
      edges {
        node {
          ...HeaderMenuItem
        }
      }
    }
    allSanityProductMenu {
      edges {
        node {
          ...ProductMenu
        }
      }
    }
    allSanityProductMenuItem {
      edges {
        node {
          ...ProductMenuItem
        }
      }
    }
    allSanityArticleMenu {
      edges {
        node {
          ...ArticleMenu
        }
      }
    }
    allSanityArticleMenuItem {
      edges {
        node {
          ...ArticleMenuItem
        }
      }
    }
    allSanityFooterMenu {
      edges {
        node {
          ...FooterMenu
        }
      }
    }
    allSanityFooterMenuItem {
      edges {
        node {
          ...FooterMenuItem
        }
      }
    }
  }
`;

function withMenus(WrappedComponent) {
  class WithMenus extends Component {
    constructor(props) {
      super(props);

      this.getRegionMenu = this.getRegionMenu.bind(this);
      this.mapToRegionMenus = this.mapToRegionMenus.bind(this);
      this.buildMenu = this.buildMenu.bind(this);
      this.renderer = this.renderer.bind(this);
    }

    getRegionMenu() {
      const { brand, dispatch, sites, siteUID } = this.props;

      const regionMenu = new MenuModel({
        children: [],
        id: 'regionMenu',
        target: { _type: 'regionMenu' },
      });

      for (const site of sites.filter((site) => site.type === 'region')) {
        if (site.brandUID !== brand.brandUID) continue;

        const menuItem = new MenuItemModel(
          {
            children: [],
            id: site.id,
            linkTarget: site.enabled
              ? {
                  brandUID: brand.brandUID,
                  linkType: 'site',
                  siteUID: site.siteUID,
                  urlLink: site.siteUID,
                }
              : null,
            onClick: () => dispatch(setBrowseForeign(true)),
            title: {
              languagePrimary: site.title,
              languageSecondary: site.title,
            },
          },
          siteUID
        );

        regionMenu.children.push(menuItem);
      }

      for (const site of sites.filter((site) => site.type === 'country')) {
        if (site.brandUID !== brand.brandUID) continue;

        const menuItem = new MenuItemModel(
          {
            id: site.id,
            linkTarget: {
              brandUID: brand.brandUID,
              linkType: 'site',
              siteUID: site.siteUID,
              urlLink: site.siteUID,
            },
            onClick: () => dispatch(setBrowseForeign(true)),
            title: {
              languagePrimary: countryNames[site.countryCode],
              languageSecondary: countryNames[site.countryCode],
            },
          },
          siteUID
        );

        regionMenu.children.push(menuItem);
      }

      return regionMenu;
    }

    mapToRegionMenus(data, menuType) {
      const { brand, site, siteUID } = this.props;

      const menus = data[`allSanity${menuType}Menu`].edges.filter(
        ({ node: menu }) =>
          menu?.site.uid.current === (site.mirrorSiteUID || siteUID) &&
          menu?.site.brand.uid.current === brand.brandUID
      );

      const menuItems = data[`allSanity${menuType}MenuItem`].edges
        .filter(
          ({ node: menuItem }) =>
            menuItem?.site.uid.current === (site.mirrorSiteUID || siteUID) &&
            menuItem?.site.brand.uid.current === brand.brandUID
        )
        .map(({ node: menuItem }) => ({
          node: {
            ...menuItem,
            linkTarget:
              menuItem.linkTarget.documentLink?.linkTarget ||
              menuItem.linkTarget,
          },
        }));

      return { menus, menuItems };
    }

    buildMenu(menus, type, title = null) {
      const { siteUID } = this.props;

      const menu = mapEdgesToNodes({ edges: menus[type].menus })[0];
      const menuItems = mapEdgesToNodes({ edges: menus[type].menuItems });
      const featuredMenuItems = mapEdgesToNodes({
        edges: menus[type].featuredMenuItems,
      });

      if (!menu) return null;

      const traverseChildren = (childMenuItem) => {
        const constructedItem = new MenuItemModel(
          { title, ...childMenuItem },
          siteUID
        );

        for (const subMenuItem of childMenuItem.featuredMenuItems || []) {
          for (const menuItem of menuItems) {
            if (menuItem.id !== subMenuItem.id) continue;

            if (
              menuItem.linkTarget?.linkType === 'document' &&
              Object.keys(menus).includes(
                menuItem.linkTarget?.documentLink?._type
              )
            ) {
              constructedItem.featuredChildren.push(
                this.buildMenu(
                  menus,
                  menuItem.linkTarget.documentLink._type,
                  menuItem.title
                )
              );
            } else {
              constructedItem.featuredChildren.push(traverseChildren(menuItem));
            }
          }
        }

        for (const subMenuItem of childMenuItem.menuItems || []) {
          for (const menuItem of menuItems) {
            if (menuItem.id !== subMenuItem.id) continue;

            if (
              menuItem.linkTarget?.linkType === 'document' &&
              Object.keys(menus).includes(
                menuItem.linkTarget?.documentLink?._type
              )
            ) {
              constructedItem.children.push(
                this.buildMenu(
                  menus,
                  menuItem.linkTarget.documentLink._type,
                  menuItem.title
                )
              );
            } else {
              constructedItem.children.push(traverseChildren(menuItem));
            }
          }
        }

        return constructedItem;
      };

      return new MenuModel({
        target: { _type: type },
        featuredChildren: featuredMenuItems,
        ...traverseChildren(menu),
      });
    }

    renderer(data) {
      const rawMenus = {
        headerMenu: this.mapToRegionMenus(data, 'Header'),
        productMenu: this.mapToRegionMenus(data, 'Product'),
        articleMenu: this.mapToRegionMenus(data, 'Article'),
        footerMenu: this.mapToRegionMenus(data, 'Footer'),
      };

      const menus = Object.keys(rawMenus).reduce(
        (acc, type) => ({
          ...acc,
          [type]: this.buildMenu(rawMenus, type),
        }),
        {}
      );

      menus.regionMenu = this.getRegionMenu();

      return <WrappedComponent menus={menus} {...this.props} />;
    }

    render() {
      return <StaticQuery query={query} render={this.renderer} />;
    }
  }

  return WithMenus;
}

export default withMenus;
