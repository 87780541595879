import { connect } from 'react-redux';
import React, { Component } from 'react';
/**
import {
  AmbientLight,
  Mesh,
  MeshBasicMaterial,
  PerspectiveCamera,
  Scene,
  SphereGeometry,
  TextureLoader,
  WebGLRenderer,
} from 'three';
*/

import EarthMap from '../../../assets/images/earthmap.gif';
//import Texture from '../../../assets/images/earthmap2.svg';
import { setActiveMenuKey, toggleMenuOpen } from '../../../state/menuService';
import { setBrowseForeign } from '../../../state/userLocationService';

class RegionMenuToggle extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    /**
    this.renderer = new WebGLRenderer({
      canvas: document.querySelector('#canvas'),
      alpha: true,
    });

    this.scene = new Scene();

    this.camera = new PerspectiveCamera(50, 1, 1, 100);
    this.camera.position.z = 25;

    const geometry = new SphereGeometry(10, 100, 100);
    const light = new AmbientLight(0xffffff);
    light.position.set(100, 50, 100);
    this.scene.add(light);

    const loader = new TextureLoader();
    loader.load(
      Texture,
      (texture) => {
        const material = new MeshBasicMaterial({
          map: texture,
        });
        this.mesh = new Mesh(geometry, material);
        this.mesh.rotation.x += 0.5;
        this.scene.add(this.mesh);

        this.animate();
      },
      undefined,
      () => console.error('Error loading texture')
    );
    */
  }

  animate() {
    /**
    this.mesh.rotation.y += 0.005;
    this.renderer.render(this.scene, this.camera);
    requestAnimationFrame(this.animate.bind(this));
    */
  }

  onClick() {
    const { dispatch, menuOpen } = this.props;

    dispatch(setActiveMenuKey('regionMenu'));
    dispatch(setBrowseForeign(true));
    dispatch(toggleMenuOpen(!menuOpen));
  }

  render() {
    return (
      <button
        className="site-footer__util util--region-menu-toggle"
        onClick={this.onClick}
      >
        <img src={EarthMap} />
      </button>
    );
  }
}

export default connect((state) => ({
  menuOpen: state.menuService.menuOpen,
}))(RegionMenuToggle);
