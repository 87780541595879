import Slice from './Slice';

class VideoPickit extends Slice {
  constructor(slice) {
    super(slice);

    this.previewUrl = slice.media.videoPickit?.imageUrl;
    this.url = slice.media.videoPickit.value;
  }
}

export default VideoPickit;
