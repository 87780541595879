import BlockContent from '@sanity/block-content-to-react';
import React, { Component } from 'react';

import { getCmsValue } from '../../utils/utils';

class Text extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { text, site } = this.props;

    return <BlockContent blocks={getCmsValue(text.text, site)} />;
  }
}

export default Text;
