import React, { Component } from 'react';

class ProductText extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { productText, site } = this.props;

    return productText.productText;
  }
}

export default ProductText;
