import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import slugify from 'slugify';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ShopGQLClient from './ShopGQLClient';
import withLoyalty from './withLoyalty';
import {
  customer,
  customerAccessTokenCreate,
  customerAccessTokenDelete,
  customerActivateByUrl,
  customerAddressCreate,
  customerAddressDelete,
  customerAddressUpdate,
  customerCreate,
  customerDefaultAddressUpdate,
  customerRecover,
  customerResetByUrl,
  customerUpdate,
  checkoutCustomerAssociateV2,
  checkoutCustomerDisassociateV2,
  addresses,
  orders,
} from './accountQueries';
import { mapEdgesToNodes } from '../../utils/utils';
import {
  addAddress,
  deleteAddress,
  setAccessToken,
  setAccount,
  setAddresses,
  setOrders,
} from '../../state/accountService';

function withAccount(WrappedComponent) {
  class WithAccount extends Component {
    constructor(props) {
      super(props);

      this.customer = this.customer.bind(this);
      this.customerAccessTokenCreate =
        this.customerAccessTokenCreate.bind(this);
      this.customerAccessTokenDelete =
        this.customerAccessTokenDelete.bind(this);
      this.customerActivateByUrl = this.customerActivateByUrl.bind(this);
      this.customerAddressCreate = this.customerAddressCreate.bind(this);
      this.customerAddressDelete = this.customerAddressDelete.bind(this);
      this.customerAddressUpdate = this.customerAddressUpdate.bind(this);
      this.customerDefaultAddressUpdate =
        this.customerDefaultAddressUpdate.bind(this);
      this.customerCreate = this.customerCreate.bind(this);
      this.checkoutCustomerAssociateV2 =
        this.checkoutCustomerAssociateV2.bind(this);
      this.checkoutCustomerDisassociateV2 =
        this.checkoutCustomerDisassociateV2.bind(this);
      this.customerRecover = this.customerRecover.bind(this);
      this.customerResetByUrl = this.customerResetByUrl.bind(this);
      this.customerUpdate = this.customerUpdate.bind(this);
      this.getAddresses = this.getAddresses.bind(this);
      this.getOrders = this.getOrders.bind(this);

      this.client = new ShopGQLClient(props.site.shopUrl, props.site.shopToken);
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    async customer() {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .query(customer(accessToken))
        .then((res) => res.data.customer)
        .catch((err) => ({ error: err }));

      if (response?.email) {
        dispatch(setAccount(response, site.siteUID));
      } else {
        dispatch(setAccount(null, site.siteUID));
        dispatch(setAccessToken(null, site.siteUID));
      }

      return response;
    }

    async customerAccessTokenCreate(data) {
      const { dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerAccessTokenCreate(data))
        .then((res) => res.data.customerAccessTokenCreate)
        .catch((err) => ({ error: err }));

      if (response.customerAccessToken?.accessToken) {
        dispatch(setAccessToken(response.customerAccessToken, site.siteUID));
      }

      return response;
    }

    async customerAccessTokenDelete(data) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerAccessTokenDelete(accessToken))
        .then((res) => res.data.customerAccessTokenDelete)
        .catch((err) => ({ error: err }));

      dispatch(
        setAccessToken({ accessToken: null, expiresAt: null }, site.siteUID)
      );
      dispatch(setAccount(null, site.siteUID));
      dispatch(setAddresses([], site.siteUID));
      dispatch(setOrders([], site.siteUID));

      return response;
    }

    async customerActivateByUrl(data) {
      const { dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerActivateByUrl(data))
        .then((res) => res.data.customerActivateByUrl)
        .catch((err) => ({ error: err }));

      if (response.customerAccessToken?.accessToken) {
        dispatch(
          setAccessToken(response.customerAccessToken.accessToken, site.siteUID)
        );
      }

      return response;
    }

    async customerAddressCreate(address) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerAddressCreate(address, accessToken))
        .then((res) => res.data.customerAddressCreate)
        .catch((err) => ({ error: err }));

      dispatch(addAddress(response.customerAddress, site.siteUID));

      return response;
    }

    async customerAddressDelete(address) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerAddressDelete(address, accessToken))
        .then((res) => res.data.customerAddressDelete)
        .catch((err) => ({ error: err }));

      return response;
    }

    async customerAddressUpdate(address) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerAddressUpdate(address, accessToken))
        .then((res) => res.data?.customerAddressUpdate)
        .catch((err) => ({ error: err }));

      if (response.error) {
        const newAddress = await this.customerAddressCreate(address);
      }

      return response;
    }

    async checkoutCustomerAssociateV2(checkoutId) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(checkoutCustomerAssociateV2(checkoutId, accessToken))
        .then((res) => res.data.checkoutCustomerAssociateV2.customer)
        .catch((err) => ({ error: err }));

      if (response?.email) {
        dispatch(setAccount(response, site.siteUID));
      }

      return response;
    }

    async checkoutCustomerDisassociateV2(checkoutId) {
      const { dispatch, site } = this.props;

      const response = await this.client
        .mutate(checkoutCustomerDisassociateV2(checkoutId))
        .then((res) => res.data.checkoutCustomerDisassociateV2.checkout)
        .catch((err) => ({ error: err }));

      return response;
    }

    async customerCreate(data) {
      const response = await this.client
        .mutate(customerCreate(data))
        .then((res) => res.data.customerCreate)
        .catch((err) => err);

      return response;
    }

    async customerDefaultAddressUpdate(address) {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerDefaultAddressUpdate(address, accessToken))
        .then((res) => res.data.customerDefaultAddressUpdate.customer)
        .catch((err) => ({ error: err }));

      if (response.email) {
        dispatch(setAccount(response, site.siteUID));
      }

      return response;
    }

    async customerRecover(data) {
      const { dispatch } = this.props;

      const response = await this.client
        .mutate(customerRecover(data))
        .then((res) => res.data.customerRecover)
        .catch((err) => ({ error: err }));

      return response;
    }

    async customerResetByUrl(data) {
      const { dispatch } = this.props;

      const response = await this.client
        .mutate(customerResetByUrl(data))
        .then((res) => res.data.customerResetByUrl)
        .catch((err) => ({ error: err }));

      return response;
    }

    async customerUpdate() {
      const { account, accessToken, dispatch, site } = this.props;

      const response = await this.client
        .mutate(customerUpdate(account, accessToken))
        .then((res) => res.data.customerUpdate)
        .catch((err) => ({ error: err }));

      if (response?.email) {
        dispatch(setAccount(response, site.siteUID));
      }

      return response;
    }

    async getAddresses() {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .query(addresses(accessToken))
        .then((res) => res.data.customer)
        .catch((err) => ({ error: err }));

      if (response.addresses) {
        dispatch(
          setAddresses(mapEdgesToNodes(response.addresses), site.siteUID)
        );
      }

      return response;
    }

    async getOrders() {
      const { accessToken, dispatch, site } = this.props;

      const response = await this.client
        .query(orders(accessToken))
        .then((res) => res.data.customer)
        .catch((err) => ({ error: err }));

      if (response.orders) {
        dispatch(setOrders(mapEdgesToNodes(response.orders), site.siteUID));
      }

      return response;
    }

    render() {
      return (
        <WrappedComponent
          activate={this.customerActivateByUrl}
          connectCheckout={this.checkoutCustomerAssociateV2}
          disconnectCheckout={this.checkoutCustomerDisassociateV2}
          createAccount={this.customerCreate}
          createAddress={this.customerAddressCreate}
          deleteAddress={this.customerAddressDelete}
          getAccount={this.customer}
          getAddresses={this.getAddresses}
          getOrders={this.getOrders}
          login={this.customerAccessTokenCreate}
          logout={this.customerAccessTokenDelete}
          recoverPassword={this.customerRecover}
          resetPassword={this.customerResetByUrl}
          updateAccount={this.customerUpdate}
          updateAddress={this.customerAddressUpdate}
          updateDefaultAddress={this.customerDefaultAddressUpdate}
          {...this.props}
        />
      );
    }
  }

  return compose(
    connect((state, props) => {
      const siteUID = props.site.siteUID;

      return {
        accessToken: state.accountService[siteUID]?.accessToken || null,
        account: state.accountService[siteUID]?.account || null,
        addresses: state.accountService[siteUID]?.addresses || [],
        countryCode: state.userLocationService.location.countryCode,
        orders: state.accountService[siteUID]?.orders || [],
      };
    }),
    withLoyalty
  )(WithAccount);
}

export default withAccount;
