import Slice from './Slice';

class ProductText extends Slice {
  constructor(slice) {
    super(slice);

    this.productText = slice.text;
  }
}

export default ProductText;
