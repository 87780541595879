import Article from './Article';
import ArticleGroup from './ArticleGroup';
import Menu from './Menu';
import Page from './Page';
import Product from './Product';
import ProductGroup from './ProductGroup';
import Site from './Site';

class MenuItem extends Menu {
  constructor(menuItem, siteUID) {
    super(menuItem, siteUID);

    this.linkTarget = MenuItem.parseLinkTarget(menuItem, siteUID);
    this.onClick = menuItem.onClick || undefined;
  }

  static parseLinkTarget(menuItem, siteUID) {
    switch (menuItem.linkTarget?.linkType) {
      case 'document':
        return MenuItem.parseDocumentLink(
          menuItem.linkTarget.documentLink,
          siteUID
        );
      case 'product':
        return new Product(
          {
            path: menuItem.linkTarget.productLink.uid.current,
          },
          siteUID
        );
      case 'site':
        return new Site({
          brand: { uid: { current: menuItem.linkTarget.brandUID } },
          uid: { current: menuItem.linkTarget.siteUID },
        });
      case 'url':
        return menuItem.linkTarget.urlLink;
    }
  }

  static parseDocumentLink(documentLink, siteUID) {
    if (!documentLink) return null;

    switch (documentLink._type) {
      case 'article':
        return new Article(documentLink, siteUID);
      case 'articleGroup':
        return new ArticleGroup(documentLink, siteUID);
      case 'page':
        return new Page(documentLink, siteUID);
      case 'productGroup':
        return new ProductGroup(documentLink, siteUID);
    }
  }
}

export default MenuItem;
