import lscache from 'lscache';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';

class ShopGQLClient {
  constructor(url, token) {
    const httpLink = new HttpLink({
      uri: () => `https://${url}/api/2024-04/graphql.json`,
    });

    const authLink = new ApolloLink((operation, forward) => {
      operation.setContext({
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token': token,
        },
      });
      return forward(operation);
    });

    this.client = new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
    });
  }

  query(query) {
    return this.client.query(query);
  }

  mutate(mutation) {
    return this.client.mutate(mutation);
  }
}

export default ShopGQLClient;
