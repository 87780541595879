import React, { useEffect, useRef, useState } from 'react';

import ProductsList from '../products/products/ProductsList';
import Slider, { Slide } from '../_common/Slider';
import { ArrowRight, ArrowLeft } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';

const Products = ({ id, products, site }) => {
  let productListWrapper;
  if (products.multiRow) {
    productListWrapper = (children) => <>{children}</>;
  } else {
    productListWrapper = (children, single) => (
      <Slider
        params={{
          navigation: 'below',
          slidesPerView: single
            ? 1
            : children.length <= 2
            ? children.length
            : 2,
          spaceBetween: 20,
          breakpoints: {
            768: {
              slidesPerView: single
                ? 1
                : children.length <= 3
                ? children.length
                : 3,
            },
          },
        }}
      >
        {children}
      </Slider>
    );
  }

  let productItemWrapper;
  if (products.multiRow) {
    productItemWrapper = (children) => <>{children}</>;
  } else {
    productItemWrapper = (children) => <Slide>{children}</Slide>;
  }

  return (
    <>
      <div className="productsSlice__header">
        <div className="productsSlice__header-title">
          <h2>{getCmsValue(products.title, site)}</h2>
        </div>
      </div>

      <ProductsList
        id={id}
        products={products.products}
        productListWrapper={productListWrapper}
        productItemWrapper={productItemWrapper}
        renderAll={true}
        singleRow={!products.multiRow}
        site={site}
        skipSort={products.skipSort}
      />
    </>
  );
};

export default Products;
