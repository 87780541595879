import { Link as GatsbyLink } from 'gatsby';
import React, { Component } from 'react';

class Link extends Component {
  constructor(props) {
    super(props);

    this.getUrlFromObject = this.getUrlFromObject.bind(this);
    this.getUrlFromString = this.getUrlFromString.bind(this);
    this.resolveLinkType = this.resolveLinkType.bind(this);
  }

  resolveLinkType(to) {
    switch (to.constructorName) {
      case 'Article':
        return 'article';
      case 'ArticleGroup':
        return 'articles';
      case 'Page':
        return 'page';
      case 'Product':
        return 'product';
      case 'ProductGroup':
        return 'products';
      case 'Prostaffer':
        return 'prostaffer';
      default:
        return '';
    }
  }

  getUrlFromObject(site, to, q) {
    const parts = [
      to.siteUID || '',
      this.resolveLinkType(to) || '',
      to.path || '',
    ].filter((part) => part);

    let brandUID = undefined;

    if (to.brandUID && to.brandUID !== site.brandUID) {
      brandUID = to.brandUID;
    } else {
      brandUID = site.brandUID;
    }

    if (brandUID !== process.env.GATSBY_SITE_BRAND) parts.unshift(brandUID);

    return '/' + parts.join('/') + (q || '');
  }

  getUrlFromString(site, to) {
    if (to === '/') return '/';
    else if (to.indexOf('http://') === 0 || to.indexOf('https://') === 0)
      return to;
    return '/' + to;
  }

  render() {
    const { children, className, site, to, q } = this.props;

    let url = '';
    if (!to) {
      url = '#!';
    } else if (typeof to === 'object') {
      url = this.getUrlFromObject(site, to, q);
    } else if (typeof to === 'string') {
      url = this.getUrlFromString(site, to);
    } else {
      url = '#!';
    }

    url = url.replace('///', '/');

    if (url.indexOf('http') === 0) {
      return (
        <a
          className={className}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {children}
        </a>
      );
    }

    return (
      <GatsbyLink className={className} to={url}>
        {children}
      </GatsbyLink>
    );
  }
}

export default Link;
