import React, { Component } from 'react';

class VideoPickit extends Component {
  constructor(props) {
    super(props);

    this.videoRef = null;
  }

  componentDidMount() {
    this.videoRef.play = () => this.playerRef.play();
    this.videoRef.pause = () => this.playerRef.pause();
  }

  render() {
    const { video } = this.props;

    return (
      <div className="video" ref={(ref) => (this.videoRef = ref)}>
        <img src={video.video?.previewUrl} />
        <video autoPlay loop muted ref={(ref) => (this.playerRef = ref)}>
          <source src={video.video?.url} type="video/mp4" />
        </video>
      </div>
    );
  }
}

export default VideoPickit;
