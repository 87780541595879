class Brand {
  constructor(brand) {
    this.brandUID = brand.uid.current;
    this.name = brand.name;
    this.logo = brand.logo.asset.url;
    this.url = brand.url;
  }
}

export default Brand;
