import { combineReducers, createStore as reduxCreateStore } from 'redux';
import { Provider } from 'react-redux';
import React from 'react';
import reducers from './index';

const createStore = () => reduxCreateStore(combineReducers(reducers));
const store = createStore();

export const reduxStore = store;

const wrapper = ({ element }) => <Provider store={store}>{element}</Provider>;

export default wrapper;
