import React, { Component } from 'react';

import { Close } from './Icons';

class Popup extends Component {
  constructor(props) {
    super(props);

    this.state = { isPopupOpen: false };

    this.togglePopup = this.togglePopup.bind(this);
    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onClick);
    this.togglePopup();
  }

  componentDidUpdate(prevProps) {
    if (this.props.visible !== prevProps.visible) {
      this.togglePopup();
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onClick);
  }

  togglePopup() {
    const { visible, timeout } = this.props;

    setTimeout(
      () => {
        this.setState({ ...this.state, isPopupOpen: visible });
      },
      visible ? timeout || 0 : 0
    );
  }

  onClick(event) {
    if (!this.popupRef || this.popupRef.contains(event.target)) return;
    if (!this.state.isPopupOpen) return;
    this.onClose();
  }

  onClose() {
    this.props.onClose && this.props.onClose();
  }

  render() {
    const { className, inverted, noHeader } = this.props;

    return (
      <div
        id={this.props.id}
        className={`popup ${this.state.isPopupOpen ? 'popup--open' : ''}`}
        ref={(node) => (this.popupRef = node)}
      >
        <div
          className={`popup__content-wrapper ${className || ''} ${
            inverted ? 'popup__content-wrapper--inverted' : ''
          }`}
        >
          {!noHeader && (
            <div className="popup__header">
              {this.props.headerContent}
              <div className="popup__close-button" onClick={this.onClose}>
                {Close}
              </div>
            </div>
          )}
          <div
            className={`popup__content ${
              this.props.noPadding ? 'content--no-padding' : ''
            }`}
          >
            {this.props.children}
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
