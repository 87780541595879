import React, { Component } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby-link';

import { Close, Search as SearchIcon } from '../_common/Icons';
import { toggleSearchOpen } from '../../state/menuService';

class Search extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      query: '',
    };
    this.state = { ...this.initialState };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      this.setState((prevState) => ({
        query: new URLSearchParams(window.location.search).get('q') || '',
      }));
    }

    if (this.inputRef) this.inputRef.focus();
  }

  onChange(e) {
    e.preventDefault();

    this.setState((prevState) => ({
      query: e.target.value,
    }));
  }

  onClose() {
    const { dispatch } = this.props;

    dispatch(toggleSearchOpen(false));
  }

  async onSubmit(e) {
    const { site } = this.props;

    e.preventDefault();

    const parts = [
      site.brandUID === process.env.GATSBY_SITE_BRAND ? '' : site.brandUID,
      site.siteUID,
    ].filter((part) => part);
    const base = parts.join('/');

    navigate(`/${base}/search?q=${this.state.query}`);
  }

  render() {
    return (
      <div className="site-header__search">
        <button className="site-header__search-button" onClick={this.onClose}>
          {Close}
        </button>
        <form className="search-form" method="GET" onSubmit={this.onSubmit}>
          <input
            className="search-input"
            type="search"
            ref={(ref) => (this.inputRef = ref)}
            onChange={this.onChange}
            value={this.state.query}
          ></input>
        </form>
        <button className="site-header__search-button" onClick={this.onSubmit}>
          {SearchIcon}
        </button>
      </div>
    );
  }
}

export default connect((state) => ({
  searchOpen: state.menuService.searchOpen,
}))(Search);
