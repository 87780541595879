import { connect } from 'react-redux';
import Link from '../Link';
import React, { Component } from 'react';

import MenuItemModel from '../../models/MenuItem';
import { AngleDownFat } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';
import { setActiveMenuKey, toggleMenuOpen } from '../../state/menuService';

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.onMenuItemClick = this.onMenuItemClick.bind(this);
  }

  onMenuItemClick(menuKey) {
    const { dispatch } = this.props;
    dispatch(setActiveMenuKey(menuKey));
    dispatch(toggleMenuOpen(true));
  }

  render() {
    const { headerMenu, screenSize, site, sites } = this.props;

    return (
      <div className="site-header__navigation">
        {screenSize === 'large' && (
          <ul className="site-header__navigation-list">
            {headerMenu.children.map((child) => (
              <li className="site-header__navigation-item" key={child.id}>
                {child instanceof MenuItemModel ? (
                  <Link
                    className={`site-header__navigation-link ${
                      child?.linkTarget?.path === 'sale'
                        ? 'navigation-link__featured'
                        : ''
                    }`}
                    site={site}
                    to={child.linkTarget}
                  >
                    {getCmsValue(child.title, site)}
                  </Link>
                ) : (
                  <span
                    className={`site-header__navigation-link ${
                      child?.linkTarget?.path === 'sale'
                        ? 'navigation-link__featured'
                        : ''
                    }`}
                    onClick={(e) => this.onMenuItemClick(child.type)}
                  >
                    {getCmsValue(child.title, site)}
                    {AngleDownFat}
                  </span>
                )}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default connect()(Navigation);
