import Link from '../Link';
import React, { Component } from 'react';

import { AngleRightFat } from '../_common/Icons';
import MobileMenuPane from './MobileMenuPane';

class MobileMenuPaneItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCurrent: false,
    };

    this.onDescend = this.onDescend.bind(this);
    this.onAscend = this.onAscend.bind(this);
  }

  onDescend() {
    this.setState((prevState) => ({ ...prevState, isCurrent: true }));
    this.props.onDescend();
  }

  onAscend() {
    this.setState((prevState) => ({ ...prevState, isCurrent: false }));
    this.props.onAscend();
  }

  render() {
    const { isCurrent } = this.state;
    const {
      children,
      featuredChildren,
      isRoot,
      linkTarget,
      onClick,
      site,
      title,
    } = this.props;

    const classNames = [isRoot ? 'pane-link--root' : ''].join(' ');

    return (
      <li className="mobile-menu__pane-item" onClick={onClick}>
        {children.length > 0 ? (
          <span
            className={`mobile-menu__pane-link ${classNames}`}
            onClick={this.onDescend}
          >
            {title}
            {AngleRightFat}
          </span>
        ) : (
          <Link
            className={`mobile-menu__pane-link ${classNames}`}
            site={site}
            to={linkTarget}
          >
            {title}
          </Link>
        )}
        {children.length > 0 && (
          <MobileMenuPane
            children={children}
            featuredChildren={featuredChildren}
            isCurrent={isCurrent}
            onAscend={this.onAscend}
            site={site}
            title={title}
          />
        )}
      </li>
    );
  }
}

export default MobileMenuPaneItem;
