import Slice from './Slice';

class EmailSignup extends Slice {
  constructor(slice) {
    super(slice);

    this.listId = slice.signupList.value;
  }
}

export default EmailSignup;
