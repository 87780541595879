import Link from '../Link';
import React, { Component } from 'react';

class DesktopMenuPaneItem extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { linkTarget, site, title } = this.props;

    return (
      <li className="desktop-menu__pane-item">
        <Link
          className="desktop-menu__pane-item-link"
          site={site}
          to={linkTarget}
        >
          {title}
        </Link>
      </li>
    );
  }
}

export default DesktopMenuPaneItem;
