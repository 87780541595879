import Article from './Article';
import Document from './Document';

class Page extends Article {
  constructor(page, siteUID) {
    super(page, siteUID);

    this.constructorName = 'Page';
  }
}

export default Page;
