import React, { Component } from 'react';

import IconLinks from './IconLinks';
import Logos from '../_common/Logos';
import Link from '../Link';
import LoyaltyBar from './LoyaltyBar';
import Navigation from './Navigation';
import { getCmsValue } from '../../utils/utils';

class Footer extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      smsTabActive:
        !props.site.listIdEmail && props.site.listIdSMS ? true : false,
    };

    this.state = { ...this.initialState };
  }

  render() {
    const { footerMenu, screenSize, site, sites } = this.props;
    const { smsTabActive } = this.state;

    const menuSection = (index) => (
      <ul className={`site-footer-menu-list order-${index}`}>
        {footerMenu?.children[index]?.children.map((subMenuItem) => (
          <li key={subMenuItem.id} className="site-footer-menu-list-item">
            <Link site={site} to={subMenuItem.linkTarget}>
              {getCmsValue(subMenuItem.title, site)}
            </Link>
          </li>
        ))}
      </ul>
    );

    return (
      <footer className="site-footer">
        {screenSize === 'small' && (
          <>
            <LoyaltyBar site={site} />
            <Navigation
              footerMenu={footerMenu}
              screenSize={screenSize}
              site={site}
              sites={sites}
            />
          </>
        )}
        <div className="site-footer-wrapper">
          <div className="site-footer-section">
            <div className="site-footer-subscribe">
              <span className="subscribe-title">
                {getCmsValue(site.strings.footer__subscribeFormTitle, site)}
              </span>
              <div className={`klaviyo-form-VKBKp7`}></div>
            </div>
          </div>
          <div className="site-footer-section-wrapper">
            <div className="site-footer-section">
              {menuSection(0)}
              {menuSection(1)}
            </div>
            <div className="site-footer-section">
              {menuSection(2)}
              <IconLinks links={site.socialLinks} site={site} />
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
