import React, { Component } from 'react';

import { getCmsValue } from '../../utils/utils';
import { Link } from 'gatsby';

class File extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { file, site } = this.props;

    return (
      <Link to={file.file?.asset?.url} target="_blank">
        {getCmsValue(file.title, site)}
      </Link>
    );
  }
}

export default File;
