import React, { Component } from 'react';
import { connect } from 'react-redux';

import AccountButton from './utils/AccountButton';
import CartToggle from './utils/CartToggle';
import RegionMenuToggle from './utils/RegionMenuToggle';
import SearchToggle from './utils/SearchToggle';
import StoresButton from './utils/StoresButton';

class Utils extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { site } = this.props;

    return (
      <div className="site-footer__utils">
        <StoresButton site={site} />
        <SearchToggle site={site} />
        {site.shopEnabled && <AccountButton site={site} />}
        {site.shopEnabled && <CartToggle site={site} />}
      </div>
    );
  }
}

export default Utils;
