import lscache from 'lscache';

/**
 * Initial state
 */
const initialAccountServiceData = {
  accessToken: null,
  accessExpires: null,
  account: null,
  addresses: [],
  orders: [],
};

/**
 * Action types
 */
const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
const SET_ACCOUNT = 'SET_ACCOUNT';
const SET_ADDRESSES = 'SET_ADDRESSES';
const SET_ORDERS = 'SET_ORDERS';
const ADD_ADDRESS = 'ADD_ADDRESS';
const DELETE_ADDRESS = 'DELETE_ADDRESS';

/**
 * Action creators
 */
export const setAccessToken = (payload, siteUID) => ({
  type: SET_ACCESS_TOKEN,
  payload: payload,
  siteUID: siteUID,
});
export const setAccount = (payload, siteUID) => ({
  type: SET_ACCOUNT,
  payload: payload,
  siteUID: siteUID,
});
export const setAddresses = (payload, siteUID) => ({
  type: SET_ADDRESSES,
  payload: payload,
  siteUID: siteUID,
});
export const setOrders = (payload, siteUID) => ({
  type: SET_ORDERS,
  payload: payload,
  siteUID: siteUID,
});
export const addAddress = (payload, siteUID) => ({
  type: ADD_ADDRESS,
  payload: payload,
  siteUID: siteUID,
});
export const deleteAddress = (payload, siteUID) => ({
  type: DELETE_ADDRESS,
  payload: payload,
  siteUID: siteUID,
});

/**
 * Reducer
 */
export default {
  accountService(
    state = lscache.get('accountServiceData') || initialAccountServiceData,
    { type, payload, siteUID }
  ) {
    let newState = { ...state };

    if (!siteUID) return newState;

    if (!newState[siteUID]) {
      newState[siteUID] = { ...initialAccountServiceData };
    }

    switch (type) {
      case SET_ACCESS_TOKEN:
        newState[siteUID].accessToken = payload.accessToken;
        newState[siteUID].accessExpires = payload.expiresAt;
        break;
      case SET_ACCOUNT:
        newState[siteUID].account = payload;
        break;
      case SET_ADDRESSES:
        newState[siteUID].addresses = payload;
        break;
      case SET_ORDERS:
        newState[siteUID].orders = payload;
        break;
      case ADD_ADDRESS:
        newState[siteUID].addresses = [
          ...(state[siteUID].addresses || []),
          payload,
        ];
        break;
      case DELETE_ADDRESS:
        newState[siteUID].addresses = [...state[siteUID].addresses].filter(
          (address) => address.id !== payload
        );
        break;
      default:
        break;
    }

    lscache.set('accountServiceData', newState);

    return newState;
  },
};
