import axios from 'axios';
import React, { Component } from 'react';

import SiteLoader from '../_common/SiteLoader';
import { getCmsValue } from '../../utils/utils';

class SubscribeForm extends Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);

    this.initialState = {
      form: {
        input: '',
        consent: false,
        sending: false,
        response: null,
      },
    };

    this.state = { ...this.initialState };
  }

  onChange(e) {
    const key = e.target.name;

    let value;
    switch (e.target.type) {
      case 'email':
        value = e.target.value;
        break;
      case 'number':
        value = e.target.value;
        break;
      case 'checkbox':
        value = e.target.checked || false;
        break;
    }

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        [key]: value,
      },
    }));
  }

  async onSubmit(e) {
    e.preventDefault();

    const { listId, type } = this.props;
    const { form } = this.state;

    const data = { profiles: [] };

    if (type === 'email' && form.consent) {
      data.profiles.push({ email: form.input });
    } else if (type === 'sms' && form.consent) {
      data.profiles.push({
        phone_number: form.input,
        sms_consent: form.consent,
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        sending: true,
      },
    }));

    const response = await axios({
      method: 'POST',
      url: `/api/klaviyo/list/${listId}/subscribe`,
      data: data,
    }).then((res) => res);

    const { status } = response;

    this.setState((prevState) => ({
      ...prevState,
      form: {
        ...prevState.form,
        input: status !== 200 ? prevState.form.input : '',
        consent: status !== 200 ? prevState.form.consent : false,
        sending: false,
        response: response,
      },
    }));
  }

  render() {
    const { inputType, inverted, placeholder, site } = this.props;
    const { form } = this.state;

    let subscribeWrapperContent;

    if (form.sending) {
      subscribeWrapperContent = <SiteLoader inverted={inverted} />;
    } else {
      subscribeWrapperContent = (
        <>
          <form onSubmit={this.onSubmit}>
            <input
              name="input"
              type={inputType || 'email'}
              value={form.input}
              onChange={this.onChange}
              placeholder={placeholder}
            />
            <label className="subscribe-form__consent">
              <input
                name="consent"
                type="checkbox"
                checked={form.consent}
                onChange={this.onChange}
                required
              />
              <span>
                {getCmsValue(site.strings.subscribeForm__consent, site)}
              </span>
            </label>
            <button className="subscribe-form__submit" type="submit">
              {getCmsValue(site.strings.subscribeForm__subscribeButton, site)}
            </button>
          </form>

          {form.response && (
            <div className="subscribe-form__response">
              {form.response.status !== 200 ? (
                <span>
                  {getCmsValue(site.strings.subscribeForm__error, site)}
                </span>
              ) : (
                <span>
                  {getCmsValue(site.strings.subscribeForm__success, site)}
                </span>
              )}
            </div>
          )}
        </>
      );
    }

    return (
      <div className={`subscribe-form ${inverted ? 'inverted' : ''}`}>
        {subscribeWrapperContent}
      </div>
    );
  }
}

export default SubscribeForm;
