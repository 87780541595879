import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import withCart from '../../../containers/plugins/withCart';
import { ShoppingCart } from '../../_common/Icons';
import { toggleCartOpen } from '../../../state/shopService';

class CartToggle extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.props.loadCart();
  }

  onClick() {
    const { dispatch } = this.props;

    dispatch(toggleCartOpen(true));
  }

  render() {
    const numLineItems = this.props.cart?.lines?.edges?.length || 0;

    return (
      <button
        className="site-header__util util--cart-toggle"
        onClick={this.onClick}
      >
        {numLineItems > 0 && (
          <span className="cart-toggle__indicator">{numLineItems}</span>
        )}
        {ShoppingCart}
      </button>
    );
  }
}

export default compose(
  withCart,
  connect((state) => ({
    cart: state.shopService.cart,
  }))
)(CartToggle);
