import lscache from 'lscache';

/**
 * Initial state
 */
const initialShopServiceData = {
  cartOpen: false,
  cart: null,
  cartId: null,
  cartLoading: false,
  shopCollectionsLoading: [],
  shopProductsLoading: [],
};

/**
 * Action types
 */
const SET_CART = 'SET_CART';
const SET_CART_ID = 'SET_CART_ID';
const SET_CART_LOADING = 'SET_CART_LOADING';
const SET_SHOP_PRODUCTS_LOADING = 'SET_SHOP_PRODUCTS_LOADING';
const SET_SHOP_COLLECTIONS_LOADING = 'SET_SHOP_COLLECTIONS_LOADING';
const TOGGLE_CART_OPEN = 'TOGGLE_CART_OPEN';
const RESET_SHOP_SERVICE_DATA = 'RESET_SHOP_SERVICE_DATA';

/**
 * Action creators
 */
export const setCart = (payload) => ({
  type: SET_CART,
  payload: payload,
});
export const setCartId = (payload) => ({
  type: SET_CART_ID,
  payload: payload,
});
export const setCartLoading = (payload) => ({
  type: SET_CART_LOADING,
  payload: payload,
});
export const setShopProductsLoading = (payload) => ({
  type: SET_SHOP_PRODUCTS_LOADING,
  payload: payload,
});
export const setShopCollectionsLoading = (payload) => ({
  type: SET_SHOP_COLLECTIONS_LOADING,
  payload: payload,
});
export const toggleCartOpen = (payload) => ({
  type: TOGGLE_CART_OPEN,
  payload: payload,
});
export const resetShopServiceData = () => ({
  type: RESET_SHOP_SERVICE_DATA,
});

/**
 * Reducer
 */
export default {
  shopService(
    state = lscache.get('shopServiceData') || initialShopServiceData,
    { type, payload }
  ) {
    let newState = { ...state };

    switch (type) {
      case SET_CART:
        newState.cart = payload;
        break;
      case SET_CART_ID:
        newState.cartId = payload;
        break;
      case SET_CART_LOADING:
        newState.cartLoading = payload;
        break;
      case SET_SHOP_PRODUCTS_LOADING:
        const { product, loading } = payload;
        const shopProductsLoadingSet = new Set(newState.shopProductsLoading);

        if (loading) shopProductsLoadingSet.add(product);
        else shopProductsLoadingSet.delete(product);

        newState.shopProductsLoading = Array.from(shopProductsLoadingSet);
        break;
      case SET_SHOP_COLLECTIONS_LOADING:
        const { collection, collectionLoading } = payload;
        const shopCollectionsLoadingSet = new Set(
          newState.shopCollectionsLoading
        );

        if (collectionLoading) shopCollectionsLoadingSet.add(collection);
        else shopCollectionsLoadingSet.delete(collection);

        newState.shopCollectionsLoading = Array.from(shopCollectionsLoadingSet);
        break;
      case TOGGLE_CART_OPEN:
        newState.cartOpen = payload;
        break;
      case RESET_SHOP_SERVICE_DATA:
        newState = { ...initialShopServiceData };
        break;
      default:
        break;
    }

    const { cart, ...saveState } = newState;
    lscache.set('shopServiceData', saveState);

    return newState;
  },
};
