import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { useEffect, useState } from 'react';

import Cookies from './Cookies';
import Location from './Location';
import { Close } from '../_common/Icons';

const Notifications = ({ dispatch, notificationServiceData, site }) => {
  const [activeNotification, setActiveNotification] = useState(false);
  const [activeNotificationIndex, setActiveNotificationIndex] = useState(-1);

  useEffect(() => setNotification(), []);
  useEffect(
    () => setNotification(),
    [activeNotificationIndex, notificationServiceData]
  );

  const queue = [
    {
      type: 'cookieNotice',
      component: <Cookies dispatch={dispatch} site={site} />,
    },
    {
      type: 'locationNotice',
      component: <Location dispatch={dispatch} site={site} />,
    },
  ];

  const setNotification = () => {
    const notificationKey =
      activeNotificationIndex !== -1 && activeNotificationIndex < queue.length
        ? queue[activeNotificationIndex].type
        : null;

    if (notificationServiceData[notificationKey]) {
      setActiveNotification(true);
    } else if (activeNotificationIndex < queue.length) {
      setActiveNotification(false);
      new Promise((resolve) =>
        setTimeout(() => {
          setActiveNotificationIndex(activeNotificationIndex + 1);
          resolve();
        }, 600)
      );
    } else {
      setActiveNotification(false);
    }
  };

  const onCloseNotification = () => {
    setActiveNotification(false);
  };

  return (
    <div
      className={`notification-wrapper ${
        activeNotification ? 'notification-wrapper--open' : ''
      }`}
    >
      <div className={`notification-wrapper__notification`}>
        <button
          onClick={onCloseNotification}
          className="notification-wrapper__close"
        >
          {Close}
        </button>
        {queue[activeNotificationIndex]?.component || null}
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    notificationServiceData: { ...state.notificationService },
  }))
)(Notifications);
