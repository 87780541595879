import Slice from './Slice';

class File extends Slice {
  constructor(slice) {
    super(slice);

    this.file = slice.file;
    this.title = slice.title;
  }
}

export default File;
