import {
  ArrowRight,
  ArrowLeft,
  AngleUpFat,
  AngleDownFat,
} from '../../../_common/Icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

export class PrevArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { className, onClick } = this.props;
    return (
      <div role="button" tabIndex={0} className={className} onClick={onClick}>
        {ArrowLeft}
      </div>
    );
  }
}

export class NextArrow extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { className, onClick } = this.props;
    return (
      <div role="button" tabIndex={0} className={className} onClick={onClick}>
        {ArrowRight}
      </div>
    );
  }
}

export class PrevAngle extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { className, onClick } = this.props;
    return (
      <div role="button" tabIndex={0} className={className} onClick={onClick}>
        {AngleUpFat}
      </div>
    );
  }
}

export class NextAngle extends Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func,
  };

  render() {
    const { className, onClick } = this.props;
    return (
      <div role="button" tabIndex={0} className={className} onClick={onClick}>
        {AngleDownFat}
      </div>
    );
  }
}
