import React, { Component } from 'react';

import Link from '../Link';
import { getCmsValue } from '../../utils/utils';

class RelatedArticle extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { relatedArticle, site } = this.props;

    return (
      <>
        <div className="related-article__wave"></div>
        <Link
          className="related-article__link"
          site={site}
          to={relatedArticle.article}
        >
          <div className="related-article__header">
            {getCmsValue(site.strings.articlePage__relatedArticle, site)}
          </div>
          <div className="related-article__title">
            {getCmsValue(relatedArticle.article.title, site)}
          </div>
        </Link>
        <div className="related-article__wave"></div>
      </>
    );
  }
}

export default RelatedArticle;
