import React, { Component } from 'react';
import { connect } from 'react-redux';

import BrandBar from './BrandBar';
import Logo from './Logo';
import Navigation from './Navigation';
import SearchForm from './SearchForm';
import Utils from './Utils';

class Header extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { brand, headerMenu, screenSize, searchOpen, site, sites } =
      this.props;

    return (
      <header
        className={`site-header ${
          searchOpen ? 'site-header--search-open' : ''
        }`}
      >
        <BrandBar brand={brand} site={site} sites={sites} />

        <div className="site-header__wrapper">
          <Logo brand={brand} site={site} />

          <Navigation
            headerMenu={headerMenu}
            screenSize={screenSize}
            site={site}
            sites={sites}
          />
          <Utils brand={brand} screenSize={screenSize} site={site} />
          <SearchForm site={site} />
        </div>
      </header>
    );
  }
}

export default connect((state) => ({
  searchOpen: state.menuService.searchOpen,
}))(Header);
