import React, { Component } from 'react';

import SubscribeForm from '../_common/SubscribeForm';

class SmsSignup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { smsSignup, site } = this.props;

    return (
      <>
        <SubscribeForm
          listId={smsSignup.listId}
          type="sms"
          inputType="number"
          placeholder="Your phone number"
          site={site}
        />
      </>
    );
  }
}

export default SmsSignup;
