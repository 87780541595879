import React, { Component } from 'react';
import { connect } from 'react-redux';

import AccountButton from './utils/AccountButton';
import CartToggle from './utils/CartToggle';
import MenuToggle from './utils/MenuToggle';
import RegionMenuToggle from './utils/RegionMenuToggle';
import SearchToggle from './utils/SearchToggle';
import StoresButton from './utils/StoresButton';

class Utils extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { screenSize, site } = this.props;

    return (
      <div className="site-header__utils">
        {screenSize === 'large' && (
          <>
            <RegionMenuToggle />
            <StoresButton site={site} />
            <SearchToggle site={site} />
            {site.shopEnabled && <AccountButton site={site} />}
            {site.shopEnabled && <CartToggle site={site} />}
          </>
        )}
        {screenSize === 'small' && <MenuToggle />}
      </div>
    );
  }
}

export default Utils;
