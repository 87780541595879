import React, { Component } from 'react';

class VideoYoutube extends Component {
  constructor(props) {
    super(props);

    this.videoRef = null;
  }

  render() {
    const { video } = this.props;

    return (
      <div
        className="video no-aspect-ratio"
        ref={(ref) => (this.videoRef = ref)}
      >
        <img src={video.video?.previewUrl} />
        <iframe
          src={`${video.video?.url}?modestbranding=1`}
          frameBorder="0"
        ></iframe>
      </div>
    );
  }
}

export default VideoYoutube;
