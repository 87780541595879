import lscache from 'lscache';

/**
 * Initial state
 */
const initialCommonStateServiceData = {
  cmsLanguage: 'languagePrimary',
  pimLanguage: 'en',
  overlayOpen: false,
  shopUrl: '',
  shopToken: '',
  siteUID: 'us',
};

/**
 * Action types
 */
export const SET_CMS_LANGUAGE = 'SET_CMS_LANGUAGE';
export const SET_PIM_LANGUAGE = 'SET_PIM_LANGUAGE';
export const SET_SHOP_DATA = 'SET_SHOP_DATA';
export const SET_SITE_UID = 'SET_SITE_UID';
export const TOGGLE_OVERLAY_OPEN = 'TOGGLE_OVERLAY_OPEN';

/**
 * Action creators
 */
export const setCmsLanguage = (payload) => ({
  type: SET_CMS_LANGUAGE,
  payload: payload,
});
export const setPimLanguage = (payload) => ({
  type: SET_PIM_LANGUAGE,
  payload: payload,
});
export const setShopData = (payload) => ({
  type: SET_SHOP_DATA,
  payload: payload,
});
export const setSiteUID = (payload) => ({
  type: SET_SITE_UID,
  payload: payload,
});
export const toggleOverlayOpen = (payload) => ({
  type: TOGGLE_OVERLAY_OPEN,
  payload: payload,
});

/**
 * Reducer
 */
export default {
  commonStateService(
    state = lscache.get('commonStateServiceData') ||
      initialCommonStateServiceData,
    { type, payload }
  ) {
    const newState = { ...state };

    switch (type) {
      case SET_CMS_LANGUAGE:
        newState.cmsLanguage = payload;
        break;
      case SET_PIM_LANGUAGE:
        newState.pimLanguage = payload;
        break;
      case SET_SHOP_DATA:
        newState.shopUrl = payload.shopUrl;
        newState.shopToken = payload.shopToken;
        break;
      case SET_SITE_UID:
        newState.siteUID = payload.siteUID;
        break;
      case TOGGLE_OVERLAY_OPEN:
        newState.overlayOpen = payload;
        break;
      default:
        break;
    }

    lscache.set('commonStateServiceData', newState);
    return newState;
  },
};
