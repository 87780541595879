import { gql } from '@apollo/client';

const productFragment = gql`
  fragment Product on Product {
    description
    handle
    id
    images(first: 10) {
      edges {
        node {
          url
        }
      }
    }
    inventory: metafield(namespace: "mustad-online-store", key: "inventory") {
      value
    }
    variants(first: 250) {
      edges {
        node {
          availableForSale
          compareAtPrice {
            amount
            currencyCode
          }
          currentlyNotInStock
          id
          price {
            amount
            currencyCode
          }
          sku
        }
      }
    }
  }
`;

export const productByHandle = ({ countryCode, handle }) => {
  return {
    query: gql`
      ${productFragment}
      query ($countryCode: CountryCode!, $handle: String!)
      @inContext(country: $countryCode) {
        productByHandle(handle: $handle) {
          ...Product
        }
      }
    `,
    variables: {
      countryCode: countryCode,
      handle: handle,
    },
  };
};

export const collectionByHandle = ({ countryCode, handle }) => {
  return {
    query: gql`
      ${productFragment}
      query ($countryCode: CountryCode!, $handle: String!)
      @inContext(country: $countryCode) {
        collectionByHandle(handle: $handle) {
          products(first: 250) {
            edges {
              node {
                ...Product
              }
            }
          }
        }
      }
    `,
    variables: {
      countryCode: countryCode,
      handle: handle,
    },
  };
};
