import React, { Component } from 'react';

class Table extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { table } = this.props;

    const parseCell = (cell) => {
      const email = String(cell)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (email) return <a href={`mailto:${cell}`}>{cell}</a>;

      return cell;
    };

    const tableRows = table.table.rows;

    return (
      <table>
        <thead>
          <tr>
            {tableRows[0].cells.map((cell) => (
              <td>{cell}</td>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableRows.slice(1).map((row) => (
            <tr>
              {row.cells.map((cell) => (
                <td>{parseCell(cell)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default Table;
