import React, { Component } from 'react';

class Image extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { image } = this.props;

    if (!image.src) return null;

    return (
      <div className="image">
        <img
          alt={''}
          src={image.src}
          srcSet={image.srcSet}
          sizes={image.sizes}
        />
      </div>
    );
  }
}

export default Image;
