import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import Popup from '../_common/Popup';
import { toggleOverlayOpen } from '../../state/commonStateService';

class Publication extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onClose = this.onClose.bind(this);

    this.initialState = {
      publicationOpen: false,
    };

    this.state = { ...this.initialState };
  }

  onClick() {
    this.setState((prevState) => ({
      ...prevState,
      publicationOpen: true,
    }));
  }

  onClose() {
    this.setState((prevState) => ({
      ...prevState,
      publicationOpen: false,
    }));
  }

  render() {
    const { publication, site } = this.props;

    return (
      <>
        <div className="publication" onClick={this.onClick}>
          <div className="publication-image">
            <img src={publication.previewUrl} />
          </div>
          <div className="publication-title">
            <h2>{publication.title}</h2>
          </div>
        </div>

        <Popup
          className="publication-embed-popup"
          onClose={this.onClose}
          noPadding={true}
          visible={this.state.publicationOpen}
        >
          <iframe
            src={`https://e.issuu.com/embed.html?d=${
              publication.publicationId
            }&hideIssuuLogo=true&hideShareButton=true&u=omustadson&backgroundColor=${'%23000000'}`}
          />
        </Popup>
      </>
    );
  }
}

export default compose(
  connect((state) => ({
    overlayOpen: state.commonStateService.overlayOpen,
  }))
)(Publication);
