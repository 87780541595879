import Article from '../Article';
import Slice from './Slice';

class RelatedArticle extends Slice {
  constructor(slice, siteUID) {
    super(slice);

    this.article = new Article(slice.relatedArticle, siteUID);
  }
}

export default RelatedArticle;
