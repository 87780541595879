import React from 'react';

import Link from '../Link';
import { Check } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';
import { setCookieNotice } from '../../state/notificationService';

const Cookies = ({ dispatch, site }) => {
  const onAcceptClick = () => {
    dispatch(setCookieNotice(false));
  };

  return (
    <div className="notification">
      <div className="notification__description">
        {getCmsValue(site.strings.notifications__cookiesText, site)}
      </div>
      <div className="notification__buttons">
        <button
          className="notification__button--accept"
          onClick={onAcceptClick}
        >
          {Check}{' '}
          {getCmsValue(site.strings.notifications__cookiesAcceptButton, site)}
        </button>
        <Link className="notification__button--more" site={site}>
          {getCmsValue(site.strings.notifications__cookiesReadMoreButton, site)}
        </Link>
      </div>
    </div>
  );
};

export default Cookies;
