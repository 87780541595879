class Document {
  constructor(document, siteUID) {
    this.id = document.id;
    this.path = document.uid?.current;

    this.published = new Date(document._createdAt);
    this.updated = new Date(document._updatedAt);

    this.siteUID = siteUID;
  }
}

export default Document;
