import Link from '../Link';
import React, { Component } from 'react';

import Logos from '../_common/Logos';

class Logo extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { brand, site } = this.props;

    return (
      <div className="site-header__logo">
        <Link site={site} to={site}>
          {Logos[brand.brandUID].default}
        </Link>
      </div>
    );
  }
}

export default Logo;
