import { connect } from 'react-redux';
import React, { Component } from 'react';

import DesktopMenu from './DesktopMenu';
import MenuItemModel from '../../models/MenuItem';
import MobileMenu from './MobileMenu';
import { toggleMenuOpen } from '../../state/menuService';

class ExpandableMenu extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { dispatch } = this.props;
    dispatch(toggleMenuOpen(false));
  }

  render() {
    const { activeMenuKey, menuOpen, menus, screenSize, site } = this.props;

    const classNames = [
      menuOpen ? 'expandable-menu--open' : '',
      screenSize === 'small' ? 'mobile' : 'desktop',
    ].join(' ');

    const mobileMenu = {
      ...menus[activeMenuKey || 'headerMenu'],
      children: menus[activeMenuKey || 'headerMenu'].children.concat([
        new MenuItemModel({
          ...menus.regionMenu,
          title: site.strings.menu__regionsMenuTitle,
        }),
      ]),
    };

    return (
      <div className={`expandable-menu ${classNames}`}>
        {screenSize === 'small' ? (
          <MobileMenu onClick={this.onClick} menu={mobileMenu} site={site} />
        ) : (
          <DesktopMenu
            onClick={this.onClick}
            menu={menus[activeMenuKey || 'headerMenu']}
            site={site}
          />
        )}
      </div>
    );
  }
}

export default connect((state) => ({
  activeMenuKey: state.menuService.activeMenuKey,
  menuOpen: state.menuService.menuOpen,
}))(ExpandableMenu);
