import React, { Component } from 'react';
import SiteLoader from '../../../_common/SiteLoader';

class RedemptionOption extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
    this.onCopy = this.onCopy.bind(this);
    this.onRedemption = this.onRedemption.bind(this);

    this.initialState = {
      code: null,
      copied: false,
      loading: false,
    };
    this.state = { ...this.initialState };
  }

  onClick() {
    if (!this.state.code) this.onRedemption();
    else this.onCopy();
  }

  async onRedemption() {
    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const { customer, loyalty, onRedemption, redemptionOption } = this.props;
    const redemption = await loyalty.redemptions(
      customer.email,
      redemptionOption.id
    );

    this.setState((prevState) => ({
      ...prevState,
      code: redemption.code,
      loading: false,
    }));

    onRedemption();
  }

  async onCopy() {
    const { onCopy } = this.props;
    const { code } = this.state;

    navigator.clipboard.writeText(code);

    this.setState((prevState) => ({
      ...prevState,
      copied: true,
    }));

    await new Promise((resolve) => setTimeout(() => resolve(), 2000));

    this.setState((prevState) => ({
      ...prevState,
      code: null,
      copied: false,
    }));

    onCopy();
  }

  render() {
    const { code, copied, loading } = this.state;
    const { redemptionOption } = this.props;

    let buttonContent;
    if (!code && !loading) {
      buttonContent = <span>REDEEM NOW</span>;
    } else if (!code && loading) {
      buttonContent = <SiteLoader viewBox={'52 52 36 36'} />;
    } else if (!loading && code && !copied) {
      buttonContent = <span>{code}</span>;
    } else if (!loading && code && copied) {
      buttonContent = <span>COPIED</span>;
    }

    return (
      <li
        className={`loyalty__redemption-option ${
          code && !copied ? 'redemption-option--code' : ''
        }`}
      >
        <h4>
          {redemptionOption.name}
          {!code && ` (${redemptionOption.cost_text})`}
        </h4>
        <button className="redemption-option__redeem" onClick={this.onClick}>
          {buttonContent}
        </button>
      </li>
    );
  }
}

export default RedemptionOption;
