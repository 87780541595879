import React from 'react';

export default {
  omsas: {
    default: (
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 121.11 18.81">
        <g>
          <path
            fill="none"
            d="M62.6,12.53c-.46.26-1.21.72-1.17,1,0,.05,0,.13.19.16l.17,0a1,1,0,0,0,.69-.29.87.87,0,0,0,.26-.8l0-.13Z"
          />
          <path
            fill="none"
            d="M18.42,1.13h-.1c-1.54,0-4.76,2.22-8.67,6a42.77,42.77,0,0,0-6.3,7.31l-.21.36.35-.22a2.13,2.13,0,0,1,.73-.21,8.65,8.65,0,0,0,1.84-.51c1.43-.59,6.36-4.56,7.48-5.52s5.47-6,5.16-7A.27.27,0,0,0,18.42,1.13Z"
          />
          <path
            fill="none"
            d="M95.63,14.11c-.26.18-1,.92-.87,1.17,0,0,0,.08.15.08H95c.35-.08.87-.89.91-1.12a.15.15,0,0,0-.06-.16C95.75,14,95.66,14.09,95.63,14.11Z"
          />
          <path
            fill="none"
            d="M82.51,13.73c0,.18-.07,1.07.28,1.28a4.89,4.89,0,0,0,1,.35l.27.06-1.56-1.93Z"
          />
          <path
            fill="none"
            d="M107.85,5.14c.79-.44,1.13-1,1-1.25,0-.07-.15-.23-.49-.11A21.34,21.34,0,0,0,105.32,6l-.4.34.5-.17A18.79,18.79,0,0,0,107.85,5.14Z"
          />
          <path
            fill="none"
            d="M105.62,12.53a2.15,2.15,0,0,0-.72,1.13l-.05.16.17,0c.08,0,.85-.22,1-.64a1.69,1.69,0,0,1,.12-.28c.09-.22.16-.37.06-.46S105.94,12.35,105.62,12.53Z"
          />
          <path
            fill="none"
            d="M2.25,15.51c-.14.14-.82.82-.64,1.18a.19.19,0,0,0,.14.11h.08a4.48,4.48,0,0,0,1.5-.75L3.48,16l-1.19-.48Z"
          />
          <path
            fill="none"
            d="M70.44,12l-.12.08a5.23,5.23,0,0,0-1.38,1.27c-.11.2,0,.3,0,.35a.27.27,0,0,0,.16,0,2.45,2.45,0,0,0,1.17-.55h0a1.18,1.18,0,0,0,.19-1.05Z"
          />
          <path
            fill="white"
            d="M19.77.23c-.28-.31-1.05-.31-2.17,0-2.06.62-6.44,4.47-8.54,6.32a85.87,85.87,0,0,0-6.94,7.84l-.07.09L2,14.42A1.42,1.42,0,0,0,1.21,14h0a.29.29,0,0,0-.22.09c-.28.28.19.93.39,1.16l.14.15H1.36L.6,16.48a2.63,2.63,0,0,0-.56,2,.53.53,0,0,0,.3.36c.39.15,2-1,3.65-2.07.57-.39,1.16-.79,1.75-1.17a70.15,70.15,0,0,0,7.9-6.33,40.89,40.89,0,0,0,6.14-8C20,.75,20,.43,19.77.23ZM3.33,16.06a4.48,4.48,0,0,1-1.5.75H1.75a.19.19,0,0,1-.14-.11c-.18-.36.5-1,.64-1.18l0,0L3.48,16ZM13.54,8.31c-1.12,1-6,4.93-7.48,5.52a8.65,8.65,0,0,1-1.84.51,2.13,2.13,0,0,0-.73.21l-.35.22.21-.36A42.77,42.77,0,0,1,9.65,7.1c3.91-3.76,7.13-6,8.67-6h.1a.27.27,0,0,1,.28.19C19,2.29,14.51,7.49,13.54,8.31Z"
          />
          <path
            fill="white"
            d="M11.38,15.72a1.65,1.65,0,0,0-.85-1h0c-.5.32-.59.73-.29,1.38s.77,1.45,1.1,1.51a.2.2,0,0,0,.19-.08c.35-.36.15-1,0-1.48C11.45,15.94,11.41,15.83,11.38,15.72Z"
          />
          <path
            fill="white"
            d="M50.74,11.4c-.88-.45-3.15-.39-5.13.89s-2.14,1.42-2.15,1.42l-.09.08-.06-.11S43,13,44,12.05l.29-.25c1-.84,1.48-1.33,1.13-1.84S45,9.4,44.88,9.4h0c-.08,0-.14.12-.18.21a4.89,4.89,0,0,1-1.34,1.19,11,11,0,0,0-1.08.85l-.18.17A7.51,7.51,0,0,1,40,13.38a6.2,6.2,0,0,1-1.24.43h-.13l0-.13a4,4,0,0,1,1.52-2.35c1.22-1,2-1.84,1.92-2.2A.18.18,0,0,0,41.94,9c-.62-.25-.87.07-1.25.55a27.39,27.39,0,0,1-3.46,2.72c-.67.41-2,1-2,1l-.22.09.08-.22a16.57,16.57,0,0,1,1.81-3.62,28.82,28.82,0,0,1,4-4c2.2-1.84,2.61-2.39,2.56-2.53a1.25,1.25,0,0,0-.58-.7.34.34,0,0,0-.28,0c-.26.16-1.82,1.58-3.2,2.83-.88.79-1.7,1.54-2.1,1.88-1.42,1.22-4.87,4.15-6.37,5.14-.14.09-3.27,2.13-3.67,1.73-.1-.1-.32-.32,1.71-2.39.84-.85,4.29-4.31,5.25-5.11a47.29,47.29,0,0,0,3.64-3.31c.28-.42.55-1.54.3-1.74s-1,.11-1.67.84c-.26.29-1,1-1.79,1.75C33.43,5.1,32,6.42,31.58,7c-.77.94-3.26,3.74-3.72,4.23s-3.4,2.93-4.18,3.49A26.84,26.84,0,0,1,19.93,17a13.52,13.52,0,0,1-2.17.64l-.09,0,0-.09a3.86,3.86,0,0,0-.72-1.6l0,0h0a.37.37,0,0,0-.24.2,1.92,1.92,0,0,0,.07,1.26c.12.35.57,1.46,1.43,1.24a26.06,26.06,0,0,0,5.09-2.56A21,21,0,0,0,25.7,13.9l.44-.46-.28.58c-.31.64-.74,1.78-.56,2.07a.11.11,0,0,0,.11.05h0c.7,0,3.18-1.46,5-2.77s5.8-4.44,5.84-4.48l.64-.5-.5.64c-.73.94-2.6,3.55-2.45,4.35a1.28,1.28,0,0,0,.63.95,1.43,1.43,0,0,0,1.22.09,3.69,3.69,0,0,0,1.45-1.13l.32-.43-.14.52c-.11.38-.33,1.47,0,1.85a.34.34,0,0,0,.29.13h0A6.35,6.35,0,0,0,40.62,14c.68-.47,1.23-.87,1.23-.87l.24-.17-.08.28c-.12.39-.35,1.45-.08,1.75,0,.05.1.1.26.07a15,15,0,0,0,2.87-1.29c.69-.36,1.41-.74,2-1a3.59,3.59,0,0,1,2.88-.24.63.63,0,0,1,.31.53c0,.87-.82,1.49-1.66,1.72l-.06,0c-.54.14-1.28.35-1.47.76a.68.68,0,0,0,.06.58.71.71,0,0,0,.56.35,4.48,4.48,0,0,0,3.1-1.35,3.38,3.38,0,0,0,.61-2.69A1.41,1.41,0,0,0,50.74,11.4Z"
          />
          <path
            fill="white"
            d="M62.68,6.68h0a6.35,6.35,0,0,0,1,0c.48,0,1.53.13,2.55.25.81.1,1.57.18,1.94.2,1,0,1.19-.08,1.24-.14a.08.08,0,0,0,0-.07,7.1,7.1,0,0,0-2.55-.65c-.79-.09-2.68-.45-2.7-.45l-.13,0,.06-.12a4.33,4.33,0,0,0,.38-1.19.49.49,0,0,0-.18-.49.35.35,0,0,0-.39.07c-.21.15-.49.4-.77.65s-.54.48-.69.59a1.53,1.53,0,0,1-1,.07L61,5.37c-.7,0-1.24.14-1.35.49l-.07.19,0,.06a.3.3,0,0,0,.07.11l.18.29c.14.25.36.2.64.12a1,1,0,0,1,.21,0,.17.17,0,0,1,.12,0,.21.21,0,0,1,.05.18c0,.27-.58.67-1.42,1.25-.27.19-.52.36-.69.5l-.51.4c-.65.51-1.52,1.19-2.18,1.78a12.18,12.18,0,0,0-2.55,3.39,1.74,1.74,0,0,0,0,1.43,1.36,1.36,0,0,0,.84.73,1.14,1.14,0,0,0,1-.22.82.82,0,0,0,.3-.68c0-.22-.17-.26-.39-.31s-.41-.1-.4-.38c0-.45.53-1,1.21-1.8s1.8-1.93,2.86-3,3.68-3.18,3.71-3.2Z"
          />
          <path
            fill="white"
            d="M66.31,10.74a.9.9,0,0,0-.54-.57,2.1,2.1,0,0,0-1.68.13A8.49,8.49,0,0,0,61.59,12a5.9,5.9,0,0,0-1.65,2.19c-.07.23-.11.54.05.7s.42.14.83,0a3.39,3.39,0,0,1,1-.14,1.57,1.57,0,0,1,.76.15l.06,0V15a2.46,2.46,0,0,0,0,1.17,4,4,0,0,0,.34.54l.83-1.6c.5-1,.62-1.66.35-2a1.27,1.27,0,0,1-.3-1,.74.74,0,0,1,.32-.5,1.36,1.36,0,0,1,1.16,0l.07,0v.07c0,.34,0,.88.13,1s0,0,.07,0A2,2,0,0,0,66.31,10.74ZM62.48,13.4a1,1,0,0,1-.69.29l-.17,0c-.15,0-.18-.11-.19-.16,0-.26.71-.72,1.17-1l.12-.06,0,.13A.87.87,0,0,1,62.48,13.4Z"
          />
          <path
            fill="white"
            d="M78.55,5.25a.75.75,0,0,0-.28.07A9.71,9.71,0,0,0,76,7.19c-.21.21-.4.39-.55.52l-2.18,2,0,0A10.68,10.68,0,0,0,68.82,12a12.28,12.28,0,0,0-2.72,3.94c-.11.44-.15,1,.07,1.18s.42,0,.79-.22c.88-.6,2.24-1.78,3.23-2.64l1-.84a2.59,2.59,0,0,1,1.4-.75h.16l-.06.14a14.68,14.68,0,0,0-.78,2,1.51,1.51,0,0,0,.24,1.2.21.21,0,0,0,.24,0c.46-.17,1.07-1.58,1.28-3a9,9,0,0,1,2-3.81c.3-.3.93-.83,1.54-1.33a18,18,0,0,0,1.43-1.24,1.36,1.36,0,0,0,.12-1.32A.27.27,0,0,0,78.55,5.25Zm-8.27,8h0a2.45,2.45,0,0,1-1.17.55.27.27,0,0,1-.16,0c-.06-.05-.12-.15,0-.35a5.23,5.23,0,0,1,1.38-1.27l.12-.08,0,.14A1.18,1.18,0,0,1,70.28,13.21Z"
          />
          <path
            fill="white"
            d="M87.3,13.73c-.57.17-2.11.83-2.12.83l-.08,0,0-.06L83.5,12.31l0-.05c.27-.56.61-1.54.36-1.8s-.68-.65-.83-.78c-.26.21-1.2,1-1.19,1.66s.13.83.14.83l.08.05L82,12.3a2.94,2.94,0,0,0-.42,2.09c.29.92.9,2,2.15,2h.16c1.28-.09,1.49-.29,1.5-.3l.07-.12.08.12s.69.85,1.29.59c1.46-.65,1.51-.88,1.51-.9l-.06,0a6.77,6.77,0,0,0-1.48-.33h-.12l0-.11s.19-.64,1.08-.64h.08c.8,0,1.21-.13,1.42-.56a4,4,0,0,0-.24-1.78A7.18,7.18,0,0,1,87.3,13.73Zm-3.47,1.63a4.89,4.89,0,0,1-1-.35c-.35-.21-.29-1.1-.28-1.28l0-.24,1.56,1.93Z"
          />
          <path
            fill="white"
            d="M102.5,8.66a22.86,22.86,0,0,0,6.36-3.41c2.89-2.48,3.75-3.66,3.62-3.85s-.57-.52-2.11.31l-.4.22A17.68,17.68,0,0,0,106.64,4c-.63.63-3.26,2.93-3.37,3l0,0a18.51,18.51,0,0,1-3.48.69h-.21s-.09.07-.13.19c-.13.38-.06,1,.08,1.08a4.1,4.1,0,0,0,1,.27l.18,0-.13.14a15,15,0,0,1-1.19,1.13c-.18.14-.59.54-1,1a10.77,10.77,0,0,1-1.74,1.52.7.7,0,0,1-.4,0,1.81,1.81,0,0,0-.31,0,.48.48,0,0,0-.41.22l-.32.37c-.47.53-1.25,1.42-1.13,1.84a.82.82,0,0,0,.57.57c.3.07.79,0,1.51-.83,1.21-1.38,3.16-3.24,4.21-4.22.78-.73,2.17-2.22,2.18-2.24l0,0ZM105.32,6a21.34,21.34,0,0,1,3.06-2.26c.34-.12.45,0,.49.11.11.27-.23.81-1,1.25a18.79,18.79,0,0,1-2.43,1.07l-.5.17ZM95,15.35h-.07c-.1,0-.14,0-.15-.08-.11-.25.61-1,.87-1.17,0,0,.12-.09.2,0a.15.15,0,0,1,.06.16C95.85,14.46,95.33,15.27,95,15.35Z"
          />
          <path
            fill="white"
            d="M119.38,14.3a15.11,15.11,0,0,1-2.77.55l-.18,0,.09-.17c0-.07,1-1.82,1.52-2.53a.5.5,0,0,0-.08-.67,1.35,1.35,0,0,0-1.69,0,27.69,27.69,0,0,1-4.7,2.43.19.19,0,0,1-.17-.06c-.11-.14,0-.42.16-.94a4.06,4.06,0,0,0,.29-1.17,2.23,2.23,0,0,0-.71-1.43.82.82,0,0,0-.63-.22c-.39.06-.62.37-.85.71a1.85,1.85,0,0,1-.7.69,15.6,15.6,0,0,1-2,.62l0-.19c.61-.2,1.26-.53,1.27-.72s-.08-.11-.21-.16c-.77-.31-2-.36-3.37,1.1-1,1-2.33,2.46-2.06,3a.68.68,0,0,0,.44.33,2.61,2.61,0,0,0,1.83-.54,28.09,28.09,0,0,1,3.83-2.14,11.85,11.85,0,0,0,1.56-.72l.12-.07,0,.13a2.73,2.73,0,0,1-.24,1.82l-.14.22c-.32.49-.71,1.11-.58,1.48a.48.48,0,0,0,.33.28c1,.34,2.4-.88,2.82-1.27a7.43,7.43,0,0,1,2.8-1l.17,0-.06.16s-.62,1.55-.23,2.21,1,.3,2.67-.3l.85-.3c1.82-.64,2.45-1.2,2.41-1.32S120.74,13.9,119.38,14.3Zm-13.27-1.44a1.69,1.69,0,0,0-.12.28c-.12.42-.89.62-1,.64l-.17,0,.05-.16a2.15,2.15,0,0,1,.72-1.13c.32-.18.46-.21.55-.13S106.2,12.64,106.11,12.86Z"
          />
          <path
            fill="white"
            d="M110.88,7.75c-.21.12-.26.6-.1,1.05.09.28.29.56.54.51a5,5,0,0,1,1-.11,2.36,2.36,0,0,1,.9.17l.61.27a9.63,9.63,0,0,0,2,.79c0-.15-.52-1-1.31-1.33a8.7,8.7,0,0,1-1-.51C112.53,8.09,111.41,7.48,110.88,7.75Z"
          />
        </g>
      </svg>
    ),
  },
  'mustad-fishing': {
    default: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 141 28"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <g>
          <path d="M50.5,19.4c0.1,2.2-2.1,3.5-4,3.5c-2.5,0-3.6-1.6-3.6-4V5.4h-6.3v14.7c0,5,2.9,8.3,7.9,8.3c2.6,0,4.8-1.2,6.1-2.6V28h6.3V5.4h-6.3V19.4z" />
          <path d="M72.2,14.7l-2.5-0.8c-1.6-0.5-2.4-0.9-2.4-2.1c0-1.3,1.3-1.9,3-1.9c2.6,0,4.9,1.2,6.9,2.8V6.9c-2-1.3-4.3-2-7.2-2c-5.4,0-8.8,2.7-8.8,7.1c0,3.9,2.5,5.8,6.4,6.8l2.2,0.6c1.6,0.5,2.6,1,2.6,2.2c0,1.4-1.5,1.9-3.3,1.9c-2.5,0-5.6-1.2-7.8-3.1v5.8c1.9,1.5,5,2.3,8,2.3c5.3,0,8.7-2.4,8.7-7C77.9,17.9,76.5,15.9,72.2,14.7z" />
          <path d="M21.8,2.4c-0.9,2.6-5.5,16.7-5.5,16.7l0,0l0,0c0,0-4.7-14-5.5-16.7C9.8-0.2,7.1,0,7.1,0L0,0v28.1l6.3,0l0-19.4l6.9,19.5l6.2,0l6.9-19.5l0,19.4l6.2,0V0l-7.1,0C25.4,0,22.7-0.2,21.8,2.4z" />
          <path d="M140.9,28l0-28l-6.2,2l0,5.2c-1-1.2-3.2-2.4-5.7-2.4c-6.6,0-10.2,5.4-10.2,11.8c0,6.5,3.7,11.8,10.2,11.8c2.4,0,4.6-1.2,5.7-2.4v2H140.9z M130.5,22.9C130.5,22.9,130.5,22.9,130.5,22.9c-3.4,0-5.5-2.7-5.5-6.3c0-3.6,2-6.4,5.4-6.4c0.2,0,0.4,0,0.6,0c2.1,0.3,3.6,2.3,3.6,4.4v3.8C134.7,20.8,132.9,22.9,130.5,22.9z" />
          <path d="M106.2,4.9c-3,0-5.9,0.9-7.5,1.9v5.4c1.8-1.2,3.9-1.8,6.2-1.8c3.2,0,5,1.3,5,3.5v1.3c-0.9-0.7-3.1-1.3-5-1.3c-5,0-8.7,2.9-8.7,7.2c0,4.7,3.8,7.3,8.3,7.3c2.5,0,4.7-0.9,5.4-1.6V28l6.2,0V13.6C116.1,8.1,112.4,4.9,106.2,4.9z M109,23.1c-0.8,0.6-1.9,1-3.1,1c-2,0-4-0.6-4-3c0-2.3,2-3,4-3c1.2,0,2.4,0.4,3.1,1c0.6,0.4,0.6,1.3,0.6,2C109.6,21.7,109.6,22.6,109,23.1z" />
          <path d="M89.6,0l-6.3,2v4.2h0v0.3h-3.2v5.1h3.2v7.6c0,6.5,2.2,9.1,9.9,9.1l0-6.1c-3.3,0-3.6-1.7-3.6-2.9l0-7.8h3.6V6.5h-3.6V0z" />
        </g>
      </svg>
    ),
    small: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 36 27"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <path d="M27.8,0c-0.2,0-2.7-0.1-3.9,2.6C22.8,4.9,19,14.7,18,17c-1-2.3-4.8-12.2-5.8-14.4C10.9-0.1,8.1,0,8.1,0H0v26.9l7.4,0l0-16.7l7.1,16.7h7.2l7.1-16.7l0,16.7l7.4,0V0L27.8,0z" />
      </svg>
    ),
  },
  'tuf-line': {
    default: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 300.32 66.12"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
        	.tuf-svg-yella{fill:#E9C103;}
        	.tuf-svg-white{fill:#FFFFFF;}`}
        </style>
        <g>
          <polygon
            className="tuf-svg-yella"
            points="45.37,0 3.63,0 0,15.66 12.75,15.66 1.18,66.12 17.34,66.12 28.91,15.66 41.74,15.66"
          />
          <polygon
            className="tuf-svg-yella"
            points="77.25,0 65.66,50.49 55.54,50.49 67.12,0 50.96,0 38.44,54.45 45.19,66.12 69.21,66.12 80.76,55 93.41,0"
          />
          <polygon
            className="tuf-svg-yella"
            points="242.92,39.83 246.06,26.29 111.07,26.29 113.49,15.66 133.26,15.66 136.9,0 100.96,0 85.75,66.12 101.91,66.12 109.17,34.66 112.17,39.83"
          />
        </g>
        <g>
          <polygon
            className="tuf-svg-white"
            points="268.68,0 257.12,11.13 247.16,54.45 253.91,66.12 285.11,66.12 288.74,50.49 264.27,50.49 267.9,34.65 270.9,39.83 291.18,39.83 294.28,26.29 269.79,26.29 272.22,15.63 296.69,15.63 300.32,0"
          />
          <g>
            <polygon
              className="tuf-svg-white"
              points="193.37,66.12 209.53,66.12 214.86,42.97 198.65,42.97"
            />
            <polygon
              className="tuf-svg-white"
              points="215.95,42.97 220.46,66.12 236.92,66.12 242.21,42.97"
            />
            <polygon
              className="tuf-svg-white"
              points="169.65,66.12 185.82,66.12 191.12,42.97 174.95,42.97"
            />
            <polygon
              className="tuf-svg-white"
              points="150.71,42.97 134.54,42.97 129.24,66.12 164.07,66.12 167.7,50.49 149.04,50.49"
            />
          </g>
          <g>
            <polygon
              className="tuf-svg-white"
              points="235.97,0 230.46,23.15 246.78,23.15 252.13,0"
            />
            <polygon
              className="tuf-svg-white"
              points="208.58,0 203.25,23.15 229.56,23.15 225.04,0"
            />
            <polygon
              className="tuf-svg-white"
              points="139.13,23.15 155.29,23.15 160.61,0 144.45,0"
            />
            <polygon
              className="tuf-svg-white"
              points="179.55,23.15 195.71,23.15 201.03,0 184.86,0"
            />
          </g>
        </g>
      </svg>
    ),
    defaultMono: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 300.32 66.12"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
        	.tuf-svg-mono{fill:#FFFFFF;}
          `}
        </style>
        <g>
          <polygon
            className="tuf-svg-mono"
            points="45.37,0 3.63,0 0,15.66 12.75,15.66 1.18,66.12 17.34,66.12 28.91,15.66 41.74,15.66"
          />
          <polygon
            className="tuf-svg-mono"
            points="77.25,0 65.66,50.49 55.54,50.49 67.12,0 50.96,0 38.44,54.45 45.19,66.12 69.21,66.12 80.76,55 93.41,0"
          />
          <polygon
            className="tuf-svg-mono"
            points="242.92,39.83 246.06,26.29 111.07,26.29 113.49,15.66 133.26,15.66 136.9,0 100.96,0 85.75,66.12 101.91,66.12 109.17,34.66 112.17,39.83"
          />
        </g>
        <g>
          <polygon
            className="tuf-svg-mono"
            points="268.68,0 257.12,11.13 247.16,54.45 253.91,66.12 285.11,66.12 288.74,50.49 264.27,50.49 267.9,34.65 270.9,39.83 291.18,39.83 294.28,26.29 269.79,26.29 272.22,15.63 296.69,15.63 300.32,0"
          />
          <g>
            <polygon
              className="tuf-svg-mono"
              points="193.37,66.12 209.53,66.12 214.86,42.97 198.65,42.97"
            />
            <polygon
              className="tuf-svg-mono"
              points="215.95,42.97 220.46,66.12 236.92,66.12 242.21,42.97"
            />
            <polygon
              className="tuf-svg-mono"
              points="169.65,66.12 185.82,66.12 191.12,42.97 174.95,42.97"
            />
            <polygon
              className="tuf-svg-mono"
              points="150.71,42.97 134.54,42.97 129.24,66.12 164.07,66.12 167.7,50.49 149.04,50.49"
            />
          </g>
          <g>
            <polygon
              className="tuf-svg-mono"
              points="235.97,0 230.46,23.15 246.78,23.15 252.13,0"
            />
            <polygon
              className="tuf-svg-mono"
              points="208.58,0 203.25,23.15 229.56,23.15 225.04,0"
            />
            <polygon
              className="tuf-svg-mono"
              points="139.13,23.15 155.29,23.15 160.61,0 144.45,0"
            />
            <polygon
              className="tuf-svg-mono"
              points="179.55,23.15 195.71,23.15 201.03,0 184.86,0"
            />
          </g>
        </g>
      </svg>
    ),
    medium: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 136.9 66.12"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <style type="text/css">
          {`
        	.tuf-svg-yella{fill:#E9C103;}
        	.tuf-svg-white{fill:#FFFFFF;}
          `}
        </style>
        <g className="tuf-svg-yella">
          <polygon points="45.37 0 3.63 0 0 15.66 12.75 15.66 1.18 66.12 17.34 66.12 28.91 15.66 41.74 15.66 45.37 0"></polygon>
          <polygon points="77.25 0 65.66 50.49 55.54 50.49 67.12 0 50.96 0 38.44 54.45 45.19 66.12 69.21 66.12 80.76 55 93.41 0 77.25 0"></polygon>
          <polygon points="127.78 39.84 130.88 26.29 111.07 26.29 113.49 15.66 133.26 15.66 136.9 0 100.96 0 85.75 66.12 101.91 66.12 109.17 34.66 112.17 39.84 127.78 39.84"></polygon>
        </g>
      </svg>
    ),
    small: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 136.9 66.12"
        xmlSpace="preserve"
        fill="currentColor"
      >
        <style>{`
          .cls-1{fill:#e9c103;}.cls-2{fill:none;}
        `}</style>
        <polygon
          className="cls-1"
          points="45.37 0 3.63 0 0 15.66 12.75 15.66 1.18 66.12 17.34 66.12 28.91 15.66 41.74 15.66 45.37 0"
        ></polygon>
        <polygon
          className="cls-1"
          points="77.25 0 65.66 50.49 55.54 50.49 67.12 0 50.96 0 38.44 54.45 45.19 66.12 69.21 66.12 80.76 55 93.41 0 77.25 0"
        ></polygon>
        <polygon
          className="cls-1"
          points="127.78 39.84 130.88 26.29 111.07 26.29 113.49 15.66 133.26 15.66 136.9 0 100.96 0 85.75 66.12 101.91 66.12 109.17 34.66 112.17 39.84 127.78 39.84"
        ></polygon>
        <path
          className="cls-2"
          d="M275.21,254.66"
          transform="translate(-138.31 -254.66)"
        ></path>
      </svg>
    ),
  },
  livetarget: {
    default: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 600 61.200001"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`
        	.st0{fill:#C9CACA;}
        	.st1{fill:#0E0000;}
        	.st2{fill:#DB0025;}
        	.st3{fill:#FFFFFF;}
          `}
        </style>
        <g transform="translate(-21,-281.6)">
          <polygon
            className="st1"
            points="621,281.6 432.5,281.6 244.1,281.6 232.8,342.8 609.7,342.8 621,281.6 "
          />
          <polygon
            className="st2"
            points="32.3,281.6 21,342.8 232.8,342.8 244.1,281.6 "
          />
          <polygon
            className="st3"
            points="45.3,322.9 84.2,322.9 82.1,333.8 31.3,333.8 39.4,290.2 51.4,290.2 "
          />
          <polygon
            className="st3"
            points="98.9,333.6 87,333.6 94.9,290.5 106.8,290.5 "
          />
          <polygon
            className="st3"
            points="138.4,322.1 139.9,320.3 164.5,290.5 178.2,290.5 141.5,333.6 131,333.6 110.2,290.4 124.1,290.5 "
          />
          <polygon
            className="st3"
            points="250,290.4 309.4,290.5 307.4,301.2 283.7,301.2 277.7,333.6 265.4,333.6 271.3,301.2 248,301.1 "
          />
          <path
            className="st3"
            d="m 422.6,333.6 h -15.9 l -15.4,-14 h -9 l 1.9,-10.3 h 19.2 v 0 c 4.2,0 6.4,-1.7 6.5,-4.9 0,-0.9 -0.2,-1.6 -0.7,-2.1 -0.7,-0.7 -2,-1.1 -3.9,-1.1 h -27.5 l -6,32.4 h -11.2 l 7.8,-43.1 h 38.9 c 4.7,0 8.3,1.2 10.7,3.6 2,2.1 3.1,5.1 3,8.8 -0.2,9.1 -5.9,15.2 -15.1,16.4 z"
          />
          <path
            className="st3"
            d="m 478.7,333.6 h -34.5 c -6.8,0 -12,-1.8 -15.4,-5.2 -3.1,-3.1 -4.5,-7.6 -4.4,-13.1 0.3,-12 7.6,-24.8 27,-24.8 v 0 h 35.2 l -2,10.7 h -32.2 v 0 c -9.5,0 -14.5,4.3 -14.7,12.8 -0.1,2.8 0.6,4.9 2,6.4 1.7,1.7 4.5,2.6 8.4,2.6 h 21.1 l 1.1,-6 H 452 l 1.9,-9.7 h 29.7 z"
          />
          <polygon
            className="st3"
            points="336.3,317.8 328.4,301.1 301.3,333.5 286.6,333.5 323.4,290.4 335.1,290.5 335.3,290.8 356,333.6 311.6,333.5 319.7,323.8 339.1,323.8 "
          />
          <polygon
            className="st3"
            points="232.7,301.2 192.8,301.1 191.7,306.9 226.5,307 224.7,317 189.9,317 188.8,322.9 228.6,322.9 226.7,333.6 173.7,333.5 181.7,290.4 234.6,290.5 "
          />
          <polygon
            className="st3"
            points="543.9,301.2 504.3,301.1 503.2,306.9 537.8,307 535.9,317 501.3,317 500.2,322.9 539.9,322.9 538,333.6 485,333.5 493,290.4 545.9,290.5 "
          />
          <polygon
            className="st3"
            points="551,290.4 610.4,290.5 608.4,301.2 585,301.2 579.1,333.6 566,333.6 571.9,301.2 549,301.1 "
          />
          <path
            className="st3"
            d="m 604.8,327.2 c 0,3.3 -2.6,5.8 -5.9,5.8 -3.3,0 -5.9,-2.6 -5.9,-5.8 0,-3.2 2.6,-5.7 5.9,-5.7 3.4,0 5.9,2.5 5.9,5.7 m -10.3,0 c 0,2.6 1.9,4.6 4.5,4.6 2.5,0 4.4,-2 4.4,-4.5 0,-2.6 -1.9,-4.6 -4.4,-4.6 -2.6,-0.1 -4.5,2 -4.5,4.5 m 3.6,3 h -1.3 v -5.7 c 0.5,-0.1 1.3,-0.2 2.2,-0.2 1.1,0 1.6,0.2 2,0.4 0.3,0.2 0.6,0.7 0.6,1.3 0,0.6 -0.5,1.1 -1.2,1.3 v 0.1 c 0.6,0.2 0.9,0.6 1,1.4 0.2,0.9 0.3,1.2 0.4,1.4 h -1.4 c -0.2,-0.2 -0.3,-0.7 -0.5,-1.4 -0.1,-0.6 -0.5,-0.9 -1.2,-0.9 h -0.6 z m 0,-3.2 h 0.6 c 0.7,0 1.3,-0.2 1.3,-0.8 0,-0.5 -0.4,-0.9 -1.2,-0.9 -0.3,0 -0.6,0 -0.7,0.1 z"
          />
        </g>
      </svg>
    ),
    defaultMono: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        id="Layer_1"
        viewBox="0 0 923.43 95.87"
      >
        <defs>
          <style>{`.lt-white{fill:#fff;}`}</style>
        </defs>
        <g>
          <path
            className="lt-white"
            d="M402.16,81.5h-19.33l9.35-50.79-36.56-.03,3.09-16.75,93.11,.07-3.09,16.75-37.21-.03-9.35,50.78h-.01Z"
          />
          <path
            className="lt-white"
            d="M629.16,81.5l-24.99-.02-24.13-21.93h-14.1l3.01-16.19,30.13,.02h.02c6.61,0,10.02-2.6,10.15-7.72,.04-1.47-.29-2.52-1.04-3.28-1.08-1.11-3.16-1.68-6.17-1.68l-43.03-.03-9.39,50.78h-17.59l12.22-67.54,60.94,.04c7.43,0,13.05,1.91,16.71,5.67,3.21,3.3,4.83,8.05,4.68,13.75-.37,14.32-9.19,23.78-23.72,25.62l26.29,22.52h0Z"
          />
          <path
            className="lt-white"
            d="M717.1,81.5l-54.07-.04c-10.72,0-18.83-2.77-24.12-8.2-4.8-4.93-7.13-11.84-6.9-20.54,.48-18.76,11.85-38.8,42.3-38.8h.05l55.22,.04-3.13,16.75-50.45-.03h-.04c-14.93,0-22.67,6.74-23.01,20.03-.12,4.45,.9,7.71,3.1,9.97,2.6,2.67,7.03,4.02,13.16,4.03l33.06,.03,1.74-9.4-28.71-.02,2.94-15.24,46.51,.03-7.64,41.39h0Z"
          />
          <path
            className="lt-white"
            d="M524.76,81.5l-69.48-.05,12.74-15.24,30.35,.02-4.41-9.42-12.37-26.17-42.51,50.8h-22.91L473.73,13.93h18.42l.23,.49,32.38,67.08Z"
          />
          <path
            className="lt-white"
            d="M17.67,0L0,95.87H331.75L349.49,0H17.67ZM95.79,81.93l-79.65-.06L28.77,13.49h18.79l-9.47,51.33h60.85l-3.15,17.11Zm26.18-.43h-18.61L115.83,13.96h18.62l-12.48,67.54Zm50.3,0L139.74,13.93h21.75l22.41,49.54,2.38-2.79L224.75,13.99l21.58,.02-57.48,67.5h0s-16.58-.01-16.58-.01ZM331.59,30.72l-62.44-.05-1.68,9.09,54.5,.04-2.91,15.73-54.5-.04-1.7,9.22,62.44,.05-3.09,16.75h0s-82.94-.07-82.94-.07l12.48-67.53,82.94,.06-3.1,16.75Z"
          />
          <path
            className="lt-white"
            d="M809.89,81.5l-82.94-.06,12.47-67.53,82.94,.06-3.1,16.75-62.16-.05-1.68,9.09,54.22,.04-2.9,15.73-54.22-.04-1.7,9.22,62.16,.05-3.09,16.75h0Z"
          />
          <path
            className="lt-white"
            d="M874.3,81.5h-20.49l9.35-50.79-35.93-.03,3.09-16.75,93.11,.07-3.09,16.75-36.69-.03-9.34,50.78h0Z"
          />
        </g>
        <path
          className="lt-white"
          d="M914.68,71.55c0,5.1-4,9.09-9.2,9.09s-9.26-4-9.26-9.09,4.11-8.99,9.26-8.99,9.2,4,9.2,8.99Zm-16.16,0c0,4,2.96,7.18,7.01,7.18s6.85-3.18,6.85-7.12-2.9-7.23-6.9-7.23-6.96,3.23-6.96,7.18h0Zm5.53,4.7h-2.08v-8.99c.82-.16,1.97-.27,3.45-.27,1.7,0,2.47,.27,3.12,.66,.49,.38,.88,1.1,.88,1.97,0,.99-.77,1.75-1.86,2.08v.11c.88,.33,1.37,.99,1.64,2.19,.27,1.37,.44,1.92,.66,2.25h-2.25c-.27-.33-.44-1.15-.71-2.19-.16-.99-.71-1.42-1.86-1.42h-.99v3.62h0Zm.05-5.11h.99c1.15,0,2.08-.38,2.08-1.31,0-.82-.6-1.37-1.92-1.37-.55,0-.93,.05-1.15,.11v2.58h0Z"
        />
      </svg>
    ),
    small: (
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        xmlSpace="preserve"
        viewBox="0 0 16 6.5"
      >
        <defs>
          <style type="text/css">
            {`
.st4{fill:#BA2034;}
.st5{fill:#222221;}
.st6{fill:#FFFFFF;}
`}
          </style>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, -4.7)">
          <polygon
            className="st4"
            points="8.2,4.7 7,11.2 0,11.2 1.2,4.7 &#9;"
          />
          <path
            className="st5"
            d="M8.2,4.7L7,11.2h7.8L16,4.7H8.2z M14.8,6.9h-2.5l-0.6,3.4h-1.3L11,6.9H8.6l0.2-1.1l6.1,0L14.8,6.9z"
          />
          <polygon
            className="st5"
            points="15,5.8 14.8,6.9 12.3,6.9 11.7,10.2 10.4,10.2 11,6.9 8.6,6.9 8.8,5.8 &#9;"
          />
          <polygon
            className="st6"
            points="6.3,10.3 1,10.3 1.9,5.8 3.1,5.8 2.5,9.1 6.5,9.1 &#9;"
          />
          <polygon
            className="st6"
            points="15,5.8 14.8,6.9 12.3,6.9 11.7,10.2 10.4,10.2 11,6.9 8.6,6.9 8.8,5.8 &#9;"
          />
        </g>
      </svg>
    ),
  },
};
