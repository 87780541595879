class Menu {
  constructor(menu) {
    this.children = menu.children || [];
    this.featuredChildren = menu.featuredChildren || [];
    this.id = menu.id || null;
    this.title = menu.title || null;
    this.type = menu.target ? menu.target._type : null;
  }
}

export default Menu;
