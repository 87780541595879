import React, { createContext, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import Checkout from './Checkout';
import DiscountCode from './DiscountCode';
import LineItems from './LineItems';
import SiteLoader from '../SiteLoader';
import Totals from './Totals';
import withCart from '../../../containers/plugins/withCart';

export const CartContext = createContext(null);

const Cart = ({
  addDiscountCode,
  cart,
  cartLoading,
  loadCart,
  onContinueShopping,
  removeDiscountCode,
  updateLineItem,
  site,
}) => {
  useEffect(() => !cart && loadCart(), []);

  return (
    <div className="cart">
      {cartLoading ? (
        <SiteLoader />
      ) : (
        <CartContext.Provider value={{ site, updateLineItem }}>
          <LineItems lineItems={cart?.lines || []} />

          <footer className="cart__footer">
            <DiscountCode
              addDiscountCode={addDiscountCode}
              cart={cart}
              removeDiscountCode={removeDiscountCode}
              site={site}
            />
            <Totals cart={cart} site={site} />
            <Checkout
              cart={cart}
              onContinueShopping={onContinueShopping}
              site={site}
            />
          </footer>
        </CartContext.Provider>
      )}
    </div>
  );
};

export default compose(
  withCart,
  connect((state) => ({
    cart: state.shopService.cart,
    cartLoading: state.shopService.cartLoading,
  }))
)(Cart);
