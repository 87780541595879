import React, { Component } from 'react';

import Slice from '../slices/Slice';

class ProductGroups extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { productGroups, site } = this.props;

    return productGroups.productGroups
      .slice(0, 3)
      .map((productGroup) => (
        <Slice key={productGroup.id} slice={productGroup} site={site} />
      ));
  }
}

export default ProductGroups;
