import Player from '@vimeo/player';
import React, { Component } from 'react';
import { v4 as uuid } from 'uuid';

class VideoDefault extends Component {
  constructor(props) {
    super(props);

    this.player = null;
    this.videoRef = null;
  }

  componentDidMount() {
    const { video } = this.props;
    const videoId = video.video?.url?.replace(/(\/videos\/)/g, '');

    if (videoId) {
      this.player = new Player(this.videoRef.id, {
        id: videoId,
        autoplay: true,
        byline: false,
        controls: false,
        dnt: true,
        loop: true,
        muted: true,
        portrait: false,
        quality: '720p',
        title: false,
        width: this.videoRef.scrollWidth,
      });
    }

    this.videoRef.play = () => this.player.play();
    this.videoRef.pause = () => this.player.pause();
  }

  render() {
    const { video } = this.props;

    return (
      <div
        className="video"
        id={`video--${uuid()}`}
        ref={(ref) => (this.videoRef = ref)}
      >
        <img src={video.video?.previewUrl} />
      </div>
    );
  }
}

export default VideoDefault;
