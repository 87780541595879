import lscache from 'lscache';
import { cloneDeep } from 'lodash';

const availableFilters = {
  inventory: { label: 'In Stock', type: 'boolean', activeFilters: [] },
  badge: { label: 'Badge', type: 'string', activeFilters: [] },
  species: { label: 'Species', type: 'string', activeFilters: [] },
  waterType: { label: 'Water Type', type: 'string', activeFilters: [] },
};

/**
 * Initial state
 */
const initialFilterServiceData = {
  filters: { ...availableFilters },
  searchTerm: '',
  sortKey: 'quantitySold',
};

/**
 * Action types
 */
const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
const SET_SORT_KEY = 'SORT_KEY';
const SET_FILTERS = 'SET_FILTERS';
const RESET_FILTER_SERVICE_DATA = 'RESET_FILTER_SERVICE_DATA';

/**
 * Action creators
 */
export const setSearchTerm = (payload) => ({
  type: SET_SEARCH_TERM,
  payload: payload,
});

export const setSortKey = (payload) => ({
  type: SET_SORT_KEY,
  payload: payload,
});

export const setFilters = (payload) => ({
  type: SET_FILTERS,
  payload: payload,
});

export const resetFilterServiceData = (payload) => ({
  type: RESET_FILTER_SERVICE_DATA,
  payload: payload,
});

/**
 * Reducer
 */
export default {
  filterService(
    state = lscache.get('filterServiceData') || initialFilterServiceData,
    { type, payload }
  ) {
    let newState = cloneDeep(state);

    switch (type) {
      case SET_SEARCH_TERM:
        newState.searchTerm = payload;
        break;
      case SET_SORT_KEY:
        newState.sortKey = payload;
        break;
      case SET_FILTERS:
        newState.filters[payload.key].activeFilters = payload.value;
        break;
      case RESET_FILTER_SERVICE_DATA:
        newState = { ...initialFilterServiceData };
        break;
      default:
        break;
    }

    lscache.set('filterServiceData', newState);

    return newState;
  },
};
