import React, { Component } from 'react';

class SignupForm extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { signupForm } = this.props;

    return <div className={`klaviyo-form-${signupForm.signupFormId}`}></div>;
  }
}

export default SignupForm;
