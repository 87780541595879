import React from 'react';

export const Search = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g stroke="none" strokeWidth="1">
      <path
        d="M14.9,13.6l9,9c0.2,0.2,0.2,0.5,0,0.7c-0.1,0.1-0.2,0.1-0.4,0.1s-0.3,0-0.4-0.1l-9-9c-1.5,1.3-3.5,2.1-5.6,2.1
        C3.8,16.5,0,12.7,0,8s3.8-8.5,8.5-8.5C13.2-0.5,17,3.3,17,8C17,10.2,16.2,12.1,14.9,13.6z M8.5,0.5C4.4,0.5,1,3.9,
        1,8s3.4,7.5,7.5,7.5S16,12.1,16,8S12.6,0.5,8.5,0.5"
      />
    </g>
  </svg>
);

export const AngleRight = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M5.6,0.6c-0.2,0.2-0.2,0.5,0,0.7L17.3,13L5.6,24.6c-0.2,0.2-0.2,0.5,0,0.7s0.5,0.2,0.7,0l12-12
      c0.1-0.1,0.1-0.2,0.1-0.4s0-0.3-0.1-0.4l-12-12C6.2,0.5,5.8,0.5,5.6,0.6z"
    />
  </svg>
);

export const AngleRightFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M13.1,12L8.8,7.7c-0.4-0.4-0.4-1,0-1.4c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.4,1,0,1.4l-5,5c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4L13.1,12z" />
  </svg>
);

export const AngleLeftFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M9.9,13l4.3,4.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-5-5c-0.4-0.4-0.4-1,0-1.4l5-5c0.4-0.4,1-0.4,1.4,0
		c0.4,0.4,0.4,1,0,1.4L9.9,13z"
    />
  </svg>
);

export const AngleUpFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlSpace="preserve"
  >
    <path
      d="M12,10.9l-4.3,4.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l5-5c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.4,1,0,1.4
	c-0.4,0.4-1,0.4-1.4,0L12,10.9z"
    />
  </svg>
);

export const AngleDownFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    fill="currentColor"
    xmlSpace="preserve"
  >
    <path
      d="M12,13.6l4.3-4.3c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-5,5c-0.4,0.4-1,0.4-1.4,0l-5-5
  	c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L12,13.6z"
    />
  </svg>
);

export const AngleLeft = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M17.6-0.4l-12,12c-0.2,0.2-0.2,0.5,0,0.7l12,12c0.2,0.2,0.5,0.2,0.7,0c0.1-0.1,0.1-0.2,0.1-0.4s0-0.3-0.1-0.4
	L6.7,12L18.4,0.4c0.1-0.1,0.1-0.2,0.1-0.4s0-0.3-0.1-0.4C18.2-0.5,17.8-0.5,17.6-0.4z"
    />
  </svg>
);

export const Copy = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 24"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </svg>
);

export const CrossFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M6.3,9.2C5.4,8.3,6.8,6.9,7.7,7.8l4,4c0.4,0.4,0.4,1,0,1.4l-4,4c-0.9,0.9-2.4-0.5-1.4-1.4l3.3-3.3L6.3,9.2z
	 M15.7,15.8c0.9,0.9-0.5,2.4-1.4,1.4l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.9-0.9,2.4,0.5,1.4,1.4l-3.3,3.3L15.7,15.8z"
    />
  </svg>
);

export const ArrowDown2 = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 8.5 12"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M4.5,11.15,8.07,7.57a.25.25,0,0,1,.36.36l-4,4a.27.27,0,0,1-.36,0l-4-4a.27.27,0,0,1,0-.36.27.27,0,0,1,.36,0L4,11.15V.25a.25.25,0,0,1,.5,0Z" />
  </svg>
);

export const ArrowUp2 = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 8.5 12"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M4,.85.43,4.43a.27.27,0,0,1-.36,0,.27.27,0,0,1,0-.36l4-4a.27.27,0,0,1,.36,0l4,4a.27.27,0,0,1,0,.36.27.27,0,0,1-.36,0L4.5.85v10.9a.25.25,0,0,1-.5,0Z" />
  </svg>
);

export const ArrowDown = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M24.4,8.6c-0.2-0.2-0.5-0.2-0.7,0L12,20.3L0.4,8.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l12,12
	c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l12-12C24.5,9.2,24.5,8.8,24.4,8.6z"
    />
  </svg>
);

export const ArrowLeft = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M23.5,10.5H1.7l7.1-7.1C9,3.2,9,2.8,8.9,2.6s-0.5-0.2-0.7,0l-8,8c0,0-0.1,0.1-0.1,0.2C0,10.9,0,11.1,0,11.2
	c0,0.1,0.1,0.1,0.1,0.2l8,8c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7l-7.1-7.1h21.8c0.3,0,0.5-0.2,0.5-0.5
	S23.8,10.5,23.5,10.5z"
    />
  </svg>
);

export const ArrowRight = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M24,11.2c0.1-0.1,0.1-0.3,0-0.4c0-0.1-0.1-0.1-0.1-0.2l-8-8c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l7.1,7.1H0.5
	C0.2,10.5,0,10.7,0,11s0.2,0.5,0.5,0.5h21.8l-7.1,7.1c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l8-8
	C23.9,11.3,23.9,11.3,24,11.2z"
    />
  </svg>
);

export const ArrowUp = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M24.4,17.6l-12-12c-0.2-0.2-0.5-0.2-0.7,0l-12,12c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1
	s0.3,0,0.4-0.1L12,6.7l11.6,11.6c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1C24.5,18.2,24.5,17.8,24.4,17.6z"
    />
  </svg>
);

export const ArrowUpFat = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M12,10.9l-4.3,4.3c-0.4,0.4-1,0.4-1.4,0c-0.4-0.4-0.4-1,0-1.4l5-5c0.4-0.4,1-0.4,1.4,0l5,5c0.4,0.4,0.4,1,0,1.4
	c-0.4,0.4-1,0.4-1.4,0L12,10.9z"
    />
  </svg>
);

export const Box = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M22.5,6.3c0-0.1,0-0.2-0.1-0.2l0-0.1l-0.1-0.1c-0.1-0.1-0.1-0.1-0.2-0.2L12-0.4c-0.3-0.2-0.7-0.2-1,0l-10,6
	L0.9,5.7C0.8,5.8,0.7,5.9,0.7,6L0.6,6.1c0,0.1-0.1,0.2-0.1,0.3l0,11.1c0,0.3,0.2,0.7,0.5,0.9l10,6l0.1,0.1c0.1,0.1,0.3,0.1,0.4,0.1
	c0.1,0,0.3,0,0.4-0.1L22,18.4c0.3-0.2,0.5-0.5,0.5-0.9v-11L22.5,6.3z M11.5,0.5l9.8,5.9l-9.8,6l-9.8-6L11.5,0.5z M1.5,17.5V7.4
	l9.5,5.9v9.9L1.5,17.5z M12,23.2v-9.9l9.5-5.9l0,10.1L12,23.2z"
    />
  </svg>
);

export const FilledBox = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path d="M12.8,1.1c-0.2-0.1-0.4-0.1-0.5,0L2.5,6.9l10,6l10-6L12.8,1.1z" />
      <path d="M2,18.5c0,0.2,0.1,0.3,0.2,0.4l9.8,5.9v-11l-10-6V18.5z" />
      <path d="M13,13.8v11l9.8-5.9c0.1-0.1,0.2-0.3,0.2-0.4V7.8L13,13.8z" />
    </g>
  </svg>
);

export const Check = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M9.5,20c-0.1,0-0.3,0-0.4-0.1l-8-8c-0.2-0.2-0.2-0.5,0-0.7s0.5-0.2,0.7,0l7.6,7.6L24.1,4.1
	c0.2-0.2,0.5-0.2,0.7,0s0.2,0.5,0,0.7l-15,15C9.8,20,9.6,20,9.5,20z"
    />
  </svg>
);

export const Clock = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M18.5,12H13V4.5C13,4.2,12.8,4,12.5,4S12,4.2,12,4.5v8c0,0.3,0.2,0.5,0.5,0.5h6c0.3,0,0.5-0.2,0.5-0.5
		C19,12.2,18.8,12,18.5,12z"
      />
      <path
        d="M12.5,0.5c-6.6,0-12,5.4-12,12s5.4,12,12,12c6.6,0,12-5.4,12-12S19.1,0.5,12.5,0.5z M12.5,23.5
		c-6.1,0-11-4.9-11-11s4.9-11,11-11s11,4.9,11,11S18.6,23.5,12.5,23.5z"
      />
    </g>
  </svg>
);

export const CheckCircle = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M18.6,9.1L11,16.8l-4.6-4.6c-0.2-0.2-0.5-0.2-0.7,0s-0.2,0.5,0,0.7l5,5C10.7,18,10.9,18,11,18s0.3,0,0.4-0.1
		l8-8c0.2-0.2,0.2-0.5,0-0.7C19.2,9,18.8,9,18.6,9.1z"
      />
      <path
        d="M12.5,1c-6.6,0-12,5.4-12,12s5.4,12,12,12s12-5.4,12-12S19.1,1,12.5,1z M12.5,24c-6.1,0-11-4.9-11-11
		s4.9-11,11-11s11,4.9,11,11S18.6,24,12.5,24z"
      />
    </g>
  </svg>
);

export const ExternalLink = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M14 4h-13v18h20v-11h1v12h-22v-20h14v1zm10 5h-1v-6.293l-11.646 11.647-.708-.708 11.647-11.646h-6.293v-1h8v8z" />
  </svg>
);

export const FilledDrop = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      className="st1"
      d="M12,2.5L6.2,13c0,0,0,0,0,0L6,13.3c-0.6,1.1-1,2.4-1,3.8c0,4.1,3.1,7.3,7,7.3c3.9,0,7-3.3,7-7.3
	c0-1.4-0.4-2.7-1-3.8l-0.1-0.3c0,0,0,0,0,0L12,2.5z"
    />
  </svg>
);

export const Facebook = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M21.5,0.5h-19C1.1,0.5,0,1.6,0,3v19c0,1.4,1.1,2.5,2.5,2.5h11c0.3,0,0.5-0.2,0.5-0.5v-9c0-0.3-0.2-0.5-0.5-0.5
	H11v-2h2.5c0.3,0,0.5-0.2,0.5-0.5V9c0-2.5,2-4.5,4.5-4.5H20v2h-1.5C17.1,6.5,16,7.6,16,9v3c0,0.3,0.2,0.5,0.5,0.5h3.3l-0.7,2h-2.6
	c-0.3,0-0.5,0.2-0.5,0.5v9c0,0.3,0.2,0.5,0.5,0.5h5c1.4,0,2.5-1.1,2.5-2.5V3C24,1.6,22.9,0.5,21.5,0.5z M23,22
	c0,0.8-0.7,1.5-1.5,1.5H17v-8h2.5c0.2,0,0.4-0.1,0.5-0.3l1-3c0.1-0.2,0-0.3-0.1-0.5c-0.1-0.1-0.2-0.2-0.4-0.2H17V9
	c0-0.8,0.7-1.5,1.5-1.5h2C20.8,7.5,21,7.3,21,7V4c0-0.3-0.2-0.5-0.5-0.5h-2C15.5,3.5,13,6,13,9v2.5h-2.5c-0.3,0-0.5,0.2-0.5,0.5v3
	c0,0.3,0.2,0.5,0.5,0.5H13v8H2.5C1.7,23.5,1,22.8,1,22V3c0-0.8,0.7-1.5,1.5-1.5h19C22.3,1.5,23,2.2,23,3V22z"
    />
  </svg>
);

export const Filter = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M23.5,8c-0.1-0.4-0.3-0.8-0.7-1l-1.5-0.9c0,0-0.1,0-0.1,0l2-2.9c0.4-0.6,0.5-1.4,0.1-2.1
		c-0.3-0.7-1-1.1-1.8-1.1h-19C1.8,0,1.1,0.4,0.7,1.1C0.4,1.7,0.4,2.5,0.9,3.1l7.6,11.2V23c0,0.7,0.4,1.4,1,1.7
		c0.3,0.2,0.6,0.3,1,0.3c0.4,0,0.7-0.1,1-0.3l3-1.8c0.6-0.4,1-1,1-1.7l0-6.9l2.3-3.3l1.7,1c0.2,0.1,0.5,0.2,0.8,0.2
		c0.5,0,0.9-0.2,1.2-0.6l1.8-2.4C23.5,8.8,23.6,8.4,23.5,8z M14.7,13.7c-0.1,0.2-0.2,0.4-0.2,0.6v6.9c0,0.3-0.2,0.7-0.5,0.9l-3,1.8
		c-0.3,0.2-0.7,0.2-1,0c-0.3-0.2-0.5-0.5-0.5-0.9v-8.7c0-0.2-0.1-0.4-0.2-0.6L1.7,2.6c-0.2-0.3-0.2-0.7-0.1-1C1.8,1.2,2.1,1,2.5,1
		h19c0.4,0,0.7,0.2,0.9,0.5c0.2,0.3,0.2,0.7-0.1,1L14.7,13.7z M22.4,8.6L20.6,11c-0.2,0.2-0.4,0.3-0.7,0.1l-1.6-1l2.3-3.3
		c0,0,0.1,0.1,0.1,0.1l1.5,0.9c0.1,0.1,0.2,0.2,0.2,0.3C22.6,8.3,22.5,8.5,22.4,8.6z"
      />
      <path
        d="M5.9,2.7C5.8,2.5,5.5,2.4,5.2,2.6C5,2.7,4.9,3,5.1,3.3l6,9c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1
		c0.2-0.2,0.3-0.5,0.1-0.7L5.9,2.7z"
      />
    </g>
  </svg>
);

export const Fish = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M23.9,10.6L23.2,10l0.6-0.6c0.2-0.2,0.2-0.5,0-0.7c-0.1-0.2-2.7-3.5-7-4.1c-0.4-0.8-2.1-4-5.4-4.1
		c-2.9,0-8.9,2.7-11.4,5.1C0,5.8,0,6,0,6.2s0.3,0.3,0.5,0.3c0.5,0,1.4,0.3,2.2,0.6C1.6,8.5,0,11.1,0,14c0,3.4,1.4,5.1,3.1,6.9
		c1,1,2.2,1.3,3.4,1.6c0.9,0.2,1.9,0.5,2.7,1c0.1,0.1,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3-0.1C10,23.2,10,23,9.9,22.8
		c0,0-1.1-1.9-0.1-2.9c1.4-1.4,1.2-4.8,1.1-4.9c0-0.2-0.1-0.4-0.3-0.4c-0.2-0.1-0.4,0-0.5,0.1c-1.4,1.3-3.2,2.8-3.7,2.9
		c-0.5,0-1.5-1-1.5-2c0-1,1.1-3,2.5-3c0.9,0,2.3,0.6,3.3,1c0.9,0.4,1.4,0.5,1.7,0.5c0,0,0,0,0,0c0.4,0.6,0.7,1.1,0.7,1.4
		c0,0.1,0,0.2-0.1,0.2C13,15.8,13,16,13,16.2s0.3,0.3,0.5,0.3c0.5,0,0.9-0.2,1.3-0.5c0.4-0.4,0.6-0.9,0.8-1.5h2c3.1,0,6.2-3,6.4-3.1
		c0.1-0.1,0.1-0.2,0.1-0.4C24,10.9,23.9,10.7,23.9,10.6z M15.7,4.5c-0.1,0-0.1,0-0.2,0c-0.5,0-1.1,0-1.7,0.1c-0.8-1.5-3-2.3-4.5-2.7
		c0.9-0.2,1.6-0.4,2.2-0.4C13.7,1.5,15.1,3.5,15.7,4.5z M8.9,5.8c0,0,0-0.1-0.1-0.1c-0.7-0.7-3-1.5-4.3-1.9c0.9-0.5,1.9-0.9,2.9-1.3
		c1.2,0.2,4.2,0.9,5.3,2.3C11.5,5,10.2,5.3,8.9,5.8z M3.4,4.5C3.4,4.5,3.4,4.5,3.4,4.5C4.8,4.9,7,5.6,7.9,6.2C7.1,6.5,6.3,7,5.5,7.4
		C4.9,7.1,3.1,6.1,1.6,5.7C2.1,5.3,2.7,4.9,3.4,4.5z M22.1,10.4l0.6,0.6c-0.9,0.8-3.1,2.5-5.3,2.5h-1.7C16,12.3,16,11.2,16,11
		c0-0.3-0.3-0.5-0.5-0.5c-0.3,0-0.5,0.2-0.5,0.5c0,0.8-0.1,3.1-0.8,4.1c-0.4-1.7-3-4.2-3.3-4.5c-0.2-0.2-0.5-0.2-0.7,0
		c-0.2,0.2-0.2,0.5,0,0.7c0.4,0.3,0.8,0.8,1.2,1.3c-0.1,0-0.2-0.1-0.2-0.1c-1.1-0.5-2.5-1-3.7-1c-2.1,0-3.5,2.6-3.5,4
		c0,1.5,1.4,3,2.5,3c0.7,0,2.3-1.3,3.5-2.4c0,1-0.2,2.4-0.9,3c-0.8,0.8-0.8,2-0.6,2.8c-0.6-0.2-1.2-0.4-1.8-0.5
		c-1.1-0.3-2.1-0.5-2.9-1.3C2.2,18.5,1,17.1,1,14c0-2.8,1.6-5.3,2.6-6.4c0.4,0.2,0.7,0.4,1,0.5C3.1,9.4,2,11,2,13
		c0,5.4,2.7,6.9,2.8,6.9C4.8,20,4.9,20,5,20c0.2,0,0.4-0.1,0.4-0.3c0.1-0.2,0-0.5-0.2-0.7C5.2,19,3,17.8,3,13
		c0-4.6,7.4-7.5,12.5-7.5C19.4,5.5,22,8,22.8,9l-0.7,0.7C22,9.8,22,10.2,22.1,10.4z"
      />
      <path
        d="M17.8,7.4C18,7.3,18.1,7,17.9,6.8c-0.1-0.2-0.4-0.3-0.7-0.2c-0.1,0-1.3,0.7-1.3,2.9s1.2,2.9,1.3,2.9
		c0.1,0,0.2,0.1,0.2,0.1c0.2,0,0.3-0.1,0.4-0.2c0.1-0.2,0.1-0.5-0.2-0.7c0,0-0.8-0.5-0.8-2.1C17,7.9,17.7,7.5,17.8,7.4z"
      />
      <path d="M20,8.5c-0.6,0-1,0.4-1,1s0.4,1,1,1s1-0.4,1-1S20.6,8.5,20,8.5z M20,9.5L20,9.5h0.5H20z" />
    </g>
  </svg>
);

export const Fishbrain = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 58.27 50.85"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M44.49,12.45a21.08,21.08,0,0,1,6.56,1.43c3.52,1.38,6.2,3.61,7,6.24s.76,8.17-13,14.55c1.25,1,4,3.52,2.14,6.33a1.3,1.3,0,0,
        1-1,.63c-1.21,0-2.28-2-3.48-4.33-.14-.27-.27-.54-.4-.76a.88.88,0,0,0-.23.18A33.37,33.37,0,0,1,28.74,43s0,.14,0,.18c-.18.58-1.43,
        3.35-2.14,3.35s-1-1-1.21-2c-.09-.32-.18-.81-.27-1-1,.09-2,.13-3,.13a27,27,0,0,1-7.18-.89C14.33,44.53,8.67,52.6,7,
        50.5c-1-1.25.22-5.89.4-7C6.3,43.05-.3,39.26,0,37.25s12.23.67,13.21,1.12C20.44,35.2,25,26.77,26.56,23.65l17.93-11.2Zm-1-1.7L41.19,
        0,35.48,8,28,5.67l-.58,7.45L17.45,15l8.3,6.82,17.76-11Zm3.66,12A2.72,2.72,0,1,0,44.45,20a2.73,2.73,0,0,0,2.72,2.72Z"
      />
    </g>
  </svg>
);

export const Ghost = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M11,0C4.9,0,0,4.9,0,11v11.5C0,23.3,0.7,24,1.5,24h1C3.3,24,4,23.3,4,22.5C4,22.2,4.2,22,4.5,22h1
		C5.8,22,6,22.2,6,22.5C6,23.3,6.7,24,7.5,24h1c0.8,0,1.5-0.7,1.5-1.5c0-0.3,0.2-0.5,0.5-0.5h1c0.3,0,0.5,0.2,0.5,0.5
		c0,0.8,0.7,1.5,1.5,1.5h1c0.8,0,1.5-0.7,1.5-1.5c0-0.3,0.2-0.5,0.5-0.5h1c0.3,0,0.5,0.2,0.5,0.5c0,0.8,0.7,1.5,1.5,1.5h1
		c0.8,0,1.5-0.7,1.5-1.5V11C22,4.9,17.1,0,11,0z M21,22.5c0,0.3-0.2,0.5-0.5,0.5h-1c-0.3,0-0.5-0.2-0.5-0.5c0-0.8-0.7-1.5-1.5-1.5
		h-1c-0.8,0-1.5,0.7-1.5,1.5c0,0.3-0.2,0.5-0.5,0.5h-1c-0.3,0-0.5-0.2-0.5-0.5c0-0.8-0.7-1.5-1.5-1.5h-1C9.7,21,9,21.7,9,22.5
		C9,22.8,8.8,23,8.5,23h-1C7.2,23,7,22.8,7,22.5C7,21.7,6.3,21,5.5,21h-1C3.7,21,3,21.7,3,22.5C3,22.8,2.8,23,2.5,23h-1
		C1.2,23,1,22.8,1,22.5V11C1,5.5,5.5,1,11,1s10,4.5,10,10V22.5z"
      />
      <path
        d="M6.5,6C5.1,6,4,7.1,4,8.5v4C4,13.9,5.1,15,6.5,15c0.9,0,1.7-0.5,2.2-1.3c0.1-0.1,0.1-0.3,0-0.5S8.5,13,8.3,13
		C7.6,12.9,7,12.2,7,11.5C7,10.7,7.7,10,8.5,10C8.8,10,9,9.8,9,9.5v-1C9,7.1,7.9,6,6.5,6z M8,9c-1.1,0.2-2,1.2-2,2.5
		c0,1,0.5,1.8,1.4,2.2C7.1,13.9,6.8,14,6.5,14C5.7,14,5,13.3,5,12.5v-4C5,7.7,5.7,7,6.5,7S8,7.7,8,8.5V9z"
      />
      <path
        d="M15.5,6C14.1,6,13,7.1,13,8.5v4c0,1.4,1.1,2.5,2.5,2.5c0.9,0,1.7-0.5,2.2-1.3c0.1-0.1,0.1-0.3,0-0.5
		S17.5,13,17.3,13c-0.7-0.1-1.3-0.7-1.3-1.5c0-0.8,0.7-1.5,1.5-1.5c0.3,0,0.5-0.2,0.5-0.5v-1C18,7.1,16.9,6,15.5,6z M17,9
		c-1.1,0.2-2,1.2-2,2.5c0,1,0.5,1.8,1.4,2.2c-0.2,0.2-0.6,0.3-0.9,0.3c-0.8,0-1.5-0.7-1.5-1.5v-4C14,7.7,14.7,7,15.5,7
		S17,7.7,17,8.5V9z"
      />
    </g>
  </svg>
);

export const Home = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 64 64"
    enableBackground="new 0 0 64 64"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path d="M53,59H36V45h-8v14H11V36H0.2L32,4.2L63.8,36H53V59z M40,55h9V32h5.2L32,9.8L9.8,32H15v23h9V41h16V55z" />
      </g>
    </g>
  </svg>
);

export const Instagram = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M20.5,1h-17C1.6,1,0,2.6,0,4.5v17C0,23.4,1.6,25,3.5,25h17c1.9,0,3.5-1.6,3.5-3.5v-17C24,2.6,22.4,1,20.5,1z
		 M2,2.5v3C2,5.8,2.2,6,2.5,6S3,5.8,3,5.5V2.1C3.2,2,3.3,2,3.5,2H4v3.5C4,5.8,4.2,6,4.5,6S5,5.8,5,5.5V2h1v3.5C6,5.8,6.2,6,6.5,6
		S7,5.8,7,5.5V2h13.5C21.9,2,23,3.1,23,4.5V8h-7.5c-0.1,0-0.1,0-0.1,0c-1-0.6-2.1-1-3.4-1S9.6,7.4,8.6,8c0,0-0.1,0-0.1,0H1V4.5
		C1,3.7,1.4,3,2,2.5z M12,8c2.8,0,5,2.2,5,5s-2.2,5-5,5s-5-2.2-5-5S9.2,8,12,8z M20.5,24h-17C2.1,24,1,22.9,1,21.5V9h6.5
		c-1,1.1-1.5,2.5-1.5,4c0,3.3,2.7,6,6,6s6-2.7,6-6c0-1.5-0.6-2.9-1.5-4H23v12.5C23,22.9,21.9,24,20.5,24z"
      />
      <path
        d="M17.5,7h3C20.8,7,21,6.8,21,6.5v-3C21,3.2,20.8,3,20.5,3h-3C17.2,3,17,3.2,17,3.5v3C17,6.8,17.2,7,17.5,7z
		 M18,4h2v2h-2V4z"
      />
      <path
        d="M12,16c1.7,0,3-1.3,3-3s-1.3-3-3-3s-3,1.3-3,3S10.3,16,12,16z M12,11c1.1,0,2,0.9,2,2s-0.9,2-2,2s-2-0.9-2-2
		S10.9,11,12,11z"
      />
    </g>
  </svg>
);

export const ListView = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 25 24.889"
    xmlSpace="preserve"
    fill="currentcolor"
    stroke="currentcolor"
  >
    <rect width="2.022" height="2.022" rx="1" ry="1" x="1.072" y="4.659" />
    <rect x="6.777" y="4.768" width="17.117" height="1.913" rx="0.5" ry="0.5" />
    <rect y="11.715" width="2.022" height="2.022" rx="1" ry="1" x="1.072" />
    <rect
      x="6.777"
      y="11.824"
      width="17.117"
      height="1.913"
      rx="0.5"
      ry="0.5"
    />
    <rect y="18.611" width="2.022" height="2.022" rx="1" ry="1" x="1.072" />
    <rect x="6.777" y="18.72" width="17.117" height="1.913" rx="0.5" ry="0.5" />
  </svg>
);

export const Location = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M11.5,3C8.5,3,6,5.5,6,8.5S8.5,14,11.5,14c3,0,5.5-2.5,5.5-5.5S14.5,3,11.5,3z M11.5,13C9,13,7,11,7,8.5
		S9,4,11.5,4S16,6,16,8.5S14,13,11.5,13z"
      />
      <path
        d="M11.5,0C6.8,0,3,3.8,3,8.5c0,8.2,7.8,15.1,8.2,15.4c0.1,0.1,0.2,0.1,0.3,0.1s0.2,0,0.3-0.1
		c0.3-0.3,8.2-7.2,8.2-15.4C20,3.8,16.2,0,11.5,0z M11.5,22.8C10,21.4,4,15.3,4,8.5C4,4.4,7.4,1,11.5,1S19,4.4,19,8.5
		C19,15.3,13,21.4,11.5,22.8z"
      />
    </g>
  </svg>
);

export const LocationFilled = (
  <svg
    width="10px"
    height="14px"
    viewBox="0 0 10 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="store-locator"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="product-locally"
        transform="translate(-640.000000, -201.000000)"
        fill="currentColor"
        fillRule="nonzero"
      >
        <path
          d="M645,215 C645,215 640,208.999505 640,206.153375 C640,203.307244 642.238576,201 645,201 C647.761424,201 650,203.307244 650,206.153375 C650,208.999505 645,215 645,215 Z M645,208 C646.104569,208 647,207.104569 647,206 C647,204.895431 646.104569,204 645,204 C643.895431,204 643,204.895431 643,206 C643,207.104569 643.895431,208 645,208 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);

export const Close = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M13.2,12.5l8.1-8.1c0.2-0.2,0.2-0.5,0-0.7s-0.5-0.2-0.7,0l-8.1,8.1L4.4,3.6c-0.2-0.2-0.5-0.2-0.7,0
	s-0.2,0.5,0,0.7l8.1,8.1l-8.1,8.1c-0.2,0.2-0.2,0.5,0,0.7c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1l8.1-8.1l8.1,8.1
	c0.1,0.1,0.2,0.1,0.4,0.1s0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7L13.2,12.5z"
    />
  </svg>
);

export const Minus = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M11.5,0.5C5.2,0.5,0,5.7,0,12s5.2,11.5,11.5,11.5S23,18.3,23,12S17.8,0.5,11.5,0.5z M11.5,22.5
		C5.7,22.5,1,17.8,1,12S5.7,1.5,11.5,1.5S22,6.2,22,12S17.3,22.5,11.5,22.5z"
      />
      <path d="M17.5,11.5h-12C5.2,11.5,5,11.7,5,12s0.2,0.5,0.5,0.5h12c0.3,0,0.5-0.2,0.5-0.5S17.8,11.5,17.5,11.5z" />
    </g>
  </svg>
);

export const PaperPlane = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M24,0.9C24,0.9,24,0.9,24,0.9c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.2-0.1c0,0,0,0-0.1,0
	c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0l-23,11C0.1,11.6,0,11.8,0,12c0,0.2,0.1,0.4,0.3,0.4l8.8,2.9l2.9,8.8c0.1,0.2,0.2,0.3,0.4,0.3
	c0,0,0,0,0,0c0.2,0,0.4-0.1,0.5-0.3l11-23c0,0,0,0,0,0C24,1.1,24,1,24,0.9z M21.1,2.7L9.4,14.4l-7.5-2.5L21.1,2.7z M12.6,22.7
	l-2.5-7.5L21.8,3.4L12.6,22.7z"
    />
  </svg>
);

export const Plus = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M12.5,1.5C6.2,1.5,1,6.7,1,13s5.2,11.5,11.5,11.5S24,19.3,24,13S18.8,1.5,12.5,1.5z M12.5,23.5
		C6.7,23.5,2,18.8,2,13S6.7,2.5,12.5,2.5S23,7.2,23,13S18.3,23.5,12.5,23.5z"
      />
      <path
        d="M18.5,12.5H13V7c0-0.3-0.2-0.5-0.5-0.5S12,6.7,12,7v5.5H6.5C6.2,12.5,6,12.7,6,13s0.2,0.5,0.5,0.5H12V19
		c0,0.3,0.2,0.5,0.5,0.5S13,19.3,13,19v-5.5h5.5c0.3,0,0.5-0.2,0.5-0.5S18.8,12.5,18.5,12.5z"
      />
    </g>
  </svg>
);

export const ShoppingCartItem = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M17.5,19.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S20,23.4,20,22S18.9,19.5,17.5,19.5z M17.5,23.5
		c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S19,21.2,19,22S18.3,23.5,17.5,23.5z"
      />
      <path
        d="M7.5,19.5C6.1,19.5,5,20.6,5,22s1.1,2.5,2.5,2.5S10,23.4,10,22S8.9,19.5,7.5,19.5z M7.5,23.5
		C6.7,23.5,6,22.8,6,22s0.7-1.5,1.5-1.5S9,21.2,9,22S8.3,23.5,7.5,23.5z"
      />
      <path
        d="M21.1,11.5c-0.3-0.1-0.5,0.1-0.6,0.4l-0.4,1.7L5.5,15.5c0,0,0,0,0,0l-1.8-9h6.8C10.8,6.5,11,6.3,11,6
		s-0.2-0.5-0.5-0.5h-7L3,2.9c0-0.2-0.3-0.4-0.5-0.4h-3C-0.8,2.5-1,2.7-1,3s0.2,0.5,0.5,0.5h2.6l2.4,12.2c0.3,1.6,1.8,2.8,3.4,2.8
		h11.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H8c-0.8,0-1.6-0.4-2-1.1l14.6-2c0.2,0,0.4-0.2,0.4-0.4l0.5-2
		C21.6,11.9,21.4,11.6,21.1,11.5z"
      />
      <path
        d="M17.5,11.5c3,0,5.5-2.5,5.5-5.5s-2.5-5.5-5.5-5.5S12,3,12,6S14.5,11.5,17.5,11.5z M17.5,1.5
		C20,1.5,22,3.5,22,6s-2,4.5-4.5,4.5S13,8.5,13,6S15,1.5,17.5,1.5z"
      />
    </g>
  </svg>
);

export const ShoppingCart = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M17.5,18.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5S20,22.4,20,21S18.9,18.5,17.5,18.5z M17.5,22.5
		c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5S19,20.2,19,21S18.3,22.5,17.5,22.5z"
      />
      <path
        d="M7.5,18.5C6.1,18.5,5,19.6,5,21s1.1,2.5,2.5,2.5S10,22.4,10,21S8.9,18.5,7.5,18.5z M7.5,22.5
		C6.7,22.5,6,21.8,6,21s0.7-1.5,1.5-1.5S9,20.2,9,21S8.3,22.5,7.5,22.5z"
      />
      <path
        d="M22.9,4.7c-0.1-0.1-0.2-0.2-0.4-0.2h-19L3,1.9c0-0.2-0.3-0.4-0.5-0.4h-3C-0.8,1.5-1,1.7-1,2s0.2,0.5,0.5,0.5
		h2.6l2.4,12.2c0.3,1.6,1.8,2.8,3.4,2.8h11.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5H8c-0.8,0-1.6-0.4-2-1.1l14.6-2
		c0.2,0,0.4-0.2,0.4-0.4l2-8C23,5,23,4.8,22.9,4.7z M20.1,12.5L5.5,14.5c0,0,0,0,0,0l-1.8-9h18.1L20.1,12.5z"
      />
    </g>
  </svg>
);

export const Spool = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 235 264.31"
    xmlSpace="preserve"
    style={{
      fill: 'none',
      stroke: 'white',
      strokeWidth: '10px',
      width: '48px',
    }}
  >
    <circle cx="117.5" cy="117.5" r="112.5" />
    <circle cx="117.5" cy="117.5" r="32.78" />
    <line x1="5" y1="108.31" x2="5" y2="264.31">
      <animate
        attributeName="y2"
        from="108.31"
        to="108.31"
        begin="0s"
        dur="3s"
        values="
          108.31;
          264.31;
          264.31;
          108.31"
        keySplines="
          0.1 0.8 0.2 1;
          0.1 0.8 0.2 1;
          0.0 0.0 1 1"
        keyTimes="
          0;
          0.33;
          0.5;
          1"
        calcMode="spline"
        repeatCount="indefinite"
      ></animate>
    </line>
  </svg>
);

export const Trash = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M18.5,7h-15C3.2,7,3,7.2,3,7.5v14C3,22.9,4.1,24,5.5,24h11c1.4,0,2.5-1.1,2.5-2.5v-14C19,7.2,18.8,7,18.5,7z
		 M18,21.5c0,0.8-0.7,1.5-1.5,1.5h-11C4.7,23,4,22.3,4,21.5V8h14V21.5z"
      />
      <path
        d="M19.5,3H19c-0.2-1.1-1.2-2-2.5-2h-11C4.3,1,3.3,1.9,3,3H2.5C2.2,3,2,3.2,2,3.5v2C2,5.8,2.2,6,2.5,6h17
		C19.8,6,20,5.8,20,5.5v-2C20,3.2,19.8,3,19.5,3z M19,5H3V4h0.5C3.8,4,4,3.8,4,3.5C4,2.7,4.7,2,5.5,2h11C17.3,2,18,2.7,18,3.5
		C18,3.8,18.2,4,18.5,4H19V5z"
      />
      <path d="M6.5,21C6.8,21,7,20.8,7,20.5v-10C7,10.2,6.8,10,6.5,10S6,10.2,6,10.5v10C6,20.8,6.2,21,6.5,21z" />
      <path d="M11,21c0.3,0,0.5-0.2,0.5-0.5v-10c0-0.3-0.2-0.5-0.5-0.5s-0.5,0.2-0.5,0.5v10C10.5,20.8,10.7,21,11,21z" />
      <path d="M15.5,21c0.3,0,0.5-0.2,0.5-0.5v-10c0-0.3-0.2-0.5-0.5-0.5S15,10.2,15,10.5v10C15,20.8,15.2,21,15.5,21z" />
    </g>
  </svg>
);

export const Youtube = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 24 25"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        d="M19.8,11.5L14.5,11c-2.3-0.2-4.7-0.2-7,0l-5.3,0.5C0.4,11.6-1,13.1-1,14.9v4.7c0,1.8,1.4,3.3,3.2,3.5l5.3,0.5
		c1.2,0.1,2.3,0.2,3.5,0.2s2.3-0.1,3.5-0.2l5.3-0.5c1.8-0.2,3.2-1.7,3.2-3.5v-4.7C23,13.1,21.6,11.6,19.8,11.5z M22,19.7
		c0,1.3-1,2.4-2.3,2.5l-5.3,0.5c-2.3,0.2-4.6,0.2-6.8,0l-5.3-0.5C1,22.1,0,21,0,19.7v-4.7c0-1.3,1-2.4,2.3-2.5L7.6,12
		c1.1-0.1,2.3-0.1,3.4-0.1c1.1,0,2.3,0,3.4,0.1l5.3,0.5c1.3,0.1,2.3,1.2,2.3,2.5V19.7z"
      />
      <path
        d="M5.5,13.9h-4c-0.3,0-0.5,0.2-0.5,0.5s0.2,0.5,0.5,0.5H3v5.5c0,0.3,0.2,0.5,0.5,0.5S4,20.7,4,20.4v-5.5h1.5
		c0.3,0,0.5-0.2,0.5-0.5S5.8,13.9,5.5,13.9z"
      />
      <path
        d="M3,5.6v3.8c0,0.3,0.2,0.5,0.5,0.5S4,9.7,4,9.4V5.6l2.9-3.9C7.1,1.5,7,1.2,6.8,1C6.6,0.9,6.3,0.9,6.1,1.1
		L3.5,4.6L0.9,1.1C0.7,0.9,0.4,0.9,0.2,1C0,1.2-0.1,1.5,0.1,1.7L3,5.6z"
      />
      <path
        d="M6,5.9v1c0,1.7,1.3,3,3,3s3-1.3,3-3v-1c0-1.7-1.3-3-3-3S6,4.3,6,5.9z M7,5.9c0-1.1,0.9-2,2-2s2,0.9,2,2v1
		c0,1.1-0.9,2-2,2s-2-0.9-2-2V5.9z"
      />
      <path
        d="M16,9.9c1,0,1.9-0.5,2.4-1.2c0.3,0.7,1,1.2,2.1,1.2c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5
		c-0.9,0-1.5-0.4-1.5-2.5v-3c0-0.3-0.2-0.5-0.5-0.5S18,3.2,18,3.4v3.5c0,1.1-0.9,2-2,2s-2-0.9-2-2V3.4c0-0.3-0.2-0.5-0.5-0.5
		S13,3.2,13,3.4v3.5C13,8.6,14.3,9.9,16,9.9z"
      />
      <path
        d="M9.5,15.9c-0.3,0-0.5,0.2-0.5,0.5v2.5c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2.5c0-0.3-0.2-0.5-0.5-0.5S6,16.2,6,16.4
		v2.5c0,1.1,0.9,2,2,2s2-0.9,2-2v-2.5C10,16.2,9.8,15.9,9.5,15.9z"
      />
      <path
        d="M19,15.9c-1.1,0-2,0.9-2,2v1c0,1.1,0.9,2,2,2h1c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5h-1c-0.6,0-1-0.4-1-1
		h2.5c0.3,0,0.5-0.2,0.5-0.5v-0.5C21,16.8,20.1,15.9,19,15.9z M18,17.9c0-0.6,0.4-1,1-1s1,0.4,1,1H18z"
      />
      <path
        d="M13.5,15.9c-0.6,0-1.1,0.2-1.5,0.5v-2c0-0.3-0.2-0.5-0.5-0.5S11,14.2,11,14.4v6c0,0.3,0.2,0.5,0.5,0.5
		s0.5-0.2,0.5-0.5v0c0.4,0.3,0.9,0.5,1.5,0.5c1.4,0,2.5-1.1,2.5-2.5S14.9,15.9,13.5,15.9z M13.5,19.9c-0.8,0-1.5-0.7-1.5-1.5
		s0.7-1.5,1.5-1.5s1.5,0.7,1.5,1.5S14.3,19.9,13.5,19.9z"
      />
    </g>
  </svg>
);

export const Warning = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 28 28"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <path
        className="cls-1"
        d="M533.5,282.48l-14,28h28Zm0,2.23,12.38,24.77H521.12Z"
        transform="translate(-519.5 -282.48)"
      />
      <path
        className="cls-1"
        d="M533.5,306.16a1.14,1.14,0,1,0,1.14,1.14A1.14,1.14,0,0,0,533.5,306.16Z"
        transform="translate(-519.5 -282.48)"
      />
      <path
        className="cls-1"
        d="M533.5,304.61a.5.5,0,0,0,.5-.5V291.39a.5.5,0,0,0-1,0v12.72A.5.5,0,0,0,533.5,304.61Z"
        transform="translate(-519.5 -282.48)"
      />
    </g>
  </svg>
);

export const NoResult = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 16"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path
      d="M9.16,5.59h0A3.52,3.52,0,0,1,7.3,4L7,2.36a.34.34,0,0,0-.32-.27.35.35,0,0,0-.35.23,2.58,2.58,0,0,0,
        .4,2h0A4.2,4.2,0,0,0,8.93,6.24h0a2.13,2.13,0,0,1,1.39,1,1.33,1.33,0,0,1-.14,1.11.35.35,0,0,0,.15.47.29.29,0,0,0,
        .16,0,.35.35,0,0,0,.31-.19A2,2,0,0,0,11,7,2.68,2.68,0,0,0,9.16,5.59ZM6.76,12.9c-1-.47-2-1.27-1.91-1.65a.36.36,0,0,
        0-.21-.45A.35.35,0,0,0,4.2,11C3.76,12.2,6,13.32,6.46,13.53a.42.42,0,0,0,.15,0,.35.35,0,0,0,.15-.67Zm6.64,0L3.09,2.6A7.3,
        7.3,0,0,1,13.4,12.91ZM8,15.3a7.62,7.62,0,0,1-1.18-.1c.9-1,2.69-3.26,2.45-4.24a.7.7,0,0,0-.34-.43,4.46,4.46,0,0,0,.66-.44L12.9,13.4A7.23,
        7.23,0,0,1,8,15.3ZM2,12.18c.24-.49,1.37-2.26,5.48-2.43a.84.84,0,0,0,0,.6c.13.31.46.54,1.06.76.19.35-1,2.21-2.55,3.92A7.31,7.31,0,0,1,2,12.18ZM.7,
        8A7.23,7.23,0,0,1,2.6,3.1L7.52,8,9.1,9.59a3.43,3.43,0,0,1-.87.52h0l0,0c0-.07,0-.22.1-.38l.07-.18a.36.36,0,0,0,0-.32A.38.38,0,0,0,8,9H8c-4.21,0-5.82,
        1.59-6.4,2.48A7.36,7.36,0,0,1,.7,8ZM8,0a8,8,0,1,0,8,8A8,8,0,0,0,8,0ZM5.91,11.13a.34.34,0,0,0,.35-.35.33.33,0,0,0-.1-.24.37.37,0,0,0-.5,0,
        .34.34,0,0,0-.09.24.35.35,0,0,0,.1.25A.31.31,0,0,0,5.91,11.13Z"
      transform="translate(0 0)"
    />
  </svg>
);

export const Question = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 122.88 122.88"
    xmlSpace="preserve"
  >
    <g>
      <path d="M109.72,61.44v-0.01h0.02c0-13.31-5.42-25.38-14.17-34.13c-8.75-8.74-20.81-14.16-34.1-14.16v0.02h-0.03h-0.01v-0.02 c-13.3,0-25.38,5.42-34.13,14.17c-8.74,8.75-14.16,20.81-14.16,34.11h0.02v0.03v0.01h-0.02c0,8.88,2.41,17.22,6.59,24.36 c8.38,14.31,23.92,23.92,41.68,23.93v-0.02h0.03h0.01v0.02c13.31,0,25.38-5.42,34.13-14.17c1.44-1.44,2.78-2.95,4-4.52 C105.93,82.88,109.72,72.6,109.72,61.44L109.72,61.44z M68.38,73.86H51.86v-1.65c0-2.8,0.3-5.09,0.94-6.85 c0.64-1.77,1.59-3.35,2.85-4.81c1.27-1.45,4.11-4,8.51-7.66c2.34-1.92,3.52-3.67,3.52-5.25c0-1.61-0.48-2.84-1.41-3.73 c-0.94-0.88-2.37-1.33-4.3-1.33c-2.07,0-3.76,0.69-5.1,2.04c-1.35,1.36-2.21,3.75-2.58,7.14l-16.89-2.1 c0.58-6.2,2.84-11.2,6.76-14.98C48.1,30.89,54.12,29,62.24,29c6.32,0,11.43,1.33,15.32,3.95c5.29,3.57,7.92,8.33,7.92,14.26 c0,2.48-0.69,4.86-2.04,7.15c-1.36,2.3-4.15,5.09-8.36,8.39c-2.92,2.34-4.77,4.19-5.54,5.6C68.77,69.76,68.38,71.6,68.38,73.86 L68.38,73.86z M51.27,78.26h17.72v15.61H51.27V78.26L51.27,78.26z M122.85,61.43h0.03v0.01c0,14.19-4.82,27.27-12.92,37.68 c-1.59,2.04-3.29,3.97-5.08,5.76c-11.1,11.1-26.45,17.97-43.43,17.98v0.03h-0.01h-0.03v-0.03c-22.59-0.01-42.34-12.22-52.99-30.4 c-5.33-9.1-8.39-19.7-8.39-31H0v-0.01v-0.03h0.03C0.03,44.44,6.9,29.09,18,18C29.09,6.9,44.45,0.03,61.43,0.03V0h0.01h0.03v0.03 c16.97,0.01,32.32,6.87,43.41,17.97C115.98,29.09,122.85,44.45,122.85,61.43L122.85,61.43z"></path>
    </g>
  </svg>
);

export const Twitter = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    fill="currentcolor"
    xmlSpace="preserve"
    viewBox="0 0 511.271 511.271"
  >
    <path d="M508.342,94.243c-2.603-2.603-6.942-3.471-10.414-2.603l-17.356,6.075c10.414-12.149,17.356-25.166,21.695-37.315    c1.736-4.339,0.868-7.81-1.736-10.414c-2.603-2.603-6.942-3.471-10.414-1.736c-24.298,10.414-45.125,19.092-62.481,24.298    c0,0.868-0.868,0-1.736,0c-13.885-7.81-47.729-25.166-72.027-25.166c-61.614,0.868-111.078,52.936-111.078,116.285v3.471    c-90.251-17.356-139.715-43.39-193.519-99.797L40.6,58.663l-5.207,10.414c-29.505,56.407-8.678,107.607,25.166,142.319    c-15.62-2.603-26.034-7.81-35.58-15.62c-3.471-2.603-7.81-3.471-12.149-0.868c-3.471,1.736-5.207,6.942-4.339,11.281    c12.149,40.786,42.522,73.763,75.498,93.722c-15.62,0-28.637-1.736-41.654-10.414c-3.471-1.736-8.678-1.736-12.149,0.868    s-5.207,6.942-3.471,11.281c15.62,44.258,45.993,67.688,94.59,73.763c-25.166,14.753-58.142,26.902-109.342,27.77    c-5.207,0-9.546,3.471-11.281,7.81c-1.736,5.207,0,9.546,3.471,13.017c31.241,25.166,100.664,39.919,186.576,39.919    c152.732,0,277.695-136.244,277.695-303.729v-2.603c19.092-9.546,34.712-27.77,42.522-52.936    C511.813,101.185,510.945,96.846,508.342,94.243z M456.274,143.707l-5.207,1.736v14.753    c0,157.939-117.153,286.373-260.339,286.373c-78.97,0-131.905-13.017-160.542-26.902c59.878-4.339,94.59-23.431,121.492-44.258    l21.695-15.62h-26.034c-49.464,0-79.837-13.885-97.193-46.861c15.62,5.207,32.108,5.207,50.332,4.339    c6.942-0.868,13.885-0.868,20.827-0.868l2.603-17.356c-32.976-9.546-72.027-39.051-91.119-78.969    c17.356,7.81,36.447,9.546,53.803,9.546h26.902L91.8,213.999c-18.224-13.017-72.027-59.01-45.993-124.963    c55.539,54.671,108.475,79.837,203.932,97.193l10.414,1.736v-24.298c0-53.803,41.654-98.061,93.722-98.929    c19.959-0.868,52.936,17.356,62.481,22.563c5.207,2.603,10.414,3.471,15.62,1.736c13.017-4.339,28.637-10.414,45.993-17.356    c-7.81,13.017-18.224,25.166-32.108,36.448c-3.471,2.603-4.339,7.81-2.603,12.149c1.736,4.339,6.942,6.075,11.281,4.339    l33.844-11.281C482.308,124.616,472.762,137.633,456.274,143.707z" />
  </svg>
);

export const User = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 31 31"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <path d="M15.36,30.72 C6.8900000000000015,30.72 -4.440892098500626e-16,23.83 -4.440892098500626e-16,15.36 S6.8900000000000015,-4.440892098500626e-16 15.36,-4.440892098500626e-16 S30.72,6.8900000000000015 30.72,15.36 S23.83,30.72 15.36,30.72 zM5.709,26.36 c2.579,2.266 5.957,3.64 9.651,3.64 c3.693,0 7.072,-1.374 9.65,-3.64 h-0.01000000000000001 c0,-4.341 -2.941,-8.161 -7.1530000000000005,-9.29 c-0.14400000000000004,-0.03800000000000001 -0.24800000000000016,-0.16000000000000023 -0.265,-0.30700000000000033 c-0.01800000000000002,-0.14600000000000016 0.05800000000000006,-0.2890000000000003 0.18800000000000017,-0.3580000000000003 c1.6780000000000008,-0.8970000000000008 2.72,-2.6350000000000002 2.72,-4.534 c0,-2.84 -2.306,-5.151 -5.14,-5.151 s-5.141,2.311 -5.141,5.151 c0,1.899 1.042,3.637 2.72,4.534 c0.13,0.06900000000000012 0.20500000000000004,0.21200000000000016 0.18800000000000017,0.3580000000000003 s-0.12200000000000008,0.269 -0.264,0.30700000000000033 C8.652000000000001,18.195 5.72,22.015 5.72,26.36 H5.709 zM18.794999999999998,16.61 c3.9130000000000003,1.377 6.646,4.973 6.905,9.104 c2.6550000000000002,-2.6510000000000002 4.3,-6.314 4.3,-10.354 c0,-8.073 -6.567,-14.64 -14.64,-14.64 C7.286999999999999,0.7200000000000001 0.72,7.286999999999999 0.72,15.36 c0,4.04 1.645,7.703 4.3,10.354 c0.258,-4.135 2.982,-7.729 6.883,-9.104 c-1.506,-1.094 -2.415,-2.846 -2.415,-4.739 c0,-3.237 2.629,-5.871 5.86,-5.871 c3.232,0 5.861,2.633 5.861,5.871 C21.21,13.764 20.301,15.515999999999998 18.794999999999998,16.61 z" />
  </svg>
);

export const Video1 = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26.53 15.07"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M25.34,0H1.2A1.2,1.2,0,0,0,0,1.2V13.87a1.2,1.2,0,0,0,1.2,1.2H25.34a1.2,1.2,0,0,0,1.19-1.2V1.2A1.2,1.2,0,0,0,25.34,0ZM9.78,1.07H12.7v2H9.78Zm5.34,6.47-3.7,2.13V5.4Zm-11,6.57H1.21v-2H4.13Zm0-11H1.21v-2H4.13Zm4.29,11H5.5v-2H8.42Zm0-11H5.5v-2H8.42Zm4.28,11H9.78v-2H12.7Zm4.14,0H13.92v-2h2.92Zm0-11H13.92v-2h2.92Zm4.14,11H18.07v-2H21Zm0-11H18.07v-2H21Zm4.15,11H22.21v-2h2.92Zm0-11H22.21v-2h2.92Z"
        />
      </g>
    </g>
  </svg>
);

export const Video = (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 1000 1000"
    enableBackground="new 0 0 1000 1000"
    xmlSpace="preserve"
    fill="currentColor"
  >
    <g>
      <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
        <path d="M4530.1,5007.9C3377.4,4889,2339.7,4398,1530.4,3588.6C753.6,2811.8,285.6,1858.6,124.4,723.1c-32.6-226.3-32.6-982,0-1208.3c163-1137.4,629.1-2086.8,1405.9-2865.5c778.7-776.8,1728.1-1242.9,2865.5-1405.9c226.3-32.6,982-32.6,1208.3,0c581.2,82.5,1076,234,1561.3,475.6c533.2,264.7,914.9,540.9,1319.6,949.4C9259.9-2549,9714.4-1616.8,9875.5-485.2c32.6,226.3,32.6,982,0,1208.3c-163,1137.4-629.1,2086.8-1405.9,2865.5c-759.5,759.5-1687.8,1223.7-2779.2,1392.5C5448.8,5017.5,4775.6,5034.8,4530.1,5007.9z M5445,1145.1C6070.2,705.9,6605.4,322.3,6636,295.4c107.4-101.7,97.8-291.5-19.2-377.9c-28.8-21.1-558.1-395.1-1175.7-828.6c-694.3-489.1-1145-796-1183.4-803.7c-90.1-17.2-157.3,5.8-230.2,78.7l-63.3,63.3V113.2c0,1862.4-9.6,1728.1,122.8,1810.6C4221.3,2008.2,4202.1,2019.7,5445,1145.1z" />
      </g>
    </g>
  </svg>
);
