import ArticleGroup from './ArticleGroup';
import Competition from './slices/Competition';
import EmailSignup from './slices/EmailSignup';
import Document from './Document';
import File from './slices/File';
import Media from './slices/Media';
import Products from './slices/Products';
import ProductText from './slices/ProductText';
import Publication from './slices/Publication';
import Quote from './slices/Quote';
import RelatedArticle from './slices/RelatedArticle';
import SignupForm from './slices/SignupForm';
import SmsSignup from './slices/SmsSignup';
import Slice from './slices/Slice';
import Table from './slices/Table';
import Text from './slices/Text';

class Article extends Document {
  constructor(article, siteUID, products) {
    super(article, siteUID);

    this.articleGroup = article.articleGroup
      ? new ArticleGroup(article.articleGroup, siteUID)
      : null;
    this.ingress = article.ingress;
    this.title = article.title;
    this.featuredMedia = new Media({
      type: article.featuredMedia?.type,
      media: article.featuredMedia,
    });
    this.headerContentOverlay = article.headerContentOverlay;
    this.isLandingPage = article.isLandingPage;
    this.slices = article.slices?.map((slice) =>
      Article.parseSlice(slice, siteUID, products)
    );

    this.constructorName = 'Article';
  }

  static parseSlice(slice, siteUID, products) {
    switch (slice._type) {
      case 'collectionSlice':
        return new Products(slice, siteUID, products);
      case 'competitionSlice':
        return new Competition(slice);
      case 'emailSignupSlice':
        return new EmailSignup(slice);
      case 'fileSlice':
        return new File(slice);
      case 'mediaSlice':
        return new Media(slice);
      case 'productsSlice':
        return new Products(slice, siteUID, products);
      case 'productTextSlice':
        return new ProductText(slice, siteUID);
      case 'publicationSlice':
        return new Publication(slice, siteUID);
      case 'quoteSlice':
        return new Quote(slice);
      case 'relatedArticleSlice':
        return new RelatedArticle(slice, siteUID);
      case 'signupFormSlice':
        return new SignupForm(slice, siteUID);
      case 'smsSignupSlice':
        return new SmsSignup(slice);
      case 'tableSlice':
        return new Table(slice);
      case 'textSlice':
        return new Text(slice);
      default:
        return new Slice(slice);
    }
  }
}

export default Article;
