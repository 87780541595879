import ProductImage from './ProductImage';

class ProductVariant {
  constructor(variant, site) {
    this.color = variant.ItemColor?.value;
    this.colorName = variant.ItemColorName?.value;
    this.diameterImperial = variant.ItemDiameterImperial?.value;
    this.diameterMetric = variant.ItemDiameterMetric?.value;
    this.diameterSize = variant.ItemDiameterSize?.value;
    this.ean = variant.ItemEAN?.value;
    this.familyName = variant.ItemFamilyName?.value;
    this.gapMetric = variant.ItemGapMetric?.value;
    this.hookSize = variant.ItemHookSize?.value;
    this.id = variant.id;
    this.images = ProductVariant.mapImages(variant.resources);
    this.inventory = variant[site?.inventoryField]?.value.value;
    this.lengthImperial = variant.ItemLengthImperial?.value;
    this.lengthMetric = variant.ItemLengthMetric?.value;
    this.lengthSize = variant.ItemLengthSize?.value;
    this.lengthUnitImperial = variant.ItemLengthUnitImperial?.value;
    this.lureDepth = variant.ItemLureDepth?.value;
    this.oldPrice = variant[site?.originalPriceField]?.value.value;
    this.packCode = variant.ItemPackCode?.value;
    this.packQuantity = variant.ItemPackQuantity?.value;
    this.price = variant[site?.sellPriceField]?.value.value;
    this.rodAction = variant.ItemRodAction?.value;
    this.rodLineClass = variant.ItemRodLineClassSize?.value;
    this.rodLureWeight = variant.ItemRodLureWeightSize?.value;
    this.rodPower = variant.ItemRodPower?.value;
    this.rodSections = variant.ItemRodSections?.value;
    this.rodStyle = variant.ItemRodStyle?.value;
    this.rodTransportLength = variant.ItemRodTransportLengthSize?.value;
    this.size = variant.ItemSize?.value;
    this.sizeSort = variant.ItemSizeSort?.value;
    this.sizeStyle = variant.ItemSizeStyle?.value;
    this.sku = variant.ItemSKUID?.value;
    this.skuName = variant.ItemSkuName?.value;
    this.showWebAmericas = variant.ItemShowWebAmericas?.value;
    this.showWebCanada = variant.ItemShowWebCanada?.value;
    this.showWebEurope = variant.ItemShowWebEurope?.value;
    this.showWebUSA = variant.ItemShowWebUSA?.value;
    this.sourceId = variant.entityId;
    this.styleName = variant.ItemStyleName?.value;
    this.testImperial = variant.ItemTestImperial?.value;
    this.testSize = variant.ItemTestRatingSize?.value;
    this.testUnitImperial = variant.ItemTestImperialUnit?.value;
    this.weightImperial = variant.ItemWeightImperial?.value;
    this.weightSize = variant.ItemWeightSize?.value;
    this.weightUnitImperial = variant.ItemWeightUnitImperial?.value;
    this.upc = variant.ItemUPC?.value;

    if (parseFloat(this.price) === 0 || parseFloat(this.price) === 88888) {
      this.price = null;
    }
  }

  static mapImages(resources) {
    return resources.map((resource) => new ProductImage(resource));
  }
}

export default ProductVariant;
