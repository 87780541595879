class ProductImage {
  constructor(image) {
    this.id = image.id;
    this.imageMap = ProductImage.mapImageMap(image.imageMap);
    this.name = image.displayName;
    this.sortKey = image.sortKey;
    this.url = image.resourceUrl;
  }

  static mapImageMap(imageMap) {
    return {
      spots: imageMap?.value?.value?.areas?.map((area) => ({
        x: area.coords.split(',')[0],
        y: area.coords.split(',')[1],
        sourceId: area.entityId,
      })),
    };
  }
}

export default ProductImage;
