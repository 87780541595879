import React, { useState } from 'react';

const DiscountCode = ({ addDiscountCode, cart, removeDiscountCode }) => {
  const [discountCode, setDiscountCode] = useState('');

  const onDiscountCodeChange = (e) => {
    setDiscountCode(e.target.value);
  };

  const onDiscountCodeRemove = () => {
    removeDiscountCode(discountCode).then(() => setDiscountCode(''));
  };

  const onDiscountCodeApply = (e) => {
    e.preventDefault();
    addDiscountCode(discountCode);
  };

  const discountCodes = cart?.discountCodes || [];
  const hasDiscount = discountCodes.length > 0 && discountCodes[0].applicable;
  const currentCode = hasDiscount ? discountCodes[0].code : discountCode;

  return (
    <div className="cart__discount-code">
      <form className="discount-code__form" onSubmit={onDiscountCodeApply}>
        <input
          type="text"
          name="discountCode"
          onChange={onDiscountCodeChange}
          value={currentCode}
          disabled={hasDiscount || false}
          placeholder="Discount Code"
        />

        {hasDiscount ? (
          <button type="reset" onClick={onDiscountCodeRemove}>
            Remove
          </button>
        ) : (
          <button type="submit">Apply</button>
        )}
      </form>
    </div>
  );
};

export default DiscountCode;
