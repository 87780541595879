import React from 'react';
import { compose } from 'redux';

import Loyalty from '../header/utils/Loyalty';
import withAccount from '../../containers/plugins/withAccount';
import withLoyalty from '../../containers/plugins/withLoyalty';

const LoyaltyBar = ({ site }) => {
  return (
    <div className="site-footer__loyalty-bar">
      <Loyalty site={site} />
    </div>
  );
};

export default compose(withAccount, withLoyalty)(LoyaltyBar);
