import React, { Component } from 'react';

import Image from './Image';
import Video from './Video';
import ImageModel from '../../models/slices/Image';
import VideoModel from '../../models/slices/Video';

class Media extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { media } = this.props;

    const addClassNames = [
      media.fullHeight ? 'media--full-height' : '',
      media.fullWidth ? 'media--full-width' : '',
    ].join(' ');

    return (
      <div className={`media ${addClassNames}`}>
        {media.media instanceof ImageModel ? (
          <Image image={media.media} />
        ) : (
          <Video video={media.media} />
        )}
      </div>
    );
  }
}

export default Media;
