import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Close, Search as SearchIcon } from '../../_common/Icons';
import { toggleSearchOpen } from '../../../state/menuService';

class Search extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { dispatch, searchOpen } = this.props;

    dispatch(toggleSearchOpen(!searchOpen));
  }

  render() {
    const { searchOpen } = this.props;

    return (
      <button
        className="site-footer__util util--search-toggle"
        onClick={this.onClick}
      >
        {searchOpen ? Close : SearchIcon}
      </button>
    );
  }
}

export default connect((state) => ({
  searchOpen: state.menuService.searchOpen,
}))(Search);
