import React, { Component } from 'react';

import LanguageToggle from '../header/utils/LanguageToggle';
import Link from '../Link';
import MobileMenuPaneItem from './MobileMenuPaneItem';
import { AngleLeftFat } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';

class MobileMenuPane extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPrevious: false,
    };

    this.onDescend = this.onDescend.bind(this);
    this.onAscend = this.onAscend.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.site.id !== this.props.site.id) {
      this.setState((prevState) => ({
        ...prevState,
        isPrevious: false,
      }));
    }
  }

  onDescend() {
    this.setState((prevState) => ({ ...prevState, isPrevious: true }));
  }

  onAscend() {
    this.setState((prevState) => ({ ...prevState, isPrevious: false }));
  }

  render() {
    const { isPrevious } = this.state;
    const {
      children,
      featuredChildren,
      isCurrent,
      isRoot,
      level,
      onAscend,
      title,
      site,
    } = this.props;

    const classNames = [
      isPrevious ? 'pane--previous' : '',
      isCurrent && !isPrevious ? 'pane--current' : '',
    ].join(' ');

    return (
      <div className={`mobile-menu__pane ${classNames}`}>
        {!isRoot && (
          <span className="mobile-menu__pane-back" onClick={onAscend}>
            {AngleLeftFat}
            Back to menu
          </span>
        )}

        {title && <h4 className="mobile-menu__pane-header">{title}</h4>}

        <ul className={`mobile-menu__pane-list`}>
          {children.map((child) => (
            <MobileMenuPaneItem
              key={child.id}
              children={child.children}
              featuredChildren={child.featuredChildren}
              isRoot={isRoot}
              linkTarget={child.linkTarget}
              onClick={child.onClick}
              onDescend={this.onDescend}
              onAscend={this.onAscend}
              site={site}
              title={getCmsValue(child.title, site)}
            />
          ))}

          {isRoot && <LanguageToggle fullTitle={true} site={site} />}
        </ul>

        {featuredChildren.length > 0 && (
          <ul className="mobile-menu__pane-sidebar">
            {featuredChildren.map((child) => (
              <MobileMenuPaneItem
                key={child.id}
                children={child.children}
                featuredChildren={child.featuredChildren}
                isRoot={isRoot}
                linkTarget={child.linkTarget}
                onClick={child.onClick}
                onDescend={this.onDescend}
                onAscend={this.onAscend}
                site={site}
                title={getCmsValue(child.title, site)}
              />
            ))}
          </ul>
        )}
      </div>
    );
  }
}

export default MobileMenuPane;
