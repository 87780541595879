import React, { Component } from 'react';

class Tag extends Component {
  render() {
    const { title } = this.props;

    return (
      <span className="tag">
        <span className="tag-title">{title}</span>
      </span>
    );
  }
}

export default Tag;
