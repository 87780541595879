import Link from '../../Link';
import React, { Component } from 'react';
import Slider from 'react-slick';

import Logos from '../../_common/Logos';
import ProductImage from '../ProductImage';
import productStars from '../product/productStars';
import Tag from '../../_common/Tag';
import withShopProduct from '../../../containers/plugins/withShopProduct';
import { getPrice, getPimValue } from '../../../utils/utils';
import {
  NextAngle,
  PrevAngle,
  NextArrow,
  PrevArrow,
} from '../product/images/Arrows';

class ProductItem extends Component {
  constructor(props) {
    super(props);

    this.onVariantSelected = this.onVariantSelected.bind(this);

    this.state = {
      hovering: false,
      selectedImageIndex: 0,
      selectedVariantSku: null,
      shopProductLoaded: false,
    };
  }

  componentDidMount() {
    const { fetchProduct, product, skuLevel } = this.props;
    const { shopProductLoaded } = this.state;

    if (!shopProductLoaded && !skuLevel && product.variants.length === 1) {
      fetchProduct();

      this.setState((prevState) => ({
        ...prevState,
        shopProductLoaded: true,
      }));
    }

    if (skuLevel && product.variants.length === 1) {
      this.onVariantSelected(product.variants[0].sku);
    }
  }

  onVariantSelected(selectedVariantSku) {
    this.setState((prevState) => ({
      ...prevState,
      selectedVariantSku: selectedVariantSku,
    }));
  }

  // TODO: Show selected variant on front pane
  render() {
    const { product, single, site } = this.props;
    const { selectedImageIndex, selectedVariantSku } = this.state;

    const selectedVariant =
      product.variants.find((variant) => variant.sku === selectedVariantSku) ||
      null;

    const oldPrice =
      parseFloat(product.prices[site.originalPriceField]) || null;
    const price = parseFloat(product.prices[site.sellPriceField]) || null;

    let productQuery = '';
    product.variableFields.forEach((field, i) => {
      if (!selectedVariant) return;
      productQuery += i === 0 ? '?' : '&';
      productQuery += `${field.value}=${getPimValue(
        selectedVariant[field.value],
        site
      )}`;
    });

    const brand = getPimValue(product.brand, site)
      .toLowerCase()
      .replace(' ', '-');

    if (!brand) return null;

    const settings = {
      className: 'product-item__image-preview',
      dots: false,
      infinite: false,
      lazyLoad: true,
      nextArrow: single ? <NextAngle /> : <NextArrow />,
      prevArrow: single ? <PrevAngle /> : <PrevArrow />,
      speed: 200,
      slidesToScroll: 7,
      slidesToShow: 7,
      vertical: single,
    };

    return (
      <li className={`product-item ${single ? 'product-item--single' : ''}`}>
        {product.badge?.value && product.badge?.value?.en !== 'None' && (
          <Tag title={getPimValue(product.badge, site)} />
        )}
        <div className="product-item__image">
          <Link
            className="product-item__link"
            site={site}
            to={{
              brandUID: brand,
              ...product,
            }}
            q={productQuery}
          >
            {/*
              <ProductImages
                mainSizes={[
                  { query: '(max-width: 787px)', containerSize: '335px' },
                  { query: '', containerSize: '354px' },
                ]}
                previewParams={{
                  navigation: 'inline'
                }}
                previewSizes={[
                  { query: '', containerSize: '32px' },
                ]}
                product={product}
                site={site}
              />
            */}
            <ProductImage
              key={product.images[selectedImageIndex]?.url}
              image={product.images[selectedImageIndex]}
              sizes={[
                { query: '(max-width: 479px)', containerSize: '422px' },
                { query: '(max-width: 767px)', containerSize: '340px' },
                { query: '', containerSize: '262px' },
              ]}
            >
              {brand !== site.brandUID && (
                <span className="product-item__image__brand-tag">
                  {Logos[brand].small}
                </span>
              )}
              <span className="product-item__image__stars">
                {productStars(product.score.value)}
              </span>
            </ProductImage>
          </Link>

          <Slider ref={(ref) => (this.sliderRef = ref)} {...settings}>
            {product.images.map((image, i) => (
              <ProductImage
                key={image?.url}
                className={`${selectedImageIndex === i ? ' active' : ''}`}
                image={image}
                sizes={[{ query: '', containerSize: '50px' }]}
                onClick={() =>
                  this.setState((prevState) => ({
                    ...prevState,
                    selectedImageIndex: i,
                  }))
                }
              />
            ))}
          </Slider>
        </div>

        <div className="product-item__content">
          <Link
            className="product-item__link"
            site={site}
            to={{
              brandUID: brand,
              ...product,
            }}
            q={productQuery}
          >
            <h3 className="product-item__title">
              {getPimValue(product.name, site)}
            </h3>
          </Link>

          <footer className="product-item__footer">
            {site.shopEnabled && price && (
              <span className="product-item__prices-wrapper">
                <div className="product-item__prices">
                  {oldPrice > price ? (
                    <>
                      <span className="product-item__price price--new">
                        {getPrice(site, price)}
                        {product.variants.length > 1 ? ' +' : ''}
                      </span>
                      <span className="product-item__price price--old">
                        {getPrice(site, oldPrice)}
                      </span>
                    </>
                  ) : (
                    <span className="product-item__price">
                      {getPrice(site, price)}
                      {product.variants.length > 1 ? ' +' : ''}
                    </span>
                  )}
                </div>
              </span>
            )}
            <span className="product-item__series">
              {getPimValue(product.series, site)}
            </span>
          </footer>
        </div>
      </li>
    );
  }
}

export default withShopProduct(ProductItem);
