import Document from './Document';

class ArticleGroup extends Document {
  constructor(articleGroup, siteUID) {
    super(articleGroup, siteUID);

    this.ingress = articleGroup.ingress;
    this.title = articleGroup.title;

    this.constructorName = 'ArticleGroup';
    this.siteUID = siteUID;
  }
}

export default ArticleGroup;
