import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import slugify from 'slugify';
import { connect } from 'react-redux';

import ShopGQLClient from './ShopGQLClient';
import { getPrice, mapEdgesToNodes } from '../../utils/utils';
import { productByHandle } from './productCollectionQueries';
import { setShopProductsLoading } from '../../state/shopService';

function withShopProduct(WrappedComponent) {
  class WithShopProduct extends Component {
    constructor(props) {
      super(props);

      this.client = new ShopGQLClient(props.site.shopUrl, props.site.shopToken);
      this.fetchProduct = this.fetchProduct.bind(this);
      this.resolveVariants = this.resolveVariants.bind(this);
      this.resolveVariant = this.resolveVariant.bind(this);

      this.initialState = { product: props.product };
      this.state = { ...this.initialState };
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    async fetchProduct() {
      const { dispatch, product, site } = this.props;

      if (!site.shopEnabled) return;

      let newProduct = cloneDeep(product);

      dispatch(
        setShopProductsLoading({ product: newProduct.path, loading: true })
      );

      const isJson = (data) => {
        try {
          const json = JSON.parse(data);
          if (Object.keys(json).length === 0) {
            return false;
          }
        } catch (e) {
          return false;
        }
        return true;
      };

      try {
        const fetchProduct = async (handle) => {
          return await this.client
            .query(
              productByHandle({ handle: handle, countryCode: site.countryCode })
            )
            .then((result) => {
              const inventory = result.data.productByHandle?.inventory?.value;
              return {
                id: result.data.productByHandle?.id || null,
                description: result.data.productByHandle?.description,
                images: result.data.productByHandle?.images,
                handle: result.data.productByHandle?.handle,
                inventory: isJson(inventory)
                  ? JSON.parse(inventory)[site.inventoryField] || {}
                  : null,
                variants: mapEdgesToNodes(
                  result.data.productByHandle?.variants || []
                ),
              };
            });
        };

        let shopProduct = await fetchProduct(newProduct.path);
        if (!shopProduct.id) {
          shopProduct = await fetchProduct(
            slugify(newProduct.name.value.en, {
              lower: true,
              replacement: '-',
              remove: /[*+~.()'"!:@]/g,
            })
          );
        }

        newProduct = await this.resolveVariants(newProduct, shopProduct, site);
        newProduct.baseShopId = shopProduct.id.split('/').slice(-1)[0];
        newProduct.shopId = shopProduct.id;
      } catch (err) {
        console.log(err);
      }

      this.setState((prevState) => ({
        ...prevState,
        product: newProduct,
      }));

      dispatch(
        setShopProductsLoading({ product: newProduct.path, loading: false })
      );
    }

    async resolveVariants(newProduct, shopProduct, site) {
      let itemInventory = shopProduct.inventory;
      if (!shopProduct.inventory) {
        itemInventory = await axios
          .get(`/api/products/${site.siteUID}/product/${newProduct.sourceId}`)
          .then((res) => res.data.data);
      }

      if (newProduct.path === 'gift-card') {
        newProduct.longDescription = {
          value: {
            en: shopProduct.description,
          },
        };
        newProduct.shortDescription = {
          value: {
            en: shopProduct.description,
          },
        };
        newProduct.images = shopProduct.images.edges.map((edge) => ({
          url: edge.node.url,
        }));

        newProduct.variants = shopProduct.variants.map((shopVariant) => {
          const newVariant = {
            ...shopVariant,
            ...this.resolveVariant(shopProduct, shopVariant, itemInventory),
            images: [],
            sku: { value: shopVariant.sku },
            size: { value: getPrice(site, shopVariant.price.amount) },
          };

          return newVariant;
        });
      } else {
        newProduct.variants.forEach((variant, i) => {
          const shopVariant = shopProduct.variants.find(
            (shopVariant) => shopVariant.sku === variant.sku.value
          );

          const newVariant = {
            ...variant,
            ...this.resolveVariant(shopProduct, shopVariant, itemInventory),
          };

          newProduct.variants[i] = newVariant;
        });
      }

      return newProduct;
    }

    resolveVariant(shopProduct, shopVariant, itemInventory) {
      const variant = {};

      const compareAtPrice =
        parseFloat(shopVariant?.compareAtPrice?.amount || '0.00') || null;
      const price = parseFloat(shopVariant?.price?.amount || '0.00') || null;

      if (!shopVariant || price === 0 || price === 88888) {
        return;
      }

      const inventory =
        shopProduct.handle === 'gift-card'
          ? 1
          : itemInventory[shopVariant?.sku];

      variant.backorder = shopVariant.currentlyNotInStock;
      variant.inventory = inventory;
      variant.available = inventory > 0;
      variant.oldPrice = compareAtPrice;
      variant.parentShopId = shopProduct.id;
      variant.price = price;
      variant.shopId = shopVariant.id;

      return variant;
    }

    render() {
      const { product, ...props } = this.props;

      return (
        <WrappedComponent
          fetchProduct={this.fetchProduct}
          product={this.state.product}
          {...props}
        />
      );
    }
  }

  return connect((state) => ({}))(WithShopProduct);
}

export default withShopProduct;
