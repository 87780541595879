import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import ProductItem from './ProductItem';
import SiteLoader from '../../_common/SiteLoader';
import withShopCollection from '../../../containers/plugins/withShopCollection';
import { filterProducts, getCmsValue } from '../../../utils/utils';
import { NoResult } from '../../_common/Icons';
import { setLoadableItems } from '../../../state/paginationService';

class Products extends Component {
  constructor(props) {
    super(props);

    this.loadMore = this.loadMore.bind(this);
  }

  componentDidMount() {
    const { fetchCollection, productGroup, skuLevel } = this.props;

    if (productGroup?.path === 'sale' || skuLevel) {
      fetchCollection();
    }
  }

  loadMore() {
    const { dispatch, id, initialItemsLoad, loadableItems } = this.props;

    dispatch(
      setLoadableItems({
        id: id,
        value: (loadableItems[id] || initialItemsLoad) + initialItemsLoad,
      })
    );
  }

  render() {
    const {
      filterServiceData,
      id,
      initialItemsLoad,
      loadableItems,
      productListWrapper,
      productItemWrapper,
      renderAll,
      shopCollectionsLoading,
      singleRow,
      site,
      skipSort,
      skuLevel,
    } = this.props;

    const shopCollectionLoading = shopCollectionsLoading.includes(
      this.props.productGroup?.collection || ''
    );

    const products = filterProducts(
      filterServiceData,
      this.props.products,
      site,
      skipSort
    );

    let renderableProducts;
    if (renderAll) {
      renderableProducts = [...products];
    } else {
      renderableProducts = products.slice(
        0,
        loadableItems[id]
          ? loadableItems[id] + initialItemsLoad
          : initialItemsLoad
      );
    }

    const single = singleRow && renderableProducts.length === 1;

    let productComponents;

    if (skuLevel) {
      productComponents = renderableProducts.map((product) => {
        if (product.variants.length > 0) {
          return product.variants
            .filter((variant) => variant.price < variant.oldPrice)
            .map((variant) => {
              return (
                <ProductItem
                  key={variant.sku}
                  product={{
                    ...product,
                    variants: [variant],
                  }}
                  skuLevel={true}
                  single={single}
                  site={site}
                />
              );
            });
        }
      });
    } else {
      productComponents = renderableProducts.map((product) => {
        return (
          <ProductItem
            key={product.path}
            product={product}
            single={single}
            site={site}
          />
        );
      });
    }

    if (productItemWrapper) {
      productComponents = productComponents.map((component) =>
        productItemWrapper(component)
      );
    }

    let productsComponent;
    if (productListWrapper) {
      productsComponent = productListWrapper(productComponents, single);
    } else {
      productsComponent = productComponents;
    }

    return (
      <div
        className={`products__list-wrapper ${
          single ? 'products__list-wrapper--single' : ''
        }`}
      >
        {renderableProducts.length > 0 ? (
          <ul
            className={`products__list ${
              singleRow ? 'products__list--single-row' : ''
            }`}
          >
            {shopCollectionLoading ? <SiteLoader /> : productsComponent}
          </ul>
        ) : (
          <div className="products__list products__list--none">
            <span className="list--none__icon">{NoResult}</span>
            <span className="list--none__text">
              {getCmsValue(site.strings.productList__noItems, site) ||
                'Sorry, no fish in the sea.'}
            </span>
          </div>
        )}

        {products.length > renderableProducts.length && (
          <button className="products__list__load-more" onClick={this.loadMore}>
            {getCmsValue(site.strings.productList__loadMoreButton, site)}
          </button>
        )}
      </div>
    );
  }
}

export default compose(
  withShopCollection,
  connect((state) => ({
    filterServiceData: { ...state.filterService },
    initialItemsLoad: state.paginationService.initialItemsLoad,
    loadableItems: { ...state.paginationService.loadableItems },
  }))
)(Products);
