import Slice from './Slice';

class Text extends Slice {
  constructor(slice) {
    super(slice);

    this.text = {
      languagePrimary: slice.textContent._rawLanguagePrimary,
      languageSecondary: slice.textContent._rawLanguageSecondary,
    };
  }
}

export default Text;
