import { connect } from 'react-redux';
import React, { Component } from 'react';

import Link from '../../Link';
import { Location } from '../../_common/Icons';

class StoresButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {}

  render() {
    const { site } = this.props;

    return (
      <Link
        className="site-footer__util util--stores-button"
        site={site}
        to={{
          siteUID: site.siteUID,
          path: 'stores',
        }}
      >
        {Location}
      </Link>
    );
  }
}

export default connect((state) => ({}))(StoresButton);
