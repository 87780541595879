import Slice from './Slice';
import VideoDefault from './VideoDefault';
import VideoYoutube from './VideoYoutube';
import VideoPickit from './VideoPickit';

class Video extends Slice {
  constructor(slice) {
    super(slice);

    switch (slice.media.videoType) {
      case 'default':
        this.video = new VideoDefault(slice);
        break;
      case 'youtube':
        this.video = new VideoYoutube(slice);
        break;
      case 'pickit':
        this.video = new VideoPickit(slice);
        break;
      default:
        this.video = null;
        break;
    }
  }
}

export default Video;
