import Slice from './Slice';

class Publication extends Slice {
  constructor(slice) {
    super(slice);

    this.previewUrl = slice.publication.imageUrl;
    this.publicationId = slice.publication.value;
    this.title = slice.publication.title;
  }
}

export default Publication;
