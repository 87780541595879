import Slice from './Slice';

class VideoDefault extends Slice {
  constructor(slice) {
    super(slice);

    this.previewUrl = slice.media.video.imageUrl;
    this.url = slice.media.video.value;
  }
}

export default VideoDefault;
