import { connect } from 'react-redux';
import { compose } from 'redux';
import React, { useEffect, useRef } from 'react';

import Cart from '../_common/cart/Cart';
import { Close } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';
import { toggleCartOpen } from '../../state/shopService';

const CartSidebar = ({ cartOpen, dispatch, site }) => {
  const sidebar = useRef(null);

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick);
    return () => document.removeEventListener('mousedown', onOutsideClick);
  }, [cartOpen]);

  const onOutsideClick = (e) => {
    if (!cartOpen || sidebar?.current.contains(e.target)) return;
    onCartClose();
  };

  const onCartClose = () => {
    dispatch(toggleCartOpen(false));
  };

  return (
    <div
      className={`cart__sidebar ${cartOpen ? 'cart__sidebar--open' : ''}`}
      ref={sidebar}
    >
      <header className="cart__header">
        <button className="cart__close-button" onClick={onCartClose}>
          {Close}
        </button>
        <h2 className="cart__title">
          {getCmsValue(site.strings.cart__title, site)}
        </h2>
      </header>

      <Cart onContinueShopping={onCartClose} site={site} />
    </div>
  );
};

export default compose(
  connect((state) => ({
    cartOpen: state.shopService.cartOpen,
  }))
)(CartSidebar);
