import React, { Component } from 'react';

import SubscribeForm from '../_common/SubscribeForm';

class EmailSignup extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { emailSignup, site } = this.props;

    return (
      <>
        <SubscribeForm
          listId={emailSignup.listId}
          type="email"
          inputType="email"
          placeholder="Your email address"
          site={site}
        />
      </>
    );
  }
}

export default EmailSignup;
