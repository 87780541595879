class Site {
  constructor(site) {
    this.brandUID = site.brand?.uid?.current;
    this.countryCode = site.countryCode;
    this.currencyCode = site.currencyCode;
    this.currencySymbol = site.currencySymbol;
    this.currencySymbolPlacement = site.currencySymbolPlacement;
    this.defaultSignupForm = site.defaultSignupForm;
    this.enabled = site.enabled;
    this.id = site.id;
    this.includeTaxes = site.includeTaxes;
    this.inventoryField = site.inventoryField?.value;
    this.languagePrimary = site.languagePrimary;
    this.languageSecondary = site.languageSecondary;
    this.mirrorSiteUID = site.mirrorSite?.uid?.current || null;
    this.originalPriceField = site.originalPriceField?.value;
    this.parentSiteUID = site.parentSite?.uid.current;
    this.productItemFilterField = site.productItemFilterField?.value;
    this.quantitySoldField = site.quantitySoldField?.value;
    this.sellPriceField = site.sellPriceField?.value;
    this.shopEnabled = site.shopEnabled;
    this.shopToken = site.shopToken;
    this.shopUrl = site.shopUrl;
    this.socialLinks =
      site.socialLinks?.map((link) => ({ type: link.type, url: link.url })) ||
      [];
    this.strings = site.strings;
    this.title = site.title;
    this.type = site.type;

    this.constructorName = 'Site';
    this.siteUID = site.uid.current;

    if (this.brandUID === process.env.GATSBY_SITE_BRAND) {
      this.brandUID === undefined;
    }
  }
}

export default Site;
