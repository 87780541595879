import { gql } from '@apollo/client';

const cartFragment = gql`
  fragment Cart on Cart {
    attributes {
      key
      value
    }
    buyerIdentity {
      countryCode
    }
    checkoutUrl
    cost {
      subtotalAmount {
        amount
        currencyCode
      }
      totalAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
    }
    deliveryGroups(first: 10) {
      edges {
        node {
          deliveryAddress {
            country
            province
          }
          selectedDeliveryOption {
            code
            estimatedCost {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
    discountAllocations {
      discountedAmount {
        amount
        currencyCode
      }
    }
    discountCodes {
      applicable
      code
    }
    id
    lines(first: 250) {
      edges {
        node {
          cost {
            amountPerQuantity {
              amount
              currencyCode
            }
            compareAtAmountPerQuantity {
              amount
              currencyCode
            }
            subtotalAmount {
              amount
              currencyCode
            }
            totalAmount {
              amount
              currencyCode
            }
          }
          id
          merchandise {
            ... on ProductVariant {
              image {
                src
              }
              product {
                handle
                title
                vendor
              }
              sku
              title
            }
          }
          quantity
        }
      }
    }
  }
`;

export const cart = (id) => ({
  query: gql`
    ${cartFragment}
    query ($id: ID!) {
      cart(id: $id) {
        ...Cart
      }
    }
  `,
  variables: { id },
});

export const cartAttributesUpdate = (countryCode, cartId, attributes) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $attributes: [AttributeInput!]!
    ) @inContext(country: $countryCode) {
      cartAttributesUpdate(attributes: $attributes, cartId: $cartId) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, attributes },
});

export const cartBuyerIdentityUpdate = (
  countryCode,
  cartId,
  buyerIdentity
) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $buyerIdentity: CartBuyerIdentityInput!
    ) @inContext(country: $countryCode) {
      cartBuyerIdentityUpdate(buyerIdentity: $buyerIdentity, cartId: $cartId) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, buyerIdentity },
});

export const cartCreate = (countryCode) => ({
  mutation: gql`
    ${cartFragment}
    mutation ($countryCode: CountryCode!) @inContext(country: $countryCode) {
      cartCreate {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode },
});

export const cartDiscountCodesUpdate = (
  countryCode,
  cartId,
  discountCodes
) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $discountCodes: [String!]!
    ) @inContext(country: $countryCode) {
      cartDiscountCodesUpdate(cartId: $cartId, discountCodes: $discountCodes) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, discountCodes },
});

export const cartLinesAdd = (countryCode, cartId, lines) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $lines: [CartLineInput!]!
    ) @inContext(country: $countryCode) {
      cartLinesAdd(cartId: $cartId, lines: $lines) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, lines },
});

export const cartLinesRemove = (countryCode, cartId, lineIds) => ({
  mutation: gql`
    ${cartFragment}
    mutation ($countryCode: CountryCode!, $cartId: ID!, $lineIds: [ID!]!)
    @inContext(country: $countryCode) {
      cartLinesRemove(cartId: $cartId, lineIds: $lineIds) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, lineIds },
});

export const cartLinesUpdate = (countryCode, cartId, lines) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $lines: [CartLineUpdateInput!]!
    ) @inContext(country: $countryCode) {
      cartLinesUpdate(cartId: $cartId, lines: $lines) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, lines },
});

export const cartSelectedDeliveryOptionsUpdate = (
  countryCode,
  cartId,
  selectedDeliveryOptions
) => ({
  mutation: gql`
    ${cartFragment}
    mutation (
      $countryCode: CountryCode!
      $cartId: ID!
      $selectedDeliveryOptions: [CartSelectedDeliveryOptionInput!]!
    ) @inContext(country: $countryCode) {
      cartSelectedDeliveryOptionsUpdate(
        cartId: $cartId
        selectedDeliveryOptions: $selectedDeliveryOptions
      ) {
        cart {
          ...Cart
        }
        userErrors {
          field
          message
        }
      }
    }
  `,
  variables: { countryCode, cartId, selectedDeliveryOptions },
});
