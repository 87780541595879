import { connect } from 'react-redux';
import React, { Component } from 'react';

import { setActiveMenuKey, toggleMenuOpen } from '../../../state/menuService';

class MenuToggle extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { dispatch, menuOpen } = this.props;

    dispatch(setActiveMenuKey('headerMenu'));
    dispatch(toggleMenuOpen(!menuOpen));
  }

  render() {
    return (
      <button
        className={`site-header__util util--menu-toggle ${
          this.props.menuOpen ? 'menu-toggle--open' : ''
        }`}
        onClick={this.onClick}
      >
        <span className="menu-toggle__icon" />
      </button>
    );
  }
}

export default connect((state) => ({
  menuOpen: state.menuService.menuOpen,
}))(MenuToggle);
