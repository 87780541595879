import { connect } from 'react-redux';
import React, { Component } from 'react';

import Link from '../../Link';
import { Location } from '../../_common/Icons';

class StoresButton extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {}

  render() {
    const { site } = this.props;

    const to = {
      brandUID: site.brandUID,
      path: 'stores',
      siteUID: site.siteUID,
    };

    return (
      <Link
        className="site-header__util util--stores-button"
        site={site}
        to={to}
      >
        {Location}
      </Link>
    );
  }
}

export default connect((state) => ({}))(StoresButton);
