import React, { Component } from 'react';

export default (rating) => (
  <svg className="stars" version="1.1" viewBox="0 0 100 18">
    <defs>
      <pattern
        id="pattern1"
        x="0"
        y="0"
        width="20"
        height="18"
        patternUnits="userSpaceOnUse"
      >
        <path
          d="M 9.5 14.25 L 3.916 17.186 L 4.982 10.968 L 0.465 6.564 L 6.708 5.657 L 9.5 0 L 12.292 5.657 L 18.535 6.564 L 14.018 10.968 L 15.084 17.186"
          style={{
            fill: 'white',
          }}
        ></path>
      </pattern>
      <pattern
        id="pattern2"
        x="0"
        y="0"
        width="20"
        height="18"
        patternUnits="userSpaceOnUse"
      >
        <path
          d="M 9.5 14.25 L 3.916 17.186 L 4.982 10.968 L 0.465 6.564 L 6.708 5.657 L 9.5 0 L 12.292 5.657 L 18.535 6.564 L 14.018 10.968 L 15.084 17.186"
          style={{
            fill: 'white',
          }}
        ></path>
      </pattern>
      <mask id="mask1" x="0" y="0" width="100" height="18">
        <rect
          x="0"
          y="0"
          width="100"
          height="18"
          style={{
            fill: 'url(#pattern2)',
          }}
        ></rect>
      </mask>
      <mask id="mask2" x="0" y="0" width="100" height="18">
        <rect
          x="0"
          y="0"
          width="100"
          height="18"
          style={{
            fill: 'url(#pattern1)',
          }}
        ></rect>
      </mask>
    </defs>
    <rect
      className="stars__background"
      x="0"
      y="0"
      width="100"
      height="18"
      style={{
        mask: 'url(#mask1)',
      }}
      fill="url(#pattern2)"
    ></rect>{' '}
    <rect
      className="stars__filled"
      x="0"
      y="0"
      width={(rating / 5) * 100}
      height="18"
      style={{
        mask: 'url(#mask2)',
      }}
    ></rect>
  </svg>
);
