import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductImage = ({ children, className, image, onClick, sizes }) => {
  const imgSrcConfig = {
    srcSetWebP: [
      { config: '&width=50', size: '50w' },
      { config: '&width=150', size: '150w' },
      { config: '&width=300', size: '300w' },
      { config: '&width=400', size: '400w' },
      { config: '&width=600', size: '600w' },
      { config: '&width=760', size: '760w' },
      { config: '&width=1100', size: '1100w' },
    ],
    srcSetJPEG: [
      { config: '&width=50&format=jpeg', size: '50w' },
      { config: '&width=150&format=jpeg', size: '150w' },
      { config: '&width=300&format=jpeg', size: '300w' },
      { config: '&width=400&format=jpeg', size: '400w' },
      { config: '&width=600&format=jpeg', size: '600w' },
      { config: '&width=765&format=jpeg', size: '760w' },
      { config: '&width=1100&format=jpeg', size: '1100w' },
    ],
  };

  // prettier-ignore
  if (!image) return null;

  const srcSetWebP = imgSrcConfig.srcSetWebP.map(
    (config) => `${image?.url || ''}${config.config} ${config.size}`
  );
  const srcSetJPEG = imgSrcConfig.srcSetJPEG.map(
    (config) => `${image?.url || ''}${config.config} ${config.size}`
  );
  const imageSizes = sizes?.map(
    (size) => `${size.query} ${size.containerSize}`
  );

  return (
    <div
      className={`product-image__wrapper ${className || ''}`}
      onClick={onClick || undefined}
    >
      {children}
      <picture>
        <source srcSet={srcSetWebP} sizes={imageSizes} type="image/webp" />
        <source srcSet={srcSetJPEG} sizes={imageSizes} type="image/jpeg" />
        <LazyLoadImage
          alt={image?.alt || ''}
          className="product-image"
          src={`${image?.url || ''}&width=1100`}
        />
      </picture>
    </div>
  );
};

export default ProductImage;
