import Slice from './Slice';

class Products extends Slice {
  constructor(slice, siteUID, products) {
    super(slice);

    this.multiRow = slice.multiRow;
    this.products = products[slice._key];
    this.title = slice.title;
  }
}

export default Products;
