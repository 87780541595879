import Slice from './Slice';

class SignupForm extends Slice {
  constructor(slice) {
    super(slice);

    this.signupFormId = slice.signupFormId;
  }
}

export default SignupForm;
