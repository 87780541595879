import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';

import LanguageToggle from './utils/LanguageToggle';
import Link from '../Link';
import Loyalty from './utils/Loyalty';
import logos from '../_common/Logos';
import { getCmsValue, getPrice } from '../../utils/utils';

class BrandBar extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { brand, cart, site, sites } = this.props;

    const freeShipping =
      site.strings?.brandBar__freeShippingLimit?.languagePrimary || '50';
    const cartTotal = cart?.cost?.totalAmount?.amount || '0';
    const remainder = parseFloat(freeShipping) - parseFloat(cartTotal);

    const activeBrand = brand.brandUID;
    const availableSites = sites.filter(
      (compSite) => compSite.siteUID === site.siteUID
    );

    const brands = availableSites
      .map((site) => ({
        name: site.brandUID,
        origin: {
          brandUID: site.brandUID,
          siteUID: site.siteUID,
        },
      }))
      .sort((a, b) => (a.name === process.env.GATSBY_SITE_BRAND ? -1 : 1));

    const shippingNotice = (
      <div className="brand-bar__shipping-notice">
        {remainder <= 0 ? (
          <span>
            {getCmsValue(site.strings.brandBar__freeShippingAchieved)}
          </span>
        ) : (
          <span>
            {getCmsValue(site.strings.brandBar__freeShippingRemaining).replace(
              /%s/,
              getPrice(site, remainder)
            )}
          </span>
        )}
      </div>
    );

    return (
      <div className="site-header__brand-bar">
        <div className="site-header__brand-bar-wrapper">
          <div className="brand-bar__brands">
            {brands.map((brand) => (
              <Link
                key={brand.name}
                className={`brand-bar__brand ${
                  brand.name === activeBrand ? 'brand-bar__brand--active' : ''
                }`}
                site={site}
                to={brand.origin}
              >
                {logos[brand.name].defaultMono || logos[brand.name].default}
              </Link>
            ))}
          </div>
          {site.shopEnabled && shippingNotice}
          <div className="brand-bar__utils">
            <LanguageToggle site={site} />
            {site.shopEnabled && <Loyalty site={site} />}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect((state) => ({
    cart: state.shopService.cart,
  }))
)(BrandBar);
