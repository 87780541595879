import React, { Component } from 'react';

import {
  Facebook,
  Fishbrain,
  Instagram,
  Twitter,
  Youtube,
} from '../_common/Icons';
import Link from '../Link';

class IconLinks extends Component {
  constructor(props) {
    super(props);

    this.iconMapping = {
      Facebook: Facebook,
      Fishbrain: Fishbrain,
      Instagram: Instagram,
      Twitter: Twitter,
      Youtube: Youtube,
    };
  }

  render() {
    const { links, site } = this.props;

    return (
      <div className="icon-links">
        {links.map((link) => (
          <Link
            key={link.type}
            className={`icon-link icon-link--${link.type.toLowerCase()}`}
            site={site}
            to={link.url}
          >
            {this.iconMapping[link.type]}
          </Link>
        ))}
      </div>
    );
  }
}

export default IconLinks;
