class Slice {
  constructor(slice) {
    this.id = slice._key;
    this.type = slice._type;

    this.schedule = slice.schedule;
    this.scheduleStart = this.schedule ? new Date(slice.scheduleStart) : null;
    this.scheduleEnd = this.schedule ? new Date(slice.scheduleEnd) : null;
  }
}

export default Slice;
