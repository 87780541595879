import Slice from './Slice';

class Table extends Slice {
  constructor(slice) {
    super(slice);

    this.table = slice.table;
  }
}

export default Table;
