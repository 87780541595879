import axios from 'axios';
import React, { Component } from 'react';

function withLoyalty(WrappedComponent) {
  class WithLoyalty extends Component {
    constructor(props) {
      super(props);

      this.campaigns = this.campaigns.bind(this);
      this.customers = this.customers.bind(this);
      this.redemptionOptions = this.redemptionOptions.bind(this);

      this.initialState = {
        campaigns: [],
        customer: null,
        redemptionOptions: [],
      };

      this.state = { ...this.initialState };
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    async action(email, actionName) {
      const body = {
        customer_email: email,
        type: 'CustomAction',
        action_name: actionName,
      };

      const response = await axios({
        method: 'POST',
        url: `/api/loyalty/actions`,
        data: body,
      }).then((res) => res.data);

      return response;
    }

    async campaigns(email) {
      const body = {
        customer_email: email,
      };

      const response = await axios({
        method: 'GET',
        url: `/api/loyalty/campaigns`,
        data: body,
      }).then((res) => res.data);

      this.setState((prevState) => ({
        ...prevState,
        campaigns: response,
      }));

      return response;
    }

    async customers(email) {
      const response = await axios({
        method: 'GET',
        url: `/api/loyalty/customers?customer_email=${email}`,
      }).then((res) => res.data);

      this.setState((prevState) => ({
        ...prevState,
        customer: response,
      }));

      return response;
    }

    async redemptionOptions(email) {
      const response = await axios({
        method: 'GET',
        url: `/api/loyalty/redemption_options?customer_email=${email}`,
      }).then((res) => res.data);

      this.setState((prevState) => ({
        ...prevState,
        redemptionOptions: response,
      }));

      return response;
    }

    async redemptions(email, redemptionOptionId) {
      const body = {
        customer_email: email,
        redemption_option_id: redemptionOptionId,
      };

      const response = await axios({
        method: 'POST',
        url: `/api/loyalty/redemptions`,
        data: body,
      }).then((res) => res.data);

      return response;
    }

    async redemptionsCancellationCompleted(pointRedemptionId, rewardText) {
      const body = {
        reward_text: rewardText,
      };

      const response = await axios({
        method: 'POST',
        url: `/api/loyalty/redemptions/${pointRedemptionId}/cancellation_completed`,
        data: body,
      }).then((res) => res.data);

      return response;
    }

    async referralReferrer(email, firstName, lastName) {
      const body = {
        email: email,
        first_name: firstName || '',
        last_name: lastName || '',
      };

      const response = await axios({
        method: 'POST',
        url: `/api/loyalty/referral/referrer`,
        data: body,
      }).then((res) => res.data);

      return response;
    }

    async referralShare(email, emails) {
      const body = {
        email: email,
        emails: emails,
      };

      const response = await axios({
        method: 'POST',
        url: `/api/loyalty/referral/share`,
        data: body,
      }).then((res) => res.data);

      return response;
    }

    async vipTiers() {
      const response = await axios({
        method: 'GET',
        url: `/api/loyalty/vip_tiers`,
      }).then((res) => res.data);

      return response;
    }

    render() {
      const { ...props } = this.props;

      const loyalty = {
        actions: this.actions,
        campaigns: this.campaigns,
        customers: this.customers,
        redemptionOptions: this.redemptionOptions,
        redemptions: this.redemptions,
        redemptionsCancellationCompleted: this.redemptionsCancellationCompleted,
        referralReferrer: this.referralReferrer,
        referralShare: this.referralShare,
        state: { ...this.state },
        vipTiers: this.vipTiers,
      };

      return <WrappedComponent loyalty={loyalty} {...props} />;
    }
  }

  return WithLoyalty;
}

export default withLoyalty;
