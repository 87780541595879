import Slice from './Slice';

class VideoYoutube extends Slice {
  constructor(slice) {
    super(slice);

    const youtubeParser = (url) => {
      var regExp =
        /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
      var match = url.match(regExp);
      return match && match[7].length == 11 ? match[7] : false;
    };

    const youtubeId =
      slice.media.videoYoutube.indexOf('http') === 0
        ? youtubeParser(slice.media.videoYoutube)
        : slice.media.videoYoutube;

    this.previewUrl = `https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`;
    this.url = `https://www.youtube.com/embed/${youtubeId}`;
  }
}

export default VideoYoutube;
