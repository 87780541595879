import axios from 'axios';

import countryMappings from '../utils/countryMappings';
import { setUserLocation } from '../state/userLocationService';

export const userLocation = async (brand, dispatch, sites) => {
  const newUserLocation = await axios.get('/api/location').then((res) => {
    const newUserLocation = {};

    if (res.data.errors || res.status !== 200) {
      newUserLocation.continentCode = '';
      newUserLocation.continentName = '';
      newUserLocation.countryCode = 'US';
      newUserLocation.countryName = '';
      newUserLocation.isEu = false;
      newUserLocation.subdivisions = [];
    } else {
      newUserLocation.continentCode = res.data.continentCode;
      newUserLocation.continentName = res.data.continentName;
      newUserLocation.countryCode = res.data.countryCode;
      newUserLocation.countryName = res.data.countryName;
      newUserLocation.isEu = res.data.isEu;
      newUserLocation.subdivisions = res.data.subdivisions;
    }

    return newUserLocation;
  });

  dispatch && dispatch(setUserLocation(newUserLocation));

  const newSite = countryMappings(newUserLocation, brand, sites);

  const pathParts = location.pathname.split('/').filter((part) => part);
  if (pathParts && pathParts[0] === brand.brandUID) pathParts.shift();
  const purePathname = pathParts.slice(-2).join('/');

  const parts = [newSite.siteUID, purePathname, location.search].filter(
    (part) => part
  );

  const brandUID = brand.brandUID;
  if (brandUID !== process.env.GATSBY_SITE_BRAND) parts.unshift(brandUID);

  return {
    path: `/${parts.join('/')}`,
    site: newSite,
  };
};
