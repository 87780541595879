import lscache from 'lscache';

/**
 * Initial state
 */
const initialUserLocationServiceData = {
  location: {
    continentCode: 'NA',
    continentName: 'North America',
    countryCode: 'US',
    countryName: 'United States',
    isEu: false,
    subdivisions: [],
  },
  browseForeign: false,
  foreignSite: '',
};

/**
 * Action types
 */
const SET_USER_LOCATION = 'SET_USER_LOCATION';
const SET_BROWSE_FOREIGN = 'SET_BROWSE_FOREIGN';
const SET_FOREIGN_SITE = 'SET_FOREIGN_SITE';

/**
 * Action creators
 */
export const setUserLocation = (payload) => ({
  type: SET_USER_LOCATION,
  payload: payload,
});
export const setBrowseForeign = (payload) => ({
  type: SET_BROWSE_FOREIGN,
  payload: payload,
});
export const setForeignSite = (payload) => ({
  type: SET_FOREIGN_SITE,
  payload: payload,
});

/**
 * Reducer
 */
export default {
  userLocationService(
    state = lscache.get('userLocationServiceData') ||
      initialUserLocationServiceData,
    { type, payload }
  ) {
    const newState = { ...state };

    switch (type) {
      case SET_USER_LOCATION:
        newState.location.continentCode = payload.continentCode;
        newState.location.continentName = payload.continentName;
        newState.location.countryName = payload.countryName;
        newState.location.countryCode = payload.countryCode;
        newState.location.isEu = payload.isEu;
        newState.location.subdivisions = payload.subdivisions;
        break;
      case SET_BROWSE_FOREIGN:
        newState.browseForeign = payload;
      case SET_FOREIGN_SITE:
        newState.foreignSite = payload;
      default:
        break;
    }

    lscache.set('userLocationServiceData', newState);
    return newState;
  },
};
