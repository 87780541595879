import Slice from './Slice';

class Quote extends Slice {
  constructor(slice) {
    super(slice);

    this.quote = slice.quote;
    this.source = slice.source;
  }
}

export default Quote;
