import lscache from 'lscache';

/**
 * Initial state
 */
const initialNotificationServiceData = {
  cookieNotice: true,
  locationNotice: false,
};

/**
 * Action types
 */
export const SET_COOKIE_NOTICE = 'SET_COOKIE_NOTICE';
export const SET_LOCATION_NOTICE = 'SET_LOCATION_NOTICE';

/**
 * Action creators
 */
export const setCookieNotice = (payload) => ({
  type: SET_COOKIE_NOTICE,
  payload: payload,
});
export const setLocationNotice = (payload) => ({
  type: SET_LOCATION_NOTICE,
  payload: payload,
});

/**
 * Reducer
 */
export default {
  notificationService(
    state = lscache.get('notificationServiceData') ||
      initialNotificationServiceData,
    { type, payload }
  ) {
    const newState = { ...state };

    switch (type) {
      case SET_COOKIE_NOTICE:
        newState.cookieNotice = payload;
        break;
      case SET_LOCATION_NOTICE:
        newState.locationNotice = payload;
        break;
      default:
        break;
    }

    lscache.set('notificationServiceData', newState);
    return newState;
  },
};
