import React from 'react';

const Checkout = ({ cart, onContinueShopping, site }) => {
  const onCheckoutButtonClick = () => {
    try {
      if (typeof window !== 'undefined') {
        window.gtag_wpromote('event', 'conversion', {
          send_to: `${process.env.GATSBY_SITE_GTAG_WPROMOTE}/krqWCNq9tYIYENz1qfYC`,
        });
      }
    } catch (err) {}

    document.location.href = cart.checkoutUrl;
  };

  return (
    <div className="cart__checkout">
      <button
        className="checkout__continue-button"
        onClick={onContinueShopping}
      >
        Continue Shopping
      </button>
      <button
        className="checkout__checkout-button"
        onClick={onCheckoutButtonClick}
      >
        Checkout
      </button>
    </div>
  );
};

export default Checkout;
