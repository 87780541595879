export default (userLocation, brand, sites) => {
  const { countryCode, isEu } = userLocation;

  const brandSites = sites.filter((site) => site.brandUID === brand.brandUID);

  const countrySite = brandSites.find(
    (site) => site.countryCode === countryCode
  );
  if (countrySite) return countrySite;

  const euSite = brandSites.find((site) => site.siteUID === 'eu');
  if (isEu && euSite) return euSite;

  const regionSites = {};
  const regionSite = brandSites.find(
    (site) => site.siteUID === regionSites[countryCode]
  );
  if (regionSite) return regionSite;

  return brandSites.find((site) => site.siteUID === 'us');
};
