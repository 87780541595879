import accountService from './accountService';
import commonStateService from './commonStateService';
import filterService from './filterService';
import menuService from './menuService';
import notificationService from './notificationService';
import paginationService from './paginationService';
import shopService from './shopService';
import userLocationService from './userLocationService';

/**
 * Reducers
 */
export default {
  ...accountService,
  ...commonStateService,
  ...filterService,
  ...menuService,
  ...notificationService,
  ...paginationService,
  ...shopService,
  ...userLocationService,
};
