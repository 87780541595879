import Document from './Document';
import MediaSlice from './slices/Media';

class ProductGroup extends Document {
  constructor(productGroup, siteUID) {
    super(productGroup, siteUID);
    this.category = productGroup.category?.value;
    this.collection = productGroup.collection?.value;
    this.ingress = productGroup.ingress;
    this.subCategory = productGroup.subCategory?.value;
    this.title = productGroup.title;
    this.productGroupType = productGroup.productGroupType;

    if (productGroup.featuredMedia) {
      this.mediaSlice = new MediaSlice({
        _key: '',
        _type: 'mediaSlice',
        media: productGroup.featuredMedia,
        schedule: null,
        scheduleStart: null,
        scheduleEnd: null,
      });
    }

    this.constructorName = 'ProductGroup';
  }
}

export default ProductGroup;
