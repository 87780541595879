import React, { Component } from 'react';

import Competition from './Competition';
import EmailSignup from './EmailSignup';
import File from './File';
import Hero from './Hero';
import Media from './Media';
import Products from './Products';
import ProductGroups from './ProductGroups';
import ProductText from './ProductText';
import Publication from './Publication';
import Quote from './Quote';
import RelatedArticle from './RelatedArticle';
import SignupForm from './SignupForm';
import SmsSignup from './SmsSignup';
import Table from './Table';
import Text from './Text';

class Slice extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { className, slice, site } = this.props;

    const now = new Date();

    if (slice.schedule) {
      if (slice.scheduleStart && now < slice.scheduleStart) return null;
      if (slice.scheduleEnd && now > slice.scheduleEnd) return null;
    }

    const sliceType = (slice) => {
      switch (slice.type) {
        case 'collectionSlice':
          return <Products products={slice} site={site} />;
        case 'competitionSlice':
          return <Competition competition={slice} />;
        case 'emailSignupSlice':
          return <EmailSignup emailSignup={slice} site={site} />;
        case 'fileSlice':
          return <File file={slice} site={site} />;
        case 'heroSlice':
          return <Hero hero={slice} site={site} />;
        case 'mediaSlice':
          return <Media media={slice} />;
        case 'productGroupsSlice':
          return <ProductGroups productGroups={slice} site={site} />;
        case 'productsSlice':
          return <Products products={slice} site={site} />;
        case 'productTextSlice':
          return <ProductText productText={slice} site={site} />;
        case 'publicationSlice':
          return <Publication publication={slice} site={site} />;
        case 'quoteSlice':
          return <Quote quote={slice} site={site} />;
        case 'relatedArticleSlice':
          return <RelatedArticle relatedArticle={slice} site={site} />;
        case 'signupFormSlice':
          return <SignupForm signupForm={slice} site={site} />;
        case 'smsSignupSlice':
          return <SmsSignup smsSignup={slice} site={site} />;
        case 'tableSlice':
          return <Table table={slice} site={site} />;
        case 'textSlice':
          return <Text text={slice} site={site} />;
        default:
          return null;
      }
    };

    return (
      <section className={`slice slice--${slice.type} ${className || ''}`}>
        {sliceType(slice)}
      </section>
    );
  }
}

export default Slice;
