const mapEdgesToNodes = (data) => {
  if (!data?.edges) return [];
  return data.edges.map((edge) => edge.node);
};

/**
 * Localization functions
 */
const store = require('../state/redux-wrapper').reduxStore;
const state = store.getState().commonStateService;

const getCmsValue = (property, site) => {
  if (!property) return '';

  const locale = state?.cmsLanguage || 'languagePrimary';

  if (property && property[locale]) {
    return property[locale];
  } else {
    return property.languagePrimary;
  }
};

const getPimValue = (property, site) => {
  if (!property) return '';

  const locale = state?.pimLanguage || 'en';

  if (property?.value?.en) {
    if (property.value[locale]) {
      return property.value[locale];
    } else {
      return property.value.en;
    }
  } else if (typeof property.value === 'object') {
    return '';
  } else {
    return property.value;
  }
};

/**
 * Miscellaneous functions
 */
const getProductsForSlices = (
  slices,
  allInRiverProduct,
  Product,
  siteUID,
  site
) => {
  const products = mapEdgesToNodes(allInRiverProduct);

  return Object.keys(slices).reduce((acc, sliceKey) => {
    const sliceProducts = products.filter((product) =>
      slices[sliceKey].includes(product.entityId)
    );
    return {
      ...acc,
      [sliceKey]: sliceProducts.map(
        (product) => new Product(product, siteUID, site)
      ),
    };
  }, {});
};

const getPrice = (site, price) => {
  if (!isFinite(price)) return null;

  const fixedPrice = price ? parseFloat(price).toFixed(2) : price;

  if (site.currencySymbolPlacement === 'before') {
    return `${site.currencySymbol}${fixedPrice}`;
  } else {
    return `${fixedPrice}${site.currencySymbol}`;
  }
};

const filterProducts = (filterServiceData, products, site, skipSort) => {
  const { filters, searchTerm, sortKey } = filterServiceData;

  let filteredProducts = products;

  for (const filterKey of Object.keys(filters)) {
    if (filters[filterKey].activeFilters.length > 0) {
      for (const activeFilter of filters[filterKey].activeFilters) {
        filteredProducts = filteredProducts.filter((product) => {
          if (Array.isArray(product[filterKey])) {
            return product[filterKey].find(
              (value) => getPimValue(value) === activeFilter
            );
          } else {
            return getPimValue(product[filterKey]) === activeFilter;
          }
        });
      }
    }
  }

  if (searchTerm) {
    filteredProducts = filteredProducts.filter((p) => {
      let matches = 0;
      for (const term of searchTerm.split(' ')) {
        if (!term) continue;
        if (p.searchKeys.includes(term)) matches++;
      }
      return matches > 0;
    });
  }

  const brands = filteredProducts.reduce((brands, product) => {
    if (!brands[product.brand.value]) brands[product.brand.value] = [];
    brands[product.brand.value].push(product);
    return brands;
  }, {});

  const newSort = (a, b) => {
    return a.launchWindow - b.launchWindow;
  };

  const oldSort = (a, b) => {
    return a.launchWindow - b.launchWindow;
  };

  const stringSort = (a, b) => {
    return getPimValue(a[sortKey]).localeCompare(getPimValue(b[sortKey]));
  };

  const numberSort = (a, b) => {
    return getPimValue(a[sortKey]) - getPimValue(b[sortKey]);
  };

  const scoreSort = (a, b) => {
    return (
      getPimValue(a[sortKey]) - getPimValue(b[sortKey]) ||
      getPimValue(a.totalReviews) - getPimValue(b.totalReviews)
    );
  };

  const priceSort = (a, b) => {
    return a.prices[site.sellPriceField] - b.prices[site.sellPriceField];
  };

  const sortMethods = {
    quantitySold: numberSort,
    score: scoreSort,
    priceLow: priceSort,
    priceHigh: priceSort,
    newest: newSort,
    oldest: oldSort,
    name: stringSort,
    series: stringSort,
  };

  const reverseSortKeys = ['quantitySold', 'score', 'priceHigh', 'newest'];

  let sortedBrandProducts = [];

  if (brands['Mustad Fishing']) {
    if (skipSort) {
      sortedBrandProducts = brands['Mustad Fishing'];
    } else {
      sortedBrandProducts = brands['Mustad Fishing'].sort(sortMethods[sortKey]);
    }
    if (!skipSort && reverseSortKeys.includes(sortKey)) {
      sortedBrandProducts = sortedBrandProducts.reverse();
    }
  }

  for (const brand of Object.keys(brands)) {
    if (brand === 'Mustad Fishing') continue;

    let brandProducts = skipSort
      ? brands[brand]
      : brands[brand].sort(sortMethods[sortKey]);
    if (!skipSort && reverseSortKeys.includes(sortKey)) {
      brandProducts = brandProducts.reverse();
    }

    sortedBrandProducts = [...sortedBrandProducts, ...brandProducts];
  }

  return sortedBrandProducts;
};

module.exports = {
  mapEdgesToNodes,
  getProductsForSlices,
  getPrice,
  getPimValue,
  getCmsValue,
  filterProducts,
};
