import React from 'react';

import LineItem from './LineItem';
import { mapEdgesToNodes } from '../../../utils/utils';

const LineItems = ({ lineItems }) => {
  return (
    <div className="cart__line-items-container">
      <table className="cart__line-items">
        <thead>
          <tr className="cart__line-item">
            <th className="line-item__column" colSpan="2">
              Product
            </th>
            <th className="line-item__column">Price</th>
            <th className="line-item__column">Quantity</th>
            <th className="line-item__column" colSpan="2">
              Subtotal
            </th>
          </tr>
        </thead>
        <tbody>
          {mapEdgesToNodes(lineItems).map((lineItem) => (
            <LineItem key={lineItem.id} lineItem={lineItem} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default LineItems;
