import React, { Component } from 'react';

import VideoDefault from './VideoDefault';
import VideoPickit from './VideoPickit';
import VideoYoutube from './VideoYoutube';
import VideoDefaultModel from '../../models/slices/VideoDefault';
import VideoPickitModel from '../../models/slices/VideoPickit';
import VideoYoutubeModel from '../../models/slices/VideoYoutube';

class Video extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { video } = this.props;

    if (video.video instanceof VideoDefaultModel) {
      return <VideoDefault video={video} />;
    } else if (video.video instanceof VideoPickitModel) {
      return <VideoPickit video={video} />;
    } else if (video.video instanceof VideoYoutubeModel) {
      return <VideoYoutube video={video} />;
    }
  }
}

export default Video;
