import React, { useEffect, useRef, useState } from 'react';
import { compose } from 'redux';

import { AngleDownFat, AngleUpFat } from '../../_common/Icons';
import LoyaltyDrawer from './LoyaltyDrawer';
import withAccount from '../../../containers/plugins/withAccount';
import withLoyalty from '../../../containers/plugins/withLoyalty';

const handleOutsideClick = (ref, handler) => {
  useEffect(() => {
    function handleOutsideClick(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handler();
      }
    }
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [ref]);
};

const Loyalty = ({ account, loyalty, site }) => {
  const { campaigns, customer, redemptionOptions } = loyalty.state;
  const wrapperRef = useRef(null);
  handleOutsideClick(wrapperRef, () => setLoyaltyOpen(false));

  const [loyaltyOpen, setLoyaltyOpen] = useState(false);

  useEffect(() => {
    if (account?.email && !customer) {
      getCustomer();
    } else {
      loyalty.campaigns();
    }
  }, []);

  useEffect(() => {
    getCustomer();
    loyalty.campaigns();
  }, [account?.email]);

  const getCustomer = async () => {
    loyalty.customers(account?.email);
    loyalty.redemptionOptions(account?.email);
  };

  return (
    <div className="brand-bar__util util__loyalty" ref={wrapperRef}>
      <button onClick={() => setLoyaltyOpen(!loyaltyOpen)}>
        {account
          ? `You have ${customer?.points_balance} points`
          : `Mustad Rewards`}
        {loyaltyOpen ? AngleUpFat : AngleDownFat}
      </button>
      <LoyaltyDrawer
        account={account}
        campaigns={campaigns}
        customer={customer}
        loyalty={loyalty}
        open={loyaltyOpen}
        redemptionOptions={redemptionOptions}
        site={site}
      />
    </div>
  );
};

export default compose(withAccount, withLoyalty)(Loyalty);
