import React, { Component } from 'react';

import Link from '../Link';
import Media from './Media';
import { ArrowRight } from '../_common/Icons';
import { getCmsValue } from '../../utils/utils';

class Hero extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { hero, site } = this.props;

    return (
      <>
        <div className="hero__media">
          <Media media={hero.media} />
        </div>
        <div className="hero__content">
          <div className="hero__caption">
            <span>{getCmsValue(hero.caption, site)}</span>
          </div>
          <h1 className="hero__title">
            <span>{getCmsValue(hero.title, site)}</span>
          </h1>
          <Link className="hero__link" site={site} to={hero.linkTarget}>
            {getCmsValue(hero.linkLabel, site)}
          </Link>
        </div>
      </>
    );
  }
}

export default Hero;
