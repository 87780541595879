import axios from 'axios';
import Helmet from 'react-helmet';
import lscache from 'lscache';
import React, { Component } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { register } from 'swiper/element/bundle';
import 'swiper/scss';

import CartSidebar from './cart/CartSidebar';
import Footer from './footer/Footer';
import Header from './header/Header';
import ExpandableMenu from './expandable-menu/ExpandableMenu';
import Notifications from './notifications/Notifications';
import withAccount from '../containers/plugins/withAccount';
import withMenus from '../containers/withMenus';
import { setLocationNotice } from '../state/notificationService';
import { setShopData, setSiteUID } from '../state/commonStateService';
import { resetFilterServiceData } from '../state/filterService';
import { toggleMenuOpen, toggleSearchOpen } from '../state/menuService';
import { userLocation } from '../utils/userLocation';

import '../styles/index.scss';

class Layout extends Component {
  constructor(props) {
    super(props);

    this.onResize = this.onResize.bind(this);
    this.setCustomAttributes = this.setCustomAttributes.bind(this);
    this.setUserLocation = this.setUserLocation.bind(this);

    const isBrowser = typeof window !== 'undefined';
    const isSimpleBrowser = isBrowser
      ? !window.ActiveXObject && 'ActiveXObject' in window
      : false;

    this.state = {
      isBrowser: isBrowser,
      isSimpleBrowser: isSimpleBrowser,
      screenSize: undefined,
    };
  }

  componentDidMount() {
    const { dispatch, site } = this.props;

    register();

    if (this.state.isBrowser) {
      window.addEventListener('resize', this.onResize);
      this.onResize();
    }

    dispatch(setSiteUID(site.siteUID));
    dispatch(setShopData({ shopUrl: site.shopUrl, shopToken: site.shopToken }));

    this.setCustomAttributes();
    this.setUserLocation();
  }

  componentWillUnmount() {
    if (this.state.isBrowser) {
      window.removeEventListener('resize', this.onResize);
    }
  }

  componentDidUpdate(prevProps) {
    const { dispatch, cmsLanguage, path, site } = this.props;

    const oldPath = prevProps.path;
    if (oldPath !== path) {
      dispatch(resetFilterServiceData());
      dispatch(toggleMenuOpen(false));
      dispatch(toggleSearchOpen(false));
      dispatch(setSiteUID(site.siteUID));
      dispatch(
        setShopData({ shopUrl: site.shopUrl, shopToken: site.shopToken })
      );
    }

    if (prevProps.cmsLanguage !== cmsLanguage) {
      window.location.reload();
    }

    if (prevProps.site.siteUID !== site.siteUID) {
      window.location.reload();
    }
  }

  onResize() {
    const screenSize =
      window.matchMedia && window.matchMedia('(max-width: 768px)').matches
        ? 'small'
        : 'large';

    if (screenSize !== this.state.screenSize) {
      this.setState({ screenSize });
    }
  }

  setCustomAttributes() {
    const customAttributes = lscache.get('customAttributes') || {};

    const exclusions = [
      'packCode',
      'weightSize',
      'hookSize',
      'colorName',
      'size',
    ];

    const qParams = new URLSearchParams(window.location.search);
    qParams.forEach((value, key) => {
      if (!exclusions.includes(key)) customAttributes[key] = value;
    });

    lscache.set('customAttributes', customAttributes, 525600);
  }

  async setUserLocation() {
    const { brand, dispatch, site, sites } = this.props;
    const browseForeign = this.props.userLocation.browseForeign;

    const newUserLocation = await userLocation(brand, dispatch, sites);

    if (!browseForeign && site.siteUID !== newUserLocation.site.siteUID) {
      dispatch(setLocationNotice(true));
    } else {
      dispatch(setLocationNotice(false));
    }
  }

  render() {
    const {
      brand,
      children,
      cartOpen,
      menuOpen,
      menus,
      overlayOpen,
      site,
      sites,
    } = this.props;

    const classNames = [
      cartOpen ? 'cart--open' : '',
      menuOpen ? 'menu--open' : '',
    ].join(' ');

    return (
      <>
        <Helmet
          bodyAttributes={{
            class: `brand--${brand.brandUID}`,
          }}
          titleTemplate={`%s | ${brand.name}`}
          defaultTitle={brand.name}
        >
          <html lang={site.languagePrimary.value} />
          <meta name="description" content={``} />
        </Helmet>

        <div className={`site ${classNames}`}>
          <div
            className={`site-overlay ${
              overlayOpen ? 'site-overlay--open' : ''
            }`}
          ></div>
          <Header
            brand={brand}
            headerMenu={menus.headerMenu}
            screenSize={this.state.screenSize}
            site={site}
            sites={sites}
          />
          <ExpandableMenu
            isBrowser={this.state.isBrowser}
            isSimpleBrowser={this.state.isSimpleBrowser}
            menus={menus}
            screenSize={this.state.screenSize}
            site={site}
          />
          <Notifications site={site} />
          <main className="site-wrapper">
            {React.cloneElement(children, { menus })}
          </main>
          <Footer
            footerMenu={menus.footerMenu}
            screenSize={this.state.screenSize}
            site={site}
            sites={sites}
          />
          <CartSidebar site={site} />
        </div>
      </>
    );
  }
}

export default compose(
  withAccount,
  withMenus,
  connect((state) => ({
    cartOpen: state.shopService.cartOpen,
    cmsLanguage: state.commonStateService.cmsLanguage,
    overlayOpen: state.commonStateService.overlayOpen,
    userLocation: { ...state.userLocationService },
  }))
)(Layout);
