import React, { Component } from 'react';

import Link from '../../Link';
import { User } from '../../_common/Icons';

class AccountButton extends Component {
  render() {
    const { site } = this.props;

    const to = {
      brandUID: site.brandUID,
      path: 'account',
      siteUID: site.siteUID,
    };

    return (
      <Link
        className="site-header__util util--account-button"
        site={site}
        to={to}
      >
        {User}
      </Link>
    );
  }
}

export default AccountButton;
