import Image from './Image';
import Slice from './Slice';
import Video from './Video';

class Media extends Slice {
  constructor(slice) {
    super(slice);

    this.media = Media.parseMedia(slice);
    this.fullWidth = slice.media?.fullWidth;
    this.fullHeight = slice.media?.fullHeight;
  }

  static parseMedia(slice) {
    switch (slice.media?.type) {
      case 'image':
        return new Image(slice);
      case 'video':
        return new Video(slice);
      default:
        return null;
    }
  }
}

export default Media;
