import React, { Component } from 'react';
import { FilledDrop } from '../_common/Icons';

import { getCmsValue } from '../../utils/utils';

class Quote extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { quote, site } = this.props;

    return (
      <>
        <div className="quote__content">
          <div className="quote__quote">{getCmsValue(quote.quote, site)}</div>
        </div>

        <div className="quote__source">- {quote.source}</div>
      </>
    );
  }
}

export default Quote;
