import axios from 'axios';
import lscache from 'lscache';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ShopGQLClient from './ShopGQLClient';
import {
  cart,
  cartAttributesUpdate,
  cartBuyerIdentityUpdate,
  cartCreate,
  cartDiscountCodesUpdate,
  cartLinesAdd,
  cartLinesRemove,
  cartLinesUpdate,
  cartSelectedDeliveryOptionsUpdate,
} from './cartQueries';
import { setCart, setCartId, setCartLoading } from '../../state/shopService';

function withCart(WrappedComponent) {
  class WithCart extends Component {
    constructor(props) {
      super(props);

      this.client = new ShopGQLClient(props.site.shopUrl, props.site.shopToken);

      this.initialState = {
        cart: null,
      };

      this.state = { ...this.initialState };

      this.loadCart = this.loadCart.bind(this);
      this.updateCartCountry = this.updateCartCountry.bind(this);
      this.updateDeliveryOption = this.updateDeliveryOption.bind(this);
      this.updateAttributes = this.updateAttributes.bind(this);
      this.addDiscountCode = this.addDiscountCode.bind(this);
      this.removeDiscountCode = this.removeDiscountCode.bind(this);
      this.addLineItem = this.addLineItem.bind(this);
      this.updateLineItem = this.updateLineItem.bind(this);
      this.removeLineItem = this.removeLineItem.bind(this);
      this.setCart = this.setCart.bind(this);
    }

    async loadCart() {
      const { dispatch, cartLoading, cartId, site } = this.props;

      let newCart;

      const isConverted = async (cart) => {
        const query = new URLSearchParams({
          checkoutUrl: cart.checkoutUrl,
        }).toString();
        const isConverted = await axios
          .get(`/api/cart/isconverted?${query}`)
          .then((res) => res.status)
          .catch((err) => err);

        return isConverted === 205;
      };

      if (cartLoading) return;
      dispatch(setCartLoading(true));

      const fetchCart = async (id) => {
        const existingCart = await this.client
          .query(cart(id))
          .then((res) => res.data.cart)
          .catch((err) => ({ error: err }));

        return existingCart;
      };

      const createCart = async () => {
        const newCart = await this.client
          .mutate(cartCreate(site.countryCode))
          .then((res) => res.data.cartCreate.cart)
          .catch((err) => ({ error: err }));

        return newCart;
      };

      if (cartId) {
        const existingCart = await fetchCart(cartId);

        if (existingCart?.id) {
          const converted = await isConverted(existingCart);

          if (!converted) {
            newCart = existingCart;
          } else {
            newCart = await createCart();
          }
        } else {
          newCart = await createCart();
        }
      } else {
        newCart = await createCart();
      }

      this.setCart(newCart);
      await this.updateAttributes(newCart.id);
      await this.updateCartCountry(newCart.id);

      dispatch(setCartLoading(false));

      return newCart;
    }

    async updateAttributes(cartId) {
      const { site } = this.props;

      const lsCacheCustomAttributes = lscache.get('customAttributes') || {};
      const customAttributes = Object.entries(lsCacheCustomAttributes).map(
        ([key, value]) => ({
          key,
          value,
        })
      );

      const updatedCart = await this.client
        .mutate(
          cartAttributesUpdate(site.countryCode, cartId, customAttributes)
        )
        .then((res) => res.data.cartAttributesUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async updateCartCountry(cartId, province, country = null) {
      const { site } = this.props;

      const buyerIdentity = {
        countryCode: country ? country : site.countryCode,
        deliveryAddressPreferences: {
          deliveryAddress: {
            country: country ? country : site.countryCode,
          },
        },
      };

      if (province)
        buyerIdentity.deliveryAddressPreferences.deliveryAddress.province =
          province;

      const updatedCart = await this.client
        .mutate(
          cartBuyerIdentityUpdate(site.countryCode, cartId, buyerIdentity)
        )
        .then((res) => res.data.cartBuyerIdentityUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async updateDeliveryOption(cartId) {
      const { site } = this.props;

      const deliveryOptions = [null];

      const updatedCart = await this.client
        .mutate(
          cartSelectedDeliveryOptionsUpdate(
            site.countryCode,
            cartId,
            deliveryOptions
          )
        )
        .then((res) => res.data.cartSelectedDeliveryOptionsUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async addDiscountCode(discountCode) {
      const { cartId, site } = this.props;

      const updatedCart = await this.client
        .mutate(
          cartDiscountCodesUpdate(site.countryCode, cartId, [discountCode])
        )
        .then((res) => res.data.cartDiscountCodesUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async removeDiscountCode() {
      const { cartId, site } = this.props;

      const updatedCart = await this.client
        .mutate(cartDiscountCodesUpdate(site.countryCode, cartId, ['']))
        .then((res) => res.data.cartDiscountCodesUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async addLineItem(variantId, quantity = 1) {
      const { cartId, site } = this.props;

      const updatedCart = await this.client
        .mutate(
          cartLinesAdd(site.countryCode, cartId, [
            {
              merchandiseId: variantId,
              quantity: quantity,
            },
          ])
        )
        .then((res) => res.data.cartLinesAdd.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async updateLineItem(lineItemId, quantity) {
      const { cartId, site } = this.props;

      const updatedCart = await this.client
        .mutate(
          cartLinesUpdate(site.countryCode, cartId, [
            {
              id: lineItemId,
              quantity: quantity,
            },
          ])
        )
        .then((res) => res.data.cartLinesUpdate.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    async removeLineItem(lineItemId) {
      const { cartId, site } = this.props;

      const updatedCart = await this.client
        .mutate(cartLinesRemove(site.countryCode, cartId, [lineItemId]))
        .then((res) => res.data.cartLinesRemove.cart)
        .catch((err) => ({ error: err }));

      this.setCart(updatedCart);

      return updatedCart;
    }

    setCart(cart) {
      const { dispatch } = this.props;

      const notAvailable = cart.lines.edges.filter(
        ({ node }) =>
          parseFloat(node.cost.subtotalAmount.amount) / node.quantity ===
            88888.0 || parseFloat(node.cost.subtotalAmount.amount) === 0.0
      );

      for (const line of notAvailable) {
        this.removeLineItem(line.node.id);
      }

      this.setState((prevState) => ({
        ...prevState,
        cart: cart,
      }));

      dispatch(setCartId(cart.id));
      dispatch(setCart(cart));
    }

    render() {
      const { cart, ...props } = this.props;

      return (
        <WrappedComponent
          cart={this.state.cart}
          loadCart={this.loadCart}
          addDiscountCode={this.addDiscountCode}
          removeDiscountCode={this.removeDiscountCode}
          addLineItem={this.addLineItem}
          updateLineItem={this.updateLineItem}
          removeLineItem={this.removeLineItem}
          updateCartCountry={this.updateCartCountry}
          updateDeliveryOption={this.updateDeliveryOption}
          {...props}
        />
      );
    }
  }

  return connect((state) => ({
    cartId: state.shopService.cartId,
    subdivisions: state.userLocationService.location.subdivisions,
  }))(WithCart);
}

export default withCart;
