import { connect } from 'react-redux';
import React, { Component } from 'react';

import MobileMenuPane from './MobileMenuPane';

class MobileMenu extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { menu, site } = this.props;

    return (
      <nav className="mobile-menu">
        <MobileMenuPane
          children={menu?.children}
          featuredChildren={menu?.featuredChildren}
          site={site}
          isCurrent
          isRoot
        />
      </nav>
    );
  }
}

export default MobileMenu;
