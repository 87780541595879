import cloneDeep from 'lodash/cloneDeep';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import ShopGQLClient from './ShopGQLClient';
import { mapEdgesToNodes } from '../../utils/utils';
import { collectionByHandle } from './productCollectionQueries';
import { setShopCollectionsLoading } from '../../state/shopService';

function withShopCollection(WrappedComponent) {
  class WithShopCollection extends Component {
    constructor(props) {
      super(props);

      this.client = new ShopGQLClient(props.site.shopUrl, props.site.shopToken);
      this.fetchCollection = this.fetchCollection.bind(this);

      this.initialState = {
        isUpdated: false,
        newProducts: props.products,
        products: props.products,
      };
      this.state = { ...this.initialState };
    }

    componentDidUpdate(prevProps) {
      if (
        JSON.stringify(this.props.products) ===
        JSON.stringify(prevProps.products)
      )
        return;

      this.setState((prevState) => ({
        ...prevState,
        isUpdated: true,
        newProducts: this.props.products,
      }));
    }

    componentWillUnmount() {
      this.setState = (state, callback) => {
        return;
      };
    }

    async fetchCollection() {
      const { dispatch, productGroup, products, site } = this.props;

      dispatch(
        setShopCollectionsLoading({
          collection: productGroup.collection,
          collectionLoading: true,
        })
      );

      try {
        const collectionProducts = await this.client
          .query(
            collectionByHandle({
              handle: productGroup.collection,
              countryCode: site.countryCode,
            })
          )
          .then((result) =>
            mapEdgesToNodes(result.data.collectionByHandle.products)
          );

        const newProducts = cloneDeep(products).filter((product) =>
          collectionProducts.find(
            (collectionProduct) => collectionProduct.handle === product.path
          )
        );

        newProducts.forEach((product, i) => {
          const collectionProduct = collectionProducts.find(
            (collectionProduct) => collectionProduct.handle === product.path
          );

          product.variants = mapEdgesToNodes(collectionProduct.variants).reduce(
            (acc, shopVariant, i) => {
              const compareAtPrice =
                parseFloat(shopVariant?.compareAtPrice?.amount || '0.00') ||
                null;
              const price =
                parseFloat(shopVariant?.price?.amount || '0.00') || null;

              if (compareAtPrice > price && shopVariant.availableForSale) {
                const pimPrice =
                  parseFloat(product.prices[site.sellPriceField] || '0.00') ||
                  null;
                if (!pimPrice || price < pimPrice) {
                  product.prices[site.sellPriceField] = price;
                }

                const pimOldPrice =
                  parseFloat(
                    product.prices[site.originalPriceField] || '0.00'
                  ) || null;
                if (compareAtPrice) {
                  if (!pimOldPrice || compareAtPrice < pimOldPrice) {
                    product.prices[site.originalPriceField] = compareAtPrice;
                  }
                }

                return [
                  ...acc,
                  {
                    available: shopVariant.availableForSale,
                    backorder: shopVariant.currentlyNotInStock,
                    inventory: shopVariant.quantityAvailable,
                    oldPrice: compareAtPrice,
                    price: price,
                    shopId: shopVariant.id,
                    sku: shopVariant.sku,
                  },
                ];
              } else {
                return acc;
              }
            },
            []
          );
        });

        this.setState((prevState) => ({
          ...prevState,
          products: newProducts.filter(
            (product) => product.variants.length > 0
          ),
        }));
      } catch (err) {
        console.log(err);
      }

      dispatch(
        setShopCollectionsLoading({
          collection: productGroup.collection,
          collectionLoading: false,
        })
      );
    }

    render() {
      const { products, ...props } = this.props;

      return (
        <WrappedComponent
          fetchCollection={this.fetchCollection}
          products={
            this.state.isUpdated ? this.state.newProducts : this.state.products
          }
          {...props}
        />
      );
    }
  }

  return connect((state) => ({
    shopCollectionsLoading: state.shopService.shopCollectionsLoading,
  }))(WithShopCollection);
}

export default withShopCollection;
