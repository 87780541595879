import { Link } from 'gatsby';
import React, { Component } from 'react';

import DesktopMenuPane from './DesktopMenuPane';
import { getCmsValue } from '../../utils/utils';

class DesktopMenu extends Component {
  constructor(props) {
    super(props);

    this.onOutsideClick = this.onOutsideClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.onOutsideClick);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.onOutsideClick);
  }

  onOutsideClick(e) {
    if (!this.ref || this.ref.contains(e.target)) return;

    this.props.onClick();
  }

  render() {
    const { menu, onClick, site } = this.props;

    return (
      <nav
        className="desktop-menu-wrapper"
        onClick={onClick}
        ref={(ref) => (this.ref = ref)}
      >
        {menu?.featuredChildren.length > 0 && (
          <aside className="desktop-menu-sidebar">
            {menu.featuredChildren.map((child) => (
              <DesktopMenuPane
                key={child.id}
                children={child.children}
                linkTarget={child.linkTarget}
                path={child.path}
                site={site}
                title={getCmsValue(child.title, site)}
              />
            ))}
          </aside>
        )}

        <div className="desktop-menu">
          {menu?.children.map((child) => (
            <DesktopMenuPane
              key={child.id}
              children={child.children}
              linkTarget={child.linkTarget}
              path={child.path}
              site={site}
              title={getCmsValue(child.title, site)}
            />
          ))}
        </div>
      </nav>
    );
  }
}

export default DesktopMenu;
