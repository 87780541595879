import React, { Component } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { connect } from 'react-redux';

import countryNames from '../../../containers/countryNames';
import {
  setCmsLanguage,
  setPimLanguage,
} from '../../../state/commonStateService';

class LanguageToggle extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { dispatch, locale, site } = this.props;

    if (site.siteUID !== prevProps.site.siteUID) {
      dispatch(setCmsLanguage('languagePrimary'));
      dispatch(setPimLanguage(site['languagePrimary'].value.replace(/-/, '_')));
    }
  }

  onClick(locale) {
    const { dispatch, site } = this.props;

    dispatch(setCmsLanguage(locale));
    dispatch(setPimLanguage(site[locale].value.replace('-', '_')));
  }

  render() {
    const { fullTitle, site } = this.props;

    const button = (locale) => {
      const { title } = site[locale];
      let localeTitle;

      if (title?.indexOf(',') !== -1) {
        localeTitle = title?.slice(0, title.indexOf(',')).trim();
      } else {
        localeTitle = title.slice(0, title.indexOf('(')).trim();
      }

      if (!fullTitle) {
        localeTitle = site[locale].value.split('-')[0].toUpperCase();
      }

      return (
        <button onClick={(e) => this.onClick(locale)}>{localeTitle}</button>
      );
    };

    return (
      <div className="brand-bar__util util--language-toggle">
        {site.languageSecondary?.title && button('languagePrimary')}
        {site.languageSecondary?.title && button('languageSecondary')}
      </div>
    );
  }
}

export default connect((state) => ({
  cmsLanguage: state.commonStateService.cmsLanguage,
}))(LanguageToggle);
