import React, { Component } from 'react';

class Competition extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const script = document.createElement('script');

    script.src = 'https://widget.gleamjs.io/e.js';
    script.async = true;

    document.body.appendChild(script);
  }

  render() {
    const { competition } = this.props;

    return (
      <a
        className="e-widget no-button"
        href={competition.url}
        rel="nofollow"
      ></a>
    );
  }
}

export default Competition;
