import { connect } from 'react-redux';
import React, { Component } from 'react';

import Utils from './Utils';

class Navigation extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { site } = this.props;

    return (
      <div className="site-footer__navigation">
        <Utils site={site} />
      </div>
    );
  }
}

export default connect()(Navigation);
