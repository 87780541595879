import React, { useRef, useEffect, useState } from 'react';

import { ArrowRight, ArrowLeft } from '../_common/Icons';

export const Slide = ({ children }) => {
  return <swiper-slide class="slider-slide">{children}</swiper-slide>;
};

const Slider = ({
  activeIndex,
  children,
  className,
  onChange,
  onClick,
  onLoad,
  params,
  parentHeightSelector,
}) => {
  const ref = useRef(null);

  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(true);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    if (!ref.current) return;

    onLoad && onLoad(ref);

    const change = (swiper) => {
      setIsVertical(swiper.isVertical());
      setIsStart(swiper.isBeginning);
      setIsEnd(swiper.isEnd);
      onChange && onChange(swiper.activeIndex);
    };

    const setHeight = (swiper) => {
      console.log(swiper);
      const elem = document.querySelector(parentHeightSelector);
      if (!elem) return;

      swiper.el.style.height = `${elem.clientHeight}px`;
    };

    const sliderParams = {
      ...params,
      navigation: false,
      on: {
        activeIndexChange: change,
        afterInit: change,
        click: (swiper) => onClick && onClick(swiper.clickedIndex),
        init: setHeight,
      },
    };

    Object.assign(ref.current, sliderParams);
    ref.current.initialize();
  }, []);

  const onButtonPrevClick = () => {
    ref.current?.swiper.slidePrev();
  };

  const onButtonNextClick = () => {
    ref.current?.swiper.slideNext();
  };

  const classNames = [
    className,
    isVertical ? 'slider-container--vertical' : '',
    params?.navigation ? `slider-navigation--${params?.navigation}` : '',
  ].join(' ');

  return (
    <div className={`slider-container ${classNames}`}>
      <swiper-container
        activeIndex={activeIndex}
        class="slider"
        init="false"
        ref={ref}
      >
        {children}
      </swiper-container>

      {params?.navigation && (
        <>
          <button
            className="slider-navigation-prev"
            onClick={onButtonPrevClick}
            disabled={isStart}
          >
            {ArrowLeft}
          </button>
          <button
            className="slider-navigation-next"
            onClick={onButtonNextClick}
            disabled={isEnd}
          >
            {ArrowRight}
          </button>
        </>
      )}
    </div>
  );
};

export default Slider;
