import React, { Component } from 'react';

import siteLoader from '../../assets/images/site-loader.svg';

class SiteLoader extends Component {
  render() {
    const { viewBox, inverted } = this.props;

    return (
      <div className={`site-loader ${inverted ? 'site-loader--inverted' : ''}`}>
        <div className="site-loader__content">
          <svg
            width="140px"
            height="140px"
            viewBox={viewBox || `0 0 140 140`}
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <filter
                x="-66.5%"
                y="-66.5%"
                width="233.1%"
                height="233.1%"
                filterUnits="objectBoundingBox"
                id="filter-1"
              >
                <feGaussianBlur
                  stdDeviation="17.7455357"
                  in="SourceGraphic"
                ></feGaussianBlur>
              </filter>

              <circle id="circle-clipping-path" cx="70" cy="70" r="16" />

              <clipPath id="the-mask">
                <use xlinkHref="#circle-clipping-path" overflow="visible" />
              </clipPath>

              <g
                id="wave-top-framed"
                transform="translate(00.000000, 00.000000)"
              >
                <rect
                  id="wave-top-box"
                  fillRule="nonzero"
                  x="0"
                  y="0"
                  width="121"
                  height="10"
                ></rect>
                <path
                  d="M0.503861062,5.35073207 L1.49613894,3.61424579 L3.53474955,4.77916614 C6.30164357,6.36024844 9.69835643,6.36024844 12.4652504,4.77916614 L16.5424717,2.44932544 C19.924231,0.516891521 24.075769,0.516891521 27.4575283,2.44932544 L31.5347496,4.77916614 C34.3016436,6.36024844 37.6983564,6.36024844 40.4652504,4.77916614 L44.5424717,2.44932544 C47.924231,0.516891521 52.075769,0.516891521 55.4575283,2.44932544 L59.5347496,4.77916614 C62.3016436,6.36024844 65.6983564,6.36024844 68.4652504,4.77916614 L72.5424717,2.44932544 C75.924231,0.516891521 80.075769,0.516891521 83.4575283,2.44932544 L87.5347496,4.77916614 C90.3016436,6.36024844 93.6983564,6.36024844 96.4652504,4.77916614 L100.542472,2.44932544 C103.924231,0.516891521 108.075769,0.516891521 111.457528,2.44932544 L120.496139,7.61424579 L119.503861,9.35073207 L110.46525,4.18581172 C107.698356,2.60472943 104.301644,2.60472943 101.53475,4.18581172 L97.4575283,6.51565243 C94.075769,8.44808634 89.924231,8.44808634 86.5424717,6.51565243 L82.4652504,4.18581172 C79.6983564,2.60472943 76.3016436,2.60472943 73.5347496,4.18581172 L69.4575283,6.51565243 C66.075769,8.44808634 61.924231,8.44808634 58.5424717,6.51565243 L54.4652504,4.18581172 C51.6983564,2.60472943 48.3016436,2.60472943 45.5347496,4.18581172 L41.4575283,6.51565243 C38.075769,8.44808634 33.924231,8.44808634 30.5424717,6.51565243 L26.4652504,4.18581172 C23.6983564,2.60472943 20.3016436,2.60472943 17.5347496,4.18581172 L13.4575283,6.51565243 C10.075769,8.44808634 5.92423103,8.44808634 2.54247168,6.51565243 L0.503861062,5.35073207 Z"
                  id="wave-top"
                ></path>
              </g>
            </defs>

            <animate
              xlinkHref="#wave-1"
              attributeName="x"
              from="11"
              to="39"
              dur="0.7s"
              repeatCount="indefinite"
            />
            <animate
              xlinkHref="#wave-2"
              attributeName="x"
              from="11"
              to="39"
              dur="0.7s"
              repeatCount="indefinite"
            />
            <animate
              xlinkHref="#wave-3"
              attributeName="x"
              from="11"
              to="39"
              dur="0.7s"
              repeatCount="indefinite"
            />

            <g
              id="mustad-loader-2"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <circle
                id="background-colour"
                fillOpacity="0.5"
                cx="70"
                cy="70"
                r="16"
              ></circle>

              <g id="animated-waves" clipPath="url(#the-mask)">
                <use xlinkHref="#wave-top-framed" id="wave-1" x="11" y="60" />
                <use xlinkHref="#wave-top-framed" id="wave-2" x="11" y="66" />
                <use xlinkHref="#wave-top-framed" id="wave-3" x="11" y="72" />
              </g>

              <path
                d="M70,86 C78.836556,86 86,78.836556 86,70 C86,61.163444 78.836556,54 70,54 C61.163444,54 54,61.163444 54,70 C54,78.836556 61.163444,86 70,86 Z M70,88 C60.0588745,88 52,79.9411255 52,70 C52,60.0588745 60.0588745,52 70,52 C79.9411255,52 88,60.0588745 88,70 C88,79.9411255 79.9411255,88 70,88 Z"
                id="circular-border"
                fillRule="nonzero"
              ></path>
            </g>
          </svg>
        </div>
      </div>
    );
  }
}

export default SiteLoader;
