import Slice from './Slice';

class Image extends Slice {
  constructor(slice) {
    super(slice);

    this.sizes =
      slice.media.image?.asset.gatsbyImageData.images.fallback.sizes || '';
    this.srcSet =
      slice.media.image?.asset.gatsbyImageData.images.fallback.srcSet || '';
    this.src =
      slice.media.image?.asset.gatsbyImageData.images.fallback.src || '';
  }
}

export default Image;
