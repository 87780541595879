import lscache from 'lscache';

/**
 * Initial state
 */
const initialMenuServiceData = {
  activeMenuKey: null,
  menuOpen: false,
  searchOpen: false,
};

/**
 * Action types
 */
const SET_ACTIVE_MENU_KEY = 'SET_ACTIVE_MENU_KEY';
const TOGGLE_MENU_OPEN = 'TOGGLE_MENU_OPEN';
const TOGGLE_SEARCH_OPEN = 'TOGGLE_SEARCH_OPEN';
const RESET_MENU_SERVICE_DATA = 'RESET_MENU_SERVICE_DATA';

/**
 * Action creators
 */
export const setActiveMenuKey = (payload) => ({
  type: SET_ACTIVE_MENU_KEY,
  payload: payload,
});
export const toggleMenuOpen = (payload) => ({
  type: TOGGLE_MENU_OPEN,
  payload: payload,
});
export const toggleSearchOpen = (payload) => ({
  type: TOGGLE_SEARCH_OPEN,
  payload: payload,
});
export const resetMenuServiceData = () => ({
  type: RESET_MENU_SERVICE_DATA,
});

/**
 * Reducer
 */
export default {
  menuService(
    state = lscache.get('menuServiceData') || initialMenuServiceData,
    { type, payload }
  ) {
    let newState = { ...state };

    switch (type) {
      case SET_ACTIVE_MENU_KEY:
        newState.activeMenuKey = payload;
        break;
      case TOGGLE_MENU_OPEN:
        newState.menuOpen = payload;
        break;
      case TOGGLE_SEARCH_OPEN:
        newState.searchOpen = payload;
        break;
      case RESET_MENU_SERVICE_DATA:
        newState = { ...initialMenuServiceData };
        break;
      default:
        break;
    }

    lscache.set('menuServiceData', newState);
    return newState;
  },
};
