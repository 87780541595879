import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import countries from '../_common/countries';
import { Check } from '../_common/Icons';
import { setActiveMenuKey, toggleMenuOpen } from '../../state/menuService';
import { setBrowseForeign } from '../../state/userLocationService';
import { setLocationNotice } from '../../state/notificationService';

const Location = ({ dispatch, site, userLocationServiceData }) => {
  useEffect(() => {}, []);

  const onAcceptClick = () => {
    dispatch(setBrowseForeign(true));
    dispatch(setLocationNotice(false));
    dispatch(setActiveMenuKey('regionMenu'));
    dispatch(toggleMenuOpen(true));
  };

  const onDeclineClick = () => {
    dispatch(setBrowseForeign(true));
    dispatch(setLocationNotice(false));
  };

  return (
    <div className="notification">
      <div className="notification__description">
        You're now browsing the website for {site.title}, but it looks like
        you're located in{' '}
        {countries[userLocationServiceData.location.countryCode]?.name}. Do you
        want to browse your local website?
      </div>
      <div className="notification__buttons">
        <button
          className="notification__button--accept"
          onClick={onAcceptClick}
        >
          {Check} Yes
        </button>
        <button className="notification__button--more" onClick={onDeclineClick}>
          No
        </button>
      </div>
    </div>
  );
};

export default compose(
  connect((state) => ({
    userLocationServiceData: { ...state.userLocationService },
  }))
)(Location);
