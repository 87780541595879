import Slice from './Slice';

class Competition extends Slice {
  constructor(slice) {
    super(slice);

    this.url = slice.competitionUrl;
  }
}

export default Competition;
