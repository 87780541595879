import lscache from 'lscache';

/**
 * Initial state
 */
const initialPaginationServiceData = {
  loadableItems: {},
  initialItemsLoad: 15,
};

/**
 * Action types
 */
const SET_LOADABLE_ITEMS = 'SET_LOADABLE_ITEMS';

/**
 * Action creators
 */
export const setLoadableItems = (payload) => ({
  type: SET_LOADABLE_ITEMS,
  payload: payload,
});

/**
 * Reducer
 */
export default {
  paginationService(
    state = lscache.get('paginationServiceData') ||
      initialPaginationServiceData,
    { type, payload }
  ) {
    const newState = { ...state };

    switch (type) {
      case SET_LOADABLE_ITEMS:
        newState.loadableItems[payload.id] = payload.value;
        break;
      default:
        break;
    }

    lscache.set('paginationServiceData', newState);
    return newState;
  },
};
